<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="320 140 200 200"
      style="transform: scale(1.3);"
    >
      <path
        d="m 386.21408,251.19159 -0.07,-1.31 3.01,-0.29 0,0 -0.45,2.27 -2.49,-0.67 z m 7.27,-49.07 0.9,-2.25 9.98,-0.41 -0.78,-2.39 2.03,-1.49 3.54,1.03 1.44,-0.43 0,0 1.36,1 2.31,-0.07 0.67,1.07 -1.02,1.62 2.53,0.11 0.82,1.25 -1.4,1.95 1.2,0.77 0.37,-1.32 1.4,-0.2 0.98,3.06 1.11,0.3 0.27,1.7 1.39,1.21 -0.19,0.99 -3.05,2.25 1.79,0.31 0.41,4.68 2.5,-1.5 0.03,1.12 1.32,-0.18 -0.3,1.66 1.08,-0.19 1.57,1.53 2.53,-2.08 -0.2,1.73 2.69,2.2 0.86,-0.36 -0.87,1.58 0.56,1.2 2.27,-2.01 1.3,-0.03 0.71,1.16 0.71,-0.87 0,0 0.93,0.64 1.37,-2.26 2.11,1.54 0.37,4.85 -2.08,2.24 -1.59,-0.5 -0.48,1.08 0.85,0.24 -1.71,0.78 0.51,0.83 1.13,-0.64 1.34,0.67 -0.27,3.54 2.77,0.2 -0.23,3.38 -1.03,1.06 2.07,0.79 0,0 -0.6,1.26 -0.68,-0.32 -0.34,4.32 -1.45,1.13 0.59,0.92 -0.84,2.5 -1.27,0.95 -0.96,2.73 -2.38,0.4 -1.83,1.62 -0.35,1.36 1.78,0.84 -2.58,2 0,0 -2.15,-0.31 -0.38,0.92 -3.09,0.43 -0.96,1.11 0.21,-0.79 -2.21,-1.46 -1.91,0.56 -0.22,0.83 -4.29,-0.5 -0.05,0.95 -4.3,1.14 -0.37,0.87 -2.43,-0.22 -1.44,1.32 -0.94,-0.93 0.59,-0.92 -2.71,-0.66 -1.97,-3.14 -0.8,0.05 0.29,-1.72 -1.92,0.46 -0.82,-1.26 -2.54,-0.25 -1.08,-0.92 0.62,-1.5 -0.57,-0.3 -1.99,1.17 -0.34,-2.31 -3.01,-0.4 -1.2,-2.4 -1.34,0.18 -0.1,-0.69 0,0 0.75,-3.25 -2.05,-2.79 -1.86,0.98 -0.8,-3.29 0.8,-0.86 -0.81,-0.13 1.2,-2.83 -1.52,0.93 0,0 0.01,-2.27 -1.09,0.06 0.41,-1.06 -1.04,-0.87 0.83,-0.81 -0.26,-1.17 0.81,0.59 1,-1.1 -0.33,-4.09 2.47,-2.26 0,0 0.48,-1.82 0,0 2.65,-4.81 -0.92,-1.15 2.34,-0.21 -1.88,-2.58 0.87,-1.1 1.34,0.53 1.72,-1.44 -0.12,-5.55 -2.48,0.5 -0.28,-2.13 -0.74,-0.02 2.72,-1.92 -0.77,-1.24 z"
        title="Côte-d'Or"
        id="FR-21"
      />
      <path
        d="m 451.92408,242.60159 1.76,0.18 1.45,-1.78 2.79,-0.54 1.58,-1.31 0.64,0.67 0.24,-1.42 1.16,0.35 -0.16,-1.05 2.12,1.23 3.96,-1.59 0.45,-1.32 1.14,0.75 0.18,-2.18 2.15,0.43 1.04,-0.87 -1,-0.52 1.48,-1.17 1.45,1.11 -0.31,-1.06 0.95,0.35 0.34,-1.35 1.06,-0.04 0.52,-2.68 0.81,0.36 0.54,-1.3 2.29,0.06 0.89,-1.05 2.15,0.66 0.33,1.54 1.18,-1.49 2.35,1.27 1.12,-1.21 -0.66,-1.3 0.55,-0.83 1.23,0.87 1.58,-0.35 0.89,-1.29 -0.42,-1.41 4.48,2.62 1.6,-1.51 0,0 0.43,0.92 3.5,-0.14 0.32,1.49 1.46,0.74 0.18,1.71 -1.3,-0.81 -0.43,1.57 1.39,2.47 0,0 -0.11,1.66 -2.2,1.94 -0.23,1.3 5.7,-1.23 1.35,0.65 0.28,1.74 -1.88,0.56 -0.57,1.83 -2.22,0.46 0.51,2.72 -4.73,4.32 0.73,0.43 -0.83,0.96 -5.65,4.07 -0.46,0.9 1.14,0.99 -4.07,3.52 -5.17,1.13 -2.7,2.8 1.34,2.37 -1.38,4.64 0.89,2.25 -13.53,11.19 -0.66,0.77 1.15,1.14 0,0 -3.7,-3 2.34,-2.67 -1.46,-2.26 5.65,-4.53 -2.29,-3.48 -3.59,-2.33 -1.35,0.64 -0.23,-0.85 0,0 0.13,-0.68 0,0 -0.97,0.12 0.11,-1.36 -1.09,-0.74 0.54,-1.7 -1.61,-0.79 0.79,-1.63 -2.65,-0.73 0.43,-1.73 -1.09,1.27 -2.04,-0.42 -1.37,-0.92 -0.2,-1.77 -0.8,1.54 -1.19,0.58 -0.75,-0.59 0.4,-1.11 -0.72,-0.95 1.16,0.66 0.87,-0.68 -0.63,-2.38 2.41,-2.8 -1.73,-2.9 -1.98,-0.82 z"
        title="Doubs"
        id="FR-25"
      />
      <path
        d="m 444.36408,240.28159 2.44,3.06 2.66,-0.12 1.43,-1.26 1.03,0.64 0,0 1.54,3.88 1.98,0.82 1.73,2.9 -2.41,2.8 0.63,2.38 -0.87,0.68 -1.16,-0.66 0.72,0.95 -0.4,1.11 0.75,0.59 1.19,-0.58 0.8,-1.54 0.2,1.77 1.37,0.92 2.04,0.42 1.09,-1.27 -0.43,1.73 2.65,0.73 -0.79,1.63 1.61,0.79 -0.54,1.7 1.09,0.74 -0.11,1.36 0.97,-0.12 0,0 -0.13,0.68 0,0 0.23,0.85 1.35,-0.64 3.59,2.33 2.29,3.48 -5.65,4.53 1.46,2.26 -2.34,2.67 3.7,3 0,0 0.66,1.29 -3.38,4.42 0.55,1.15 -0.88,1.65 0,0 -8.1,9.07 -5.99,0.23 -0.48,-2.9 -2.79,-0.89 -0.15,-1.23 -1.96,2.92 -1.24,0.09 -1.12,1.55 -2.71,0.25 -0.69,-0.61 0.76,-2.46 -1.59,0.01 -0.53,-1.95 -0.85,2.24 -0.04,-1.68 -1.72,-1.9 0.65,-0.72 -3.23,-1.85 1.02,-0.92 -0.38,-1.07 0,0 0.39,-1.01 3.87,-0.83 0.16,-1.53 -2.56,-1.04 0.11,-3.51 0.9,-0.21 0,0 0.85,-0.01 0,0 0.37,-1.11 -0.72,-0.62 1.89,-1.64 -1.92,-2.85 0.54,-0.77 -0.65,-1.85 -1.33,-0.08 1.27,-2.45 -2.1,-1.22 -0.55,-1.5 3.37,-1.08 2.28,0.34 -0.08,-1.6 -2.16,-0.61 -0.42,-1.71 -2.9,0.3 -1.24,-2.95 -1.46,0.45 -0.5,-0.93 0.17,-2.18 0,0 2.58,-2 -1.78,-0.84 0.35,-1.36 1.82,-1.61 2.38,-0.4 0.96,-2.73 1.27,-0.95 0.84,-2.5 -0.58,-0.92 1.44,-1.13 0.34,-4.32 0.67,0.32 z"
        title="Jura"
        id="FR-39"
      />
      <path
        d="m 338.82408,224.01159 1.86,0.77 3.35,-1.9 0.92,0.7 -0.1,2.23 1.77,1.3 1.61,-0.28 1.24,2.1 1.7,-0.11 0.44,-0.81 2.59,2.14 1.89,-2.25 3.98,0.73 0.45,-4.11 0.62,2.06 2.76,1.84 0.28,2.52 1.2,-0.62 2.43,0.98 2.15,3.17 2.53,-0.41 1.26,1.6 0.16,-1.41 1.88,-2.01 1.15,1.25 -1.43,1.34 0.53,1.63 2.98,-0.93 0.19,-0.82 0.98,1.12 -0.01,2.69 1.25,1.33 1.04,-0.24 0.66,-1.58 2.62,0.03 0,0 1.52,-0.93 -1.2,2.83 0.81,0.13 -0.8,0.86 0.8,3.29 1.87,-0.98 2.05,2.78 -0.75,3.25 0,0 -0.9,0.3 0,0 -3.01,0.28 0.07,1.32 0,0 -2.79,0.71 -0.49,2.07 1.46,1.4 -0.69,2.55 -2.63,1.72 0.64,0.86 1.21,-0.86 0.7,1.71 -0.74,2.88 2.59,2.55 -2.34,1.85 0.46,3.56 -0.86,-0.61 -1.53,0.54 -2.88,2.32 -5.43,2.37 -0.58,-2.08 -1.89,-1 -3.23,0.89 -1.28,-0.69 0,0 -1.36,-0.99 -0.46,1.57 0,0 0.48,0.92 0,0 -2.05,0.09 0.55,2.04 -1.88,0.35 -1.38,1.98 -1.63,-0.75 0.39,-1.63 -1.32,-1.46 -1.72,0.08 -1.34,1.73 -1.39,-0.33 -0.53,-1.6 -1.31,-0.06 -2.78,2.49 -3.04,-3.12 -2.94,-1.43 -1.11,-2.64 0,0 1.57,-3.44 -0.82,-3.38 1.25,-2.92 -0.65,-1.16 0.58,-2.97 -2.49,-2.46 0.53,-3.46 -2.17,-6.53 0.14,-2.03 -4.24,-3.35 -0.49,-1.33 2.62,-6.09 -2.34,-5.07 0,0 -1.12,-1.12 0.39,-0.81 0.67,0.46 1.74,-1.28 1.82,0.54 z"
        title="Nièvre"
        id="FR-58"
      />
      <path
        d="m 459.57408,202.06159 2.01,-3.18 1.13,0.57 -1.34,1.86 0.9,0.14 0.89,-1.32 1.32,0.05 1.54,-2.91 3.8,-1.26 1.01,1.05 0.35,1.92 -0.64,0.58 2.44,1.18 -0.14,0.95 3.45,-1.46 4.23,-0.31 1.91,1.17 1.78,3.47 2.71,-1.09 0.84,-1.82 1.64,-0.82 1.78,2.6 5.8,3.3 1.54,2.2 0,0 -2.71,4.14 1.77,6.77 -0.91,1.42 1.75,2.48 -0.6,0.69 0,0 -1.6,1.51 -4.48,-2.62 0.42,1.41 -0.89,1.29 -1.58,0.35 -1.23,-0.87 -0.55,0.83 0.66,1.3 -1.12,1.21 -2.35,-1.27 -1.18,1.49 -0.33,-1.54 -2.15,-0.66 -0.89,1.05 -2.29,-0.06 -0.54,1.3 -0.81,-0.36 -0.52,2.68 -1.06,0.04 -0.34,1.35 -0.95,-0.35 0.31,1.06 -1.45,-1.11 -1.48,1.17 1,0.52 -1.04,0.87 -2.15,-0.43 -0.18,2.18 -1.14,-0.75 -0.45,1.32 -3.96,1.59 -2.12,-1.23 0.16,1.05 -1.16,-0.35 -0.24,1.42 -0.64,-0.67 -1.58,1.31 -2.79,0.54 -1.45,1.78 -1.76,-0.18 0,0 -1.03,-0.64 -1.43,1.26 -2.66,0.12 -2.44,-3.06 0,0 -2.07,-0.79 1.03,-1.07 0.23,-3.37 -2.77,-0.2 0.27,-3.54 -1.34,-0.67 -1.13,0.64 -0.51,-0.83 1.71,-0.78 -0.85,-0.24 0.48,-1.09 1.59,0.5 2.08,-2.24 -0.37,-4.85 -2.11,-1.54 -1.37,2.26 -0.93,-0.64 0,0 1.58,-4.29 2.15,0.16 0.86,-0.74 1.77,0.67 1.74,-2.12 1.22,2.17 3.9,-0.77 -0.26,-2.32 1.01,-1.5 -0.13,-1.29 -1.23,-0.7 0.62,-2.57 2.1,0.05 0.79,-2.43 2.79,0.45 -0.25,-1.16 1.19,-2.18 1.4,0.39 z"
        title="Haute-Saône"
        id="FR-70"
      />
      <path
        d="m 390.05408,249.29159 0.1,0.69 1.34,-0.17 1.2,2.4 3.02,0.39 0.34,2.31 1.98,-1.17 0.57,0.3 -0.62,1.5 1.08,0.93 2.54,0.25 0.81,1.26 1.92,-0.46 -0.28,1.72 0.79,-0.05 1.97,3.14 2.71,0.66 -0.59,0.91 0.94,0.93 1.44,-1.32 2.43,0.22 0.37,-0.87 4.3,-1.14 0.06,-0.94 4.29,0.49 0.22,-0.83 1.91,-0.55 2.21,1.45 -0.21,0.79 0.96,-1.11 3.08,-0.43 0.38,-0.92 2.15,0.31 0,0 -0.17,2.18 0.5,0.93 1.46,-0.45 1.24,2.95 2.9,-0.3 0.42,1.71 2.16,0.61 0.08,1.6 -2.28,-0.34 -3.37,1.08 0.55,1.5 2.1,1.22 -1.27,2.45 1.33,0.08 0.65,1.85 -0.54,0.77 1.92,2.85 -1.89,1.64 0.72,0.62 -0.37,1.11 0,0 -0.85,0.01 0,0 -0.9,0.21 -0.11,3.51 2.56,1.04 -0.16,1.53 -3.87,0.83 -0.39,1.01 0,0 -3.26,-0.7 -1.23,-2.86 -1.58,-0.81 -0.05,0.91 -1.3,-0.28 -3.26,1.47 -3.02,-1.84 -1.39,0.84 -0.4,-1.07 -6.69,20.52 0,0 -2.25,-0.22 0.39,-1.94 -0.66,-0.9 0.7,-0.35 -2.35,-1.53 1.29,-1.18 -1.27,-1.65 -1.77,0.31 -0.79,2.12 -1.45,-0.22 -0.91,-1.53 -0.62,1.24 -1.77,0.39 -0.64,-1.26 -2.54,-0.84 -1.26,1.17 -0.38,3.76 0,0 -1.07,1.25 0.37,0.88 -1.8,-0.03 -2.17,1.72 -1.06,-0.13 0.19,-1.74 -1.19,0.59 -0.75,-1 -1.22,1.23 -1.88,-0.2 -1.25,-1.29 -3.5,1.59 0,0 -1.04,0 0,0 -0.86,-1.82 -3.42,-0.7 0.79,-1.92 -0.43,-1.76 0,0 -0.07,-1.04 1.95,-0.44 0.09,-1.26 1.7,0.41 0.71,-0.76 -0.82,-0.08 -0.36,-4.18 0.98,-3.8 -1.95,-0.95 0.02,-0.89 -3.59,0.21 -1.15,-2.55 -1.39,0.76 -2.64,-1.2 -0.23,-3.82 -0.95,-0.4 0.34,-1.1 -1,-1.93 -2.47,-2.9 -0.39,-2.55 0,0 1.28,0.69 3.23,-0.89 1.89,1 0.58,2.08 5.43,-2.37 2.88,-2.32 1.53,-0.54 0.86,0.61 -0.46,-3.56 2.34,-1.85 -2.59,-2.55 0.74,-2.88 -0.7,-1.71 -1.21,0.86 -0.64,-0.86 2.63,-1.72 0.69,-2.55 -1.46,-1.4 0.49,-2.07 2.79,-0.71 0,0 2.49,0.67 0.45,-2.27 0,0 z"
        title="Saône-et-Loire"
        id="FR-71"
      />
      <path
        d="m 337.18408,187.36159 0.01,-1.26 2.78,-1.46 1.78,-2.66 -1.33,-3.44 1.33,-3.38 2.33,0.65 1.54,-1.48 4.72,0.5 1.16,-1.06 2.22,0.74 1.2,-0.16 0.46,-1.72 1.6,0.61 0,0 0.58,1.97 1.59,-1.1 1.55,0.61 1.66,2.92 1.81,1.19 -0.41,1.03 1.73,0.95 -0.76,2.25 0.86,0.43 -1.76,2.55 2.14,-0.33 1.53,3.1 2.31,-1 -0.35,-1.12 1.41,0.52 -0.36,2.07 2.14,0.91 1.29,4.57 2.06,1.8 -0.05,0.78 -1.31,-0.09 0.52,1.29 1.18,0.26 0.6,-1.41 0.78,1.35 -0.77,3.12 3.79,-0.07 0.76,-0.75 1.67,1.07 0.64,-1.27 1.23,0.05 0.85,1.17 1.57,-1.35 0,0 0.8,-0.73 0,0 1.46,1.12 0.43,-1.26 -0.74,-0.34 1.31,-0.29 -0.18,1.41 0.94,1.18 2,0.32 0,0 0.78,1.23 -2.72,1.92 0.73,0.02 0.28,2.13 2.48,-0.5 0.13,5.56 -1.72,1.44 -1.35,-0.53 -0.87,1.1 1.89,2.58 -2.35,0.21 0.92,1.15 -2.65,4.81 0,0 -0.48,1.82 0,0 -2.47,2.26 0.33,4.09 -1,1.1 -0.81,-0.59 0.26,1.18 -0.83,0.81 1.05,0.87 -0.41,1.07 1.09,-0.06 -0.01,2.27 0,0 -2.62,-0.03 -0.66,1.58 -1.04,0.24 -1.25,-1.33 0.01,-2.69 -0.98,-1.12 -0.19,0.82 -2.98,0.93 -0.53,-1.63 1.43,-1.34 -1.15,-1.25 -1.88,2.01 -0.16,1.41 -1.26,-1.6 -2.53,0.41 -2.15,-3.17 -2.43,-0.98 -1.2,0.62 -0.28,-2.52 -2.76,-1.84 -0.62,-2.06 -0.45,4.11 -3.98,-0.73 -1.89,2.25 -2.59,-2.14 -0.44,0.81 -1.7,0.11 -1.24,-2.1 -1.61,0.28 -1.77,-1.3 0.1,-2.23 -0.92,-0.7 -3.35,1.9 -1.86,-0.77 0,0 -1.66,-1.81 -0.17,-1.7 0.87,-1.14 -1.43,-1.44 0.3,-0.82 -1.96,-1.34 -0.02,-1.03 -1.27,0.21 0.16,-2.49 7.21,-1.82 -0.51,-2.88 0.81,-1 -1.53,-1.08 0.71,-0.39 -0.15,-2.01 1.7,-0.3 1.39,-1.95 0,0 0.49,-0.34 0,0 1.35,-1.53 -0.12,-2.1 -1.07,-0.79 1.05,-0.64 -3.11,-2.69 -0.58,-2.71 -0.82,0.06 0.56,-1.07 z"
        title="Yonne"
        id="FR-89"
      />
      <path
        d="m 499.04408,208.49159 1.25,2.43 3.07,0.71 4.01,2.96 -0.41,1.38 0.8,1.76 -1.44,2.01 -0.24,2.26 0.63,0.83 0.86,-0.73 1.86,0.47 2.37,4.18 -0.44,1.33 0,0 -2.35,0.91 -2.17,-0.99 -1.69,0.63 0.78,2.48 -2.56,1.27 0,0 -1.39,-2.47 0.43,-1.57 1.3,0.81 -0.18,-1.71 -1.46,-0.74 -0.32,-1.49 -3.5,0.14 -0.43,-0.92 0,0 0.6,-0.69 -1.75,-2.48 0.91,-1.42 -1.77,-6.77 2.71,-4.14 0,0 z"
        title="Territoire de Belfort"
        id="FR-90"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "BourgogneFrancheComte",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>