<template>
  <v-row justify="center" align="center" class="fill-height pa-0 ma-0" style="overflow: hidden" no-gutters>
    <TrafficMap :url_traffic="url_traffic" :kenbruns="kenbruns" v-on:on-image-error="emitEnd" />
    <repetitive-signal
      key="interval"
      :ready="ready_interval"
      :milliseconds="interval"
      v-on:repetitive-signal-over="onIntervalEnd"
    ></repetitive-signal>
    <repetitive-signal
      key="duration"
      :ready="ready_duration"
      :milliseconds="duration"
      v-on:repetitive-signal-over="onDurationEnd"
    ></repetitive-signal>
  </v-row>
</template>

<script>
import TrafficMap from "@/components/Traffic/Map";
import RepetitiveSignal from "@/components/RepetitiveSignal/RepetitiveSignal";
import snackBar from "@/utils/mixins/snackBar";

export default {
  name: "SceneTraffic",

  components: {
    TrafficMap,
    RepetitiveSignal,
  },

  mixins: [snackBar],

  data() {
    return {
      url_traffic: null,

      ready_interval: false,
      ready_duration: false,
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    kenbruns: {
      type: Boolean,
      default: true,
    },
    intervalOnMounted: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: null,
    },
    duration: {
      type: Number,
      default: null,
    },
  },

  methods: {
    async getTraffic() {
      try {
        let { data } = await this.$axios.get("/traffic");
        if (data.url) this.url_traffic = data.url;
        this.ready_duration = true;
      } catch (error) {
        this.emitEnd();
        console.error(error);
        this.displayErrorSnack(`Une erreur s'est produite lors de la récupération du trafic routier`);
      }
    },

    emitReady() {
      this.url_traffic = null;
      this.$emit("traffic-ready", true);
    },

    emitEnd() {
      this.$emit("traffic-end", false);
    },

    onIntervalEnd() {
      this.emitReady();
    },

    async onDurationEnd() {
      this.emitEnd();

      this.ready_duration = false;
      this.ready_interval = true;
    },
  },

  watch: {
    show: {
      handler: async function(val, oldVal) {
        if (val == true) {
          await this.getTraffic();
          this.ready_duration = true;
          this.ready_interval = false;
        } else if (!val && this.intervalOnMounted) {
          this.ready_duration = false;
          this.ready_interval = true;
        } else {
          this.ready_duration = false;
          this.ready_interval = false;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
