<template>
  <Weather
    :show="show"
    :ref_weather="ref_weather"
    :interval="interval"
    :intervalOnMounted="intervalOnMounted"
    :duration="duration"
    v-on:weather-ready="emitReady"
    v-on:weather-end="emitEnd"
    :transition_name="transition_name"
  />
</template>

<script>
import Weather from "@/components/Weather/Weather";

export default {
  name: "SceneWeather",

  components: {
    Weather,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: null,
    },
    intervalOnMounted: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: null,
    },
    ref_weather: {
      type: Array,
      default: () => [false, false, false],
    },
    transition_name: {
      type: String,
      default: "fade",
    },
  },

  methods: {
    emitReady() {
      this.$emit("weather-ready");
    },
    emitEnd() {
      this.$emit("weather-end");
    },
  },
};
</script>

<style lang="scss" scoped></style>
