<template>
  <v-row id="vimeo-container" class="fill-height vimeo-container no-cursor pa-0 ma-0" no-gutters>
    <div :class="{ 'hide-cursor': hide_cursor }"></div>
    <RepetitiveCheck
      :start="start_repetitive"
      :cb="get_vimeo_progress"
      :check="check_vimeo_unprogress"
      v-on:max_failure="handle_failure_check"
      :nbr_max_failure="new Number(4)"
      :millisecond_timeout="new Number(1000 * 20)"
      key="check_unprogress"
    />
  </v-row>
</template>

<script>
import Player from "@vimeo/player";
import RepetitiveCheck from "@/components/RepetitiveCheck/RepetitiveCheck";

import { throttle } from "lodash";

export default {
  name: "MyVimeo",

  props: {
    video_url: String,
    hide_cursor: { type: Boolean, default: true },
  },

  components: {
    RepetitiveCheck,
  },

  data() {
    return {
      player: null,

      start_repetitive: false,
    };
  },

  // window.addEventListener('message', function(data) {console.log(data)}, false);
  // document.getElementsByTagName('iframe')[0].contentWindow.postMessage({method: 'pause'}, "https://player.vimeo.com");

  methods: {
    async initPlayer(url) {
      try {
        this.player = new Player("vimeo-container", {
          url,
          controls: false,
          muted: true,
          autopause: false,
          width: 900,
        });
        await this.player.ready();
        this.start_repetitive = true;
      } catch (error) {
        await this.destroyPlayer();
        this.debouncedEmitEnd(error);
        console.error(error);
      }
    },

    async loadVideo(url) {
      try {
        await this.player.ready();
        await this.player.loadVideo(url);
      } catch (error) {
        this.debouncedEmitEnd(error);
        console.error(error);
      }
    },

    async handleNewVideo(url) {
      try {
        if (!this.player) await this.initPlayer(url);
        else await this.loadVideo(url);
        this.attachEventVimeoPlayer();
        await this.playVideo();
      } catch (error) {
        this.debouncedEmitEnd(error);
        console.error(error);
      }
    },

    async playVideo() {
      try {
        await this.player.ready();
        await this.player.play();
      } catch (error) {
        this.debouncedEmitEnd(error);
        console.error(error);
      }
    },

    async pauseVideo() {
      try {
        await this.player.ready();
        await this.player.pause();
      } catch (error) {
        console.error(error);
        this.debouncedEmitEnd(error);
      }
    },

    attachEventVimeoPlayer() {
      try {
        if (this.player) {
          this.player.on("timeupdate", async (data) => {
            await this.onTimeUpdateEvent(data);
          });
          this.player.on("ended", () => {
            this.onEndedEvent();
          });
          this.player.on("error", () => {
            this.onErrorEvent();
          });
        }
      } catch (error) {
        console.error(error);
        this.debouncedEmitEnd(error);
      }
    },

    detachEventVimeoPlayer() {
      if (this.player) {
        this.player.off("ended");
        this.player.off("timeupdate");
        this.player.off("error");
      }
    },

    async onTimeUpdateEvent(data) {
      try {
        if (data.percent > 0.978) {
          this.detachEventVimeoPlayer();
          // await this.pauseVideo();
          await this.destroyPlayer();
          this.debouncedEmitEnd(true);
        }
      } catch (error) {
        console.error(error);
        this.debouncedEmitEnd(error);
      }
    },

    onEndedEvent() {},

    async onErrorEvent() {
      try {
        this.detachEventVimeoPlayer();
        // await this.pauseVideo();
        await this.destroyPlayer();
        this.debouncedEmitEnd(true);
      } catch (error) {
        console.error(error);
        this.debouncedEmitEnd(error);
      }
    },

    async unloadPlayer() {
      try {
        await this.player.ready();
        await this.player.unload();
      } catch (error) {
        await this.destroyPlayer();
        this.debouncedEmitEnd(true);
        console.error(error);
      }
    },

    async destroyPlayer() {
      try {
        // should stop RepetitiveTask because if we destroy the player we can not check the progression anymore
        this.start_repetitive = false;
        // await this.player.ready();
        this.detachEventVimeoPlayer();
        await this.player.destroy();
        this.player = null;
      } catch (error) {
        this.debouncedEmitEnd(error);
        console.error(error);
      }
    },

    debouncedEmitEnd: throttle(
      function(payload) {
        this.emitEnd(payload);
      },
      2000,
      { trailing: false }
    ),

    emitEnd(payload) {
      console.log("signal emitEnd");
      this.start_repetitive = false;
      this.$emit("video-end", payload);
    },

    async get_vimeo_progress() {
      try {
        await this.player.ready();
        return await this.player.getCurrentTime();
      } catch (error) {
        this.debouncedEmitEnd(error);
        console.error(error);
        return null;
      }
    },

    check_vimeo_unprogress(ref_previous_progress, nbr_max_failure) {
      // pick the last N part
      let sliced =
        ref_previous_progress.length < nbr_max_failure
          ? ref_previous_progress.slice(0, ref_previous_progress.length)
          : ref_previous_progress.slice(ref_previous_progress.length - nbr_max_failure, ref_previous_progress.length);

      if (sliced.filter((item, index) => sliced.indexOf(item) != index).length >= nbr_max_failure - 1)
        // if vimeo progress is the same since since nbr_max_failure times so its stuck
        return true;
      return false;
    },

    handle_failure_check() {
      this.debouncedEmitEnd();
    },
  },

  watch: {
    video_url: {
      handler: async function(url) {
        try {
          await this.$nextTick();
          if (url && url.length > 0) await this.handleNewVideo(url);
        } catch (error) {
          console.error(error);
          this.debouncedEmitEnd(error);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.no-cursor:hover {
  cursor: none;
}

.vimeo-container {
  position: relative;

  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: none;
}

.hide-cursor {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: none;
  z-index: 100;
}
</style>
