<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="230 180 200 200"
      style="transform: scale(1.2);"
    >
      <path
        d="m 413.70408,308.36159 6.69,-20.52 0.4,1.07 1.39,-0.84 3.02,1.84 3.26,-1.47 1.3,0.28 0.05,-0.91 1.58,0.81 1.23,2.86 3.26,0.7 0,0 0.38,1.07 -1.02,0.92 3.23,1.85 -0.65,0.72 1.72,1.9 0.04,1.68 0.85,-2.24 0.53,1.95 1.59,-0.01 -0.76,2.46 0.69,0.61 2.71,-0.25 1.12,-1.55 1.24,-0.09 1.96,-2.92 0.15,1.23 2.79,0.89 0.48,2.9 5.99,-0.23 8.1,-9.07 0,0 4.37,2.95 -2.81,4.91 0.93,2.04 -6,2.06 -0.64,1.2 1.25,0.91 -1.59,2.91 0,0 -2.64,0.81 -0.26,1.99 -2.77,-1.09 -0.59,5.3 1.17,2.49 -0.24,2.05 0,0 -2.2,12.71 -1.08,1.12 -1.97,-0.3 -0.61,4.09 -2.58,1.67 0,0 -1.02,-2.67 -2.36,-2.01 0.42,-0.99 -5.39,-5.73 0.32,-1.78 -3.27,-2.82 -2.08,2.12 -1.32,3.32 -1.94,1.36 -1.41,-0.23 -1.64,-1.96 -1.44,-0.3 -0.34,0.78 -0.21,-0.95 0,0 -7.62,0.63 0,-2.26 -1.54,-3.26 -1.39,-1 -1.8,0.99 0.17,-1.4 -3.27,-1.31 1,-1.81 -0.32,-6.96 2.33,-3.73 z"
        title="Ain"
        id="FR-01"
        transform="scale(0.8)"
      />

      <path
        d="m 309.92408,293.77159 0.74,-3.03 1.03,-0.01 1.56,-2.77 5.24,-0.96 2.06,0.81 3.24,-2.06 -0.45,-2.48 -1.55,-0.86 1.22,-1.84 -1.07,-1.19 1.45,-0.75 0.95,0.77 -0.15,-1.91 3.21,-1.71 0.59,-1.61 0.74,-0.13 1.14,1.84 1.56,-1.08 1.98,0.55 0.14,-1.2 2.95,-2.86 1.78,-0.69 2.81,0.57 0,0 1.11,2.64 2.94,1.43 3.04,3.12 2.78,-2.49 1.31,0.06 0.53,1.6 1.39,0.33 1.34,-1.73 1.72,-0.08 1.32,1.46 -0.39,1.63 1.63,0.75 1.38,-1.98 1.88,-0.35 -0.55,-2.04 2.05,-0.09 0,0 -0.48,-0.92 0,0 0.46,-1.57 1.36,0.99 0,0 0.39,2.55 2.47,2.9 1,1.93 -0.34,1.1 0.95,0.4 0.23,3.82 2.64,1.2 1.39,-0.76 1.15,2.55 3.59,-0.21 -0.02,0.89 1.95,0.95 -0.98,3.8 0.36,4.18 0.82,0.08 -0.71,0.76 -1.7,-0.41 -0.09,1.26 -1.95,0.44 0.07,1.04 0,0 -0.89,0 0,0 -0.44,0.81 -2.67,0.25 0.05,1.05 -1.5,0.09 1.69,4.56 -0.8,0.42 0.15,2.63 1.18,1.39 -0.67,2.1 1.07,3.23 -1.8,1.54 -1.33,-0.57 -0.52,1.03 -1.44,-0.47 -0.62,2.58 0,0 -0.7,-1.65 -1.84,-0.23 -1.71,-3.68 -5.27,0.93 -0.1,-3.1 0,0 -0.99,0 0,0 -0.79,-0.87 -3.61,1.74 -1.22,-1.24 -7.19,-0.25 -1.28,-0.81 -0.29,-1.86 -3.2,0.73 -1.9,-1.28 -0.68,-2.78 -1.6,-0.44 0.01,-2.16 1.34,-1.61 -1.1,-0.59 -3.64,0.23 -0.93,3.04 -3.2,-1.57 -0.38,2.43 -2.04,0.59 -1.47,3.18 -2.97,-1.46 0,0 -3.53,-8.19 -2.11,-0.96 -0.42,0.78 -1.4,-2.9 -0.95,1.27 -2.6,-1.97 1.35,-1.57 -1.97,-0.72 z"
        title="Allier"
        id="FR-03"
        transform="scale(0.8)"
      />
      <path
        d="m 412.73408,356.42159 0.29,2.55 1.59,1.53 0,0 0.28,7.55 0.95,0.64 -1.18,1.93 1.1,3.04 1.28,0.84 -1.04,2.93 2.11,4.11 -1.33,2.24 -0.43,3.23 -3.42,4.13 0.8,6.56 -1.64,4.17 -1.97,1.66 0.29,5.72 -1.44,1.98 -0.65,5.64 0,0 -0.05,3.44 0,0 -3.77,-1.84 -0.4,-1.33 -1.53,-0.19 -0.28,-0.88 -2.16,0.31 -0.63,3.19 -1.76,-0.41 -0.15,-3.24 -2.72,0.47 -1.6,1.34 0.03,1.32 -1.3,1.58 -3.27,-3.06 -1.48,0.27 -0.54,-1.35 -3.23,1.12 -0.65,-0.69 0.9,-1.99 -0.73,-1.6 1.18,-0.82 -1.35,-0.81 0.06,-1.53 -1.63,-0.8 0,0 -1.41,-5.52 -2.55,-2.29 -1.73,-8.71 0,0 2.58,-1.42 -0.53,-0.55 1.6,-2.85 1.62,0.9 -0.24,-1.25 0.73,0.76 0.23,-1.06 0.94,0.26 0.26,-2.26 5.06,-0.04 2.84,-5.27 3.69,-0.58 -0.97,-1.38 0.25,-1.33 3.57,-1.28 -1.51,-1.57 0.53,-1.84 1.66,-0.33 -1.04,-2.28 2.04,0.44 0.53,1.55 0.11,-0.74 0.79,0.21 -0.6,-1.56 1.51,-2.64 0.14,-2.68 0,0 4.94,-0.86 -0.43,-1.36 1.33,-1.04 -0.32,-1.2 z"
        title="Ardèche"
        id="FR-07"
        transform="scale(0.8)"
      />
      <path
        d="m 319.40408,349.78159 1.46,-0.01 -0.29,1.31 1.19,-0.52 0.92,0.95 1.26,-1.22 0.26,1.52 2.66,0.38 -0.33,1.8 1.42,1.5 1.14,-0.68 1.76,0.52 1.18,-0.94 2.27,1.44 0.69,-0.44 1.6,0.9 1.08,3.52 2.13,1.08 2.15,-0.72 0.35,-1.69 2.15,-1.22 0,0 0.02,1.44 -0.92,0.17 0.98,0.86 -1.32,1.22 3.22,-0.35 0.18,1.56 2.43,-0.49 0.57,3.43 1.52,0.58 -0.5,1.47 0,0 0,0.58 0,0 1.16,3.11 2.97,0.99 -3.22,0.02 1.21,1.15 -0.54,3.01 0.58,0.8 1,-0.38 -0.41,0.92 1.79,0.31 -1.08,1.09 0.7,0.85 0,0 -3.14,2.58 -0.78,-0.91 -1.04,0.4 0.32,1.12 -1.08,0.42 0.52,1.26 -1.83,1.47 -2.06,-2.34 -1.61,0.96 -0.19,3.06 -1.1,-0.23 -1.06,2.11 -0.84,4.26 0.52,0.74 -0.91,0.12 -1.47,4.03 0,0 -1.87,-2.07 -0.07,-3.74 -0.97,-1 1.06,-0.74 -0.69,-1.14 -1.34,0.25 -1.19,-4.96 -2.27,-0.01 -1.33,1.09 0.06,-3.28 -1.55,-1.71 -5.49,5.58 -0.18,3.14 -2.1,2.25 0.43,1.94 -2.52,1.81 -0.65,2.23 -5.46,0.59 -1.07,-1.74 -1.31,0.12 -3.31,1.11 0.39,1.5 -0.71,0.38 0,0 -1.49,-1.16 -0.54,-3.42 -1.21,-0.06 1.74,-5.35 -3.34,-4.95 -0.26,-1.76 0.75,-0.98 -1.08,-0.81 -0.64,-2.42 0,0 1.4,-0.57 0,0 0.83,0.04 0,0 1.15,-0.96 -1.84,-3.43 1.84,-1.55 1.25,0.25 0.27,-3.22 2.23,-1.84 -1.35,-0.76 -0.28,-2.37 1.94,-1.59 1.18,-2.51 0.83,0.17 1.12,-1.96 1.81,-0.89 -0.22,-4.61 5.24,2.65 1.8,-0.7 -0.08,-1.18 -1.43,-1.02 z"
        title="Cantal"
        id="FR-15"
        transform="scale(0.8)"
      />
      <path
        d="m 414.61408,360.50159 2.41,-0.75 0.86,0.75 4.5,-2.75 0.87,0.11 0.54,1.41 1.29,-0.06 0.9,2.18 2.57,-0.9 -0.71,2.92 1.6,-0.43 1.77,2.18 -1.47,0.97 0.97,1.88 -0.15,2.86 -2.08,2.73 1.22,0.43 0.7,-1.09 1.2,0.07 2.41,1.7 2.99,-0.41 1.84,1.59 0.07,-1.39 1.14,0.74 1.98,-2.37 0.88,0.13 0.47,0.71 -0.8,0.07 -0.42,1.49 1.18,2.82 -1.21,9.96 0.81,0.22 -1.08,1.34 4.01,1.87 -0.25,-1.6 3.23,2.45 0.86,1.66 2.38,-0.28 2.19,1.94 1.73,-0.6 0,0 1.31,0.93 -1.7,2.15 -1.67,-0.5 -0.84,1.34 -3.63,-0.64 0.26,2.21 -2.11,3.94 2.76,2.46 -1.45,0.09 -1.1,2.02 -5.98,-1.97 0.27,2.98 1.36,0.72 -0.83,0.92 -1.6,-0.81 -0.82,0.5 1.03,1.93 -0.42,1.18 1.2,0.21 1.21,1.67 1.25,-0.72 1.1,1.19 3.38,-0.19 -0.88,1.58 1.19,0.32 0.44,1.95 1.64,0 -0.44,4.38 0,0 -1,0.1 -0.36,1.19 1.64,0.3 -0.17,0.99 -1.96,-0.24 0.35,-1 -1.25,-1.3 -1.31,0.13 -0.56,1.01 0.59,1.07 -3.33,2.09 0,0 -1.94,-0.27 -0.68,-1.8 -2.18,-0.2 0.08,-2.6 -1.23,-0.81 -0.83,0.66 -3.57,-1.65 -0.81,1.16 -3.09,-1.09 -0.33,-2.79 0.84,-2.1 -2.46,2.16 -1.33,-0.37 -0.69,-1.37 -5.72,2.76 -1.76,-0.06 -2.53,1.93 -1.26,-4.96 -5.73,-0.97 0,0 0.65,-5.64 1.44,-1.98 -0.29,-5.72 1.98,-1.66 1.64,-4.17 -0.8,-6.56 3.42,-4.13 0.43,-3.23 1.33,-2.24 -2.11,-4.11 1.04,-2.93 -1.27,-0.83 -1.1,-3.05 1.17,-1.92 -0.95,-0.64 -0.33,-7.55 z m 5.06,51.84 0,0 -0.09,-0.06 0,0 -0.48,0.7 0,0 -1.51,2.73 0.87,0.64 -0.22,1.98 4.05,0.68 1.51,-3.92 1.98,-0.93 0,0 0.01,-0.35 0,0 -2.32,-0.57 0.12,-1.33 -0.67,0.2 0,0 -0.58,-0.63 0,0 -0.43,-0.05 0,0 -0.77,0.2 0,0 -0.68,0.39 -0.79,0.32 z"
        title="Drôme"
        id="FR-26"
        transform="scale(0.8)"
      />
      <path
        d="m 427.08408,329.96159 0.21,0.95 0.34,-0.78 1.44,0.3 1.64,1.96 1.41,0.23 1.94,-1.36 1.32,-3.32 2.08,-2.12 3.27,2.82 -0.32,1.78 5.39,5.73 -0.42,0.99 2.36,2.01 1.02,2.67 0,0 2,4.14 0,0 0,0.65 0,0 1.64,1.49 1.1,3.96 2.1,-0.05 4.16,2.92 0.77,0.09 0.77,-1.43 -1.04,0.35 0.51,-4.04 2.15,-0.9 3.45,3.25 3.4,0.14 2.55,3.83 -0.94,0.66 1.04,0.3 -0.37,1.99 -2.31,1.96 0.41,1.41 -0.77,1.06 1.65,3.24 -0.74,2.02 1.52,-0.63 1.76,1.46 1.13,-0.64 0.45,1.45 0,0 -1.21,1.33 0.54,1.95 -1.02,0.36 -0.64,2.83 1.96,1.15 3,-0.13 -0.05,2.63 1.48,0.84 -0.11,4.94 -1.38,0.15 -1.09,-1.27 -2.03,1.29 -4.7,-0.72 -3.22,2.82 -1.42,-1.24 -3.02,1.89 1.27,1.36 -1.21,1.23 -2.83,0.82 -2.32,-0.76 -1.17,3.09 0,0 -1.73,0.6 -2.18,-1.94 -2.38,0.28 -0.86,-1.66 -3.22,-2.45 0.24,1.61 -4.01,-1.87 1.08,-1.35 -0.81,-0.21 1.2,-9.96 -1.18,-2.82 0.42,-1.5 0.8,-0.07 -0.47,-0.71 -0.87,-0.13 -1.99,2.37 -1.14,-0.75 -0.06,1.39 -1.85,-1.58 -2.98,0.41 -2.41,-1.71 -1.19,-0.07 -0.7,1.09 -1.22,-0.43 2.09,-2.73 0.15,-2.86 -0.97,-1.88 1.47,-0.98 -1.77,-2.18 -1.59,0.43 0.7,-2.92 -2.57,0.9 -0.9,-2.18 -1.29,0.06 -0.54,-1.41 -0.87,-0.11 -4.5,2.76 -0.86,-0.76 -2.41,0.76 0,0 -1.59,-1.53 -0.29,-2.55 0,0 0.05,-5.27 0,0 0.97,-0.03 0.03,-0.95 3.78,-3.4 -4.01,-3.39 3.42,0.62 0.1,-0.92 1.64,-0.84 4.04,0.11 -0.37,-0.88 0.65,-0.26 1.37,0.56 0.91,-3.03 2.16,-2.21 1.82,0.03 -0.77,-1.76 -1.77,-0.46 0.15,-1.8 -1.31,0.1 -0.25,-1.36 1.21,0.48 z"
        title="Isère"
        id="FR-38"
        transform="scale(0.8)"
      />
      <path
        d="m 377.13408,302.40159 0.43,1.76 -0.79,1.92 3.42,0.7 0.86,1.82 0,0 1.04,0 0,0 3.5,-1.59 1.25,1.29 1.88,0.2 1.22,-1.23 0.75,1 1.19,-0.59 -0.19,1.74 1.06,0.13 2.17,-1.72 1.8,0.03 -0.37,-0.88 1.07,-1.25 0,0 1.35,0.57 -0.99,1.31 1.64,1.68 -0.82,1.48 -1.48,-0.8 -2.41,1.15 -0.78,3.59 -2.24,1.24 2.63,2.61 -0.55,1 -2.3,-0.35 4.19,3.86 -0.95,1.56 0.84,1.52 2.23,1.1 -0.77,4.49 -1.21,0.82 0.54,1.1 1.69,0.41 -1.53,4.36 3.15,2.78 1.19,2.24 6.03,0.61 1.11,1.89 1.58,-1.04 -1.21,1.73 0.1,2.61 1.81,0.62 1.08,-1.03 1.44,2.31 0,0 -0.05,5.27 0,0 -5.85,3.18 0.32,1.2 -1.33,1.04 0.43,1.36 -4.94,0.86 0,0 -1.94,-2.28 -1.91,0.98 0,0 -0.5,-0.19 0,0 -1.18,-0.79 0.9,-1.58 -1.63,-0.96 1.48,-1.13 -0.87,-1.5 -1.57,-0.55 -1.5,0.81 -1.62,-1.83 -3.08,0 -0.21,1.43 -3.01,0.39 -0.42,1.32 -1.05,-1.17 -1.05,0.38 -0.1,1.26 -1.74,-2.81 -1.67,0.23 -1.16,2.27 -0.44,-1.42 0,0 -0.24,-2.49 2.98,-2.14 0.89,-3.53 -0.99,-0.63 -1.13,-4.23 -4.57,-3.16 -1.94,-3.54 0.15,-1.99 -2,-1.5 -0.05,-1.44 -1.76,-0.98 1.39,-1.33 -0.29,-3.36 1.62,-0.94 -2.56,-2.62 0,0 0.62,-2.58 1.44,0.47 0.52,-1.03 1.33,0.57 1.8,-1.54 -1.07,-3.23 0.67,-2.1 -1.18,-1.39 -0.15,-2.63 0.8,-0.42 -1.69,-4.56 1.5,-0.09 -0.05,-1.05 2.67,-0.25 0.44,-0.81 0,0 z"
        title="Loire"
        id="FR-42"
        transform="scale(0.8)"
      />
      <path
        d="m 344.45408,357.26159 2.81,0.03 2.35,-2.51 2.18,-0.38 0.47,-1.21 1.1,1.37 1.19,-1.48 0.98,1.25 1.72,0.17 1.75,-0.2 1.6,-1.58 1.2,1.76 1.68,-0.21 1.98,3.78 1.92,-1.39 0.25,-1.29 1.59,0.52 -0.31,0.93 3.09,0.63 1.12,-2.54 1.39,0.51 0.07,1.01 2.51,0.49 0,0 0.44,1.42 1.16,-2.27 1.67,-0.23 1.74,2.81 0.1,-1.26 1.05,-0.38 1.05,1.17 0.42,-1.32 3.01,-0.39 0.21,-1.43 3.08,0 1.62,1.83 1.5,-0.81 1.57,0.55 0.87,1.5 -1.48,1.13 1.63,0.96 -0.9,1.58 1.18,0.79 0,0 0.5,0.19 0,0 1.91,-0.98 1.94,2.28 0,0 -0.14,2.68 -1.51,2.64 0.6,1.56 -0.79,-0.21 -0.11,0.74 -0.53,-1.55 -2.04,-0.44 1.04,2.28 -1.66,0.33 -0.53,1.84 1.51,1.57 -3.57,1.28 -0.25,1.33 0.97,1.38 -3.69,0.58 -2.84,5.27 -5.06,0.04 -0.26,2.26 -0.94,-0.26 -0.23,1.06 -0.73,-0.76 0.24,1.25 -1.62,-0.9 -1.6,2.85 0.53,0.55 -2.58,1.42 0,0 -1.07,-0.21 0.3,-1.42 -1.39,0.44 -2.01,-2.11 -0.74,-2.18 -3.19,0.55 0.28,-1.63 -1.8,-1.44 -1.51,0.32 -0.21,2.9 -0.77,-0.57 -3.82,1.54 -2.54,-5.19 -0.52,-3.43 -0.56,0.38 -1.23,-1.2 0,0 -0.7,-0.85 1.08,-1.09 -1.79,-0.31 0.41,-0.92 -1,0.38 -0.58,-0.8 0.54,-3.01 -1.21,-1.15 3.22,-0.02 -2.97,-0.99 -1.16,-3.11 0,0 0,-0.58 0,0 0.5,-1.47 -1.52,-0.58 -0.57,-3.43 -2.43,0.49 -0.18,-1.56 -3.22,0.35 1.32,-1.22 -0.98,-0.86 0.92,-0.17 z"
        title="Haute-Loire"
        id="FR-43"
        transform="scale(0.8)"
      />
      <path
        d="m 321.84408,310.33159 2.97,1.46 1.47,-3.18 2.04,-0.59 0.38,-2.43 3.2,1.57 0.93,-3.04 3.64,-0.23 1.1,0.59 -1.34,1.61 -0.01,2.16 1.6,0.44 0.68,2.78 1.9,1.28 3.2,-0.73 0.29,1.86 1.28,0.81 7.19,0.25 1.22,1.24 3.61,-1.74 0.79,0.87 0,0 0.99,0 0,0 0.1,3.1 5.27,-0.93 1.71,3.68 1.84,0.23 0.7,1.65 0,0 2.56,2.62 -1.62,0.94 0.29,3.36 -1.39,1.33 1.76,0.98 0.05,1.44 2,1.5 -0.15,1.99 1.94,3.54 4.57,3.16 1.13,4.23 0.99,0.63 -0.89,3.53 -2.98,2.14 0.24,2.49 0,0 -2.51,-0.49 -0.07,-1.01 -1.39,-0.51 -1.12,2.54 -3.09,-0.63 0.31,-0.93 -1.59,-0.52 -0.25,1.29 -1.92,1.39 -1.98,-3.78 -1.68,0.21 -1.2,-1.76 -1.6,1.58 -1.75,0.2 -1.72,-0.17 -0.98,-1.25 -1.19,1.48 -1.1,-1.37 -0.47,1.21 -2.18,0.38 -2.35,2.51 -2.81,-0.03 0,0 -2.15,1.22 -0.35,1.69 -2.15,0.72 -2.13,-1.08 -1.08,-3.52 -1.6,-0.9 -0.69,0.44 -2.27,-1.44 -1.18,0.94 -1.76,-0.52 -1.14,0.68 -1.42,-1.5 0.33,-1.8 -2.66,-0.38 -0.26,-1.52 -1.26,1.22 -0.92,-0.95 -1.19,0.52 0.29,-1.31 -1.46,0.01 0,0 0.38,-4.47 -1.18,-0.39 -1.16,-2.08 1.03,-2.65 1.41,0 0.41,-1.06 -0.71,-0.47 0.45,-2.58 -1.33,-1.67 0,0 -2,-1.23 -2.29,-4.04 2.42,-1.05 -0.07,-1.28 1.98,0.15 0.31,-1.35 2.35,-1.61 -0.51,-1.1 0.83,-1.46 1.86,-0.62 -0.67,-1.43 0.36,-2.61 -1.25,-0.73 -0.89,-2.32 z"
        title="Puy-de-Dôme"
        id="FR-63"
        transform="scale(0.8)"
      />
      <path
        d="m 397.42408,305.73159 0.38,-3.76 1.26,-1.17 2.54,0.84 0.64,1.26 1.77,-0.39 0.62,-1.24 0.91,1.53 1.45,0.22 0.79,-2.12 1.77,-0.31 1.27,1.65 -1.29,1.18 2.35,1.53 -0.7,0.35 0.66,0.9 -0.39,1.94 2.25,0.22 0,0 0.94,1.49 -2.33,3.73 0.32,6.96 -1,1.81 3.27,1.31 -0.17,1.4 1.8,-0.99 1.39,1 1.54,3.26 0,2.26 7.62,-0.63 0,0 -0.53,1.8 -1.21,-0.48 0.25,1.36 1.31,-0.1 -0.15,1.8 1.77,0.46 0.77,1.76 -1.82,-0.03 -2.16,2.21 -0.91,3.03 -1.37,-0.56 -0.65,0.26 0.37,0.88 -4.04,-0.11 -1.64,0.84 -0.1,0.92 -3.42,-0.62 4.01,3.39 -3.78,3.4 -0.03,0.95 -0.97,0.03 0,0 -1.44,-2.31 -1.08,1.03 -1.81,-0.62 -0.1,-2.61 1.21,-1.73 -1.58,1.04 -1.11,-1.89 -6.03,-0.61 -1.19,-2.24 -3.15,-2.78 1.53,-4.36 -1.69,-0.41 -0.54,-1.1 1.21,-0.82 0.77,-4.49 -2.23,-1.1 -0.84,-1.52 0.95,-1.56 -4.19,-3.86 2.3,0.35 0.55,-1 -2.63,-2.61 2.24,-1.24 0.78,-3.59 2.41,-1.15 1.48,0.8 0.82,-1.48 -1.64,-1.68 0.99,-1.31 z"
        title="Rhône"
        id="FR-69"
        transform="scale(0.8)"
      />
      <path
        d="m 457.20408,322.53159 1.47,0.61 0.39,5.58 1.27,0.42 0.41,1.38 2.05,-0.52 1.3,3.88 2.05,0.51 0.48,-0.7 1.93,0.95 0.03,-1.62 0.95,0.72 1.69,-0.34 1.24,1.37 -0.16,2.25 1.27,-0.7 0.23,1.44 4.2,-0.65 1.78,-3.61 -0.38,-0.86 2.59,-2.07 1.07,-3.51 2.44,-2.75 2.58,1.67 -0.12,1.1 -1.42,0.02 1.06,2.52 1.57,1.6 2.61,-0.43 1.24,1.74 -0.05,2.55 0.98,0.25 1.78,-2.57 1.84,-0.77 0,0 1.14,4.57 3.18,1.83 0.45,1.19 3.53,0.74 -0.97,3.04 0.77,0.8 0.23,4.17 2.22,0.55 -0.23,1.32 2.11,0.15 0.61,2.15 3.02,1.63 -1.01,2.64 -2.19,1.96 1.03,4.2 -2.21,2.31 -3.34,-0.2 -2.32,2.96 -2.18,0.09 -0.01,1.86 -1.84,0.56 -3.37,-1.9 -1.06,1.26 -2.57,-0.15 -2.04,1.8 0,0 -2.19,-0.87 -1.51,1.41 -1.3,-0.56 -1.25,0.96 0.26,2.22 -1.47,0.18 -3.58,-1.06 -0.03,-1.9 -1.28,-1.21 -1.63,0.89 -1.39,-1.08 0,0 -0.45,-1.45 -1.13,0.64 -1.76,-1.46 -1.52,0.63 0.74,-2.02 -1.65,-3.24 0.77,-1.06 -0.41,-1.41 2.31,-1.96 0.37,-1.99 -1.04,-0.3 0.94,-0.66 -2.55,-3.83 -3.4,-0.14 -3.45,-3.25 -2.15,0.9 -0.51,4.04 1.04,-0.35 -0.77,1.43 -0.77,-0.09 -4.16,-2.92 -2.1,0.05 -1.1,-3.96 -1.64,-1.49 0,0 0,-0.65 0,0 -2,-4.14 0,0 2.58,-1.67 0.61,-4.09 1.97,0.3 1.08,-1.12 z"
        title="Savoie"
        id="FR-73"
        transform="scale(0.8)"
      />
      <path
        d="m 497.26408,296.93159 -0.9,0.54 0.57,1.43 3.32,3.23 -2.52,4.58 -0.28,3.95 4.2,0.77 -1.05,4.35 0.71,0.53 1.93,-1.22 3.65,5.09 -0.63,0.48 1.16,0.93 0.28,1.79 -1.74,1.45 -0.25,1.67 -2.31,1.49 -4.97,0.57 -0.86,3.43 0,0 -1.84,0.77 -1.78,2.57 -0.98,-0.25 0.05,-2.55 -1.24,-1.74 -2.61,0.43 -1.57,-1.6 -1.06,-2.52 1.42,-0.02 0.12,-1.1 -2.58,-1.67 -2.44,2.75 -1.07,3.51 -2.59,2.07 0.38,0.86 -1.78,3.61 -4.2,0.65 -0.23,-1.44 -1.27,0.7 0.16,-2.25 -1.24,-1.37 -1.69,0.34 -0.95,-0.72 -0.03,1.62 -1.93,-0.95 -0.48,0.7 -2.05,-0.51 -1.3,-3.88 -2.05,0.52 -0.41,-1.38 -1.27,-0.42 -0.39,-5.58 -1.47,-0.61 0,0 0.24,-2.05 -1.17,-2.49 0.59,-5.3 2.77,1.09 0.26,-1.99 2.64,-0.81 0,0 1.56,-0.72 1.81,0.44 0.6,-0.86 3.17,0.72 4.31,-3.95 2.62,-1.15 0.59,-1.85 -2.02,0.28 -0.96,-1.55 0.43,-1.57 -1.14,-0.5 1.33,-2.89 11.07,-5.82 6.78,0.15 5.73,1.65 z"
        title="Haute-Savoie"
        id="FR-74"
        transform="scale(0.8)"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "AuvergneRhoneAlpes",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x * 0.789 + b.width / 2 - 10);
      t.setAttribute("y", b.y * 0.78 + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>