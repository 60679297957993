<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="120 25 200 200"
      style="transform: scale(1.3);"
    >
      <path
        d="m 227.60408,107.59159 0.18,3.92 0.99,1.99 -1.22,1.53 1.16,1.23 -0.58,0.83 0.71,0.34 1.5,-1.21 0.87,0.74 0,1.35 -2.62,1.02 3.07,2.39 -0.39,3.58 2,0.81 -1.35,2.19 0.79,1.95 -1.03,-0.15 -1.14,1.58 3.29,2.1 -1.36,4.4 0,0 -1.58,-1.4 -1.65,1.86 -1.21,-0.81 -1.25,0.41 -0.01,-1.12 -1.3,0.4 -0.55,1.32 -1.43,-0.22 -1.82,1.18 -1.2,-1.7 -1.92,0.87 -1.03,2.23 -2.56,1.01 0.03,0.87 -0.86,-0.29 -1.71,2.31 -2.33,-0.5 -1,0.77 0.93,1.23 -2.69,-1.59 -4.76,-1.02 -0.99,0.11 -0.72,2.11 -1.15,-0.79 0.55,-0.83 -1.04,0.17 -0.94,-1.6 -1.47,0.09 0,0 0,0 0,0 -5.34,2.48 -3.05,0.18 0.11,-0.83 -2.06,-0.41 -0.49,0.61 0.92,0.95 -1.3,1.37 -2.62,0.79 -2.62,2.23 0,0 0.75,-1.23 -2.52,0.98 -2.84,-2.07 -0.87,1.04 -4.58,-0.33 -0.81,-2.34 -2.36,-0.76 2.55,-3.18 1.1,0.45 2.14,-2.26 -0.12,-1.31 -2.26,0.28 0.81,-2.23 2.42,0.51 2.22,-0.98 1.72,-2.92 1.8,-1.15 -1.28,-1.09 0.88,-1.32 -0.38,-3.09 -1.83,0.9 1.49,-1.72 -2.98,-2.07 1.38,-0.12 0.47,-1.71 1.13,-0.63 -1.6,-1.94 -1.99,2.59 -4.33,-2.73 -2.4,-2.31 0.66,-0.61 -0.81,-1.9 1.06,-0.99 -0.11,-1.14 0,0 -0.07,-1.01 1.81,-1.89 5.14,-0.44 5.37,2.53 17.75,1.52 7.17,3.22 9.15,-2.7 5.33,-4.86 6.56,-2 0,0 z"
        title="Calvados"
        id="FR-14"
      />

      <path
        d="m 227.60408,107.59159 3.59,-0.74 4.82,-2.59 3.57,3.42 2.32,-0.81 -0.56,1.44 0.98,0 0.41,1.23 4.31,-1.07 0.81,0.57 0.68,-1.12 0.79,1.97 2.36,-0.01 0.06,1.56 1.61,-0.82 0.07,2.95 -2.46,-0.53 -0.64,1.45 2.25,0.51 0.15,1.16 1.55,-1.56 0.99,1.73 -0.33,1.53 0.7,-0.61 1.42,1.89 2.34,-0.48 -0.61,-2.54 3.63,-0.7 0.98,-2.04 1.85,0.36 -0.17,-1.2 3.08,0.56 1.51,-5.27 1.12,0.05 0.32,-1.28 1.75,-0.93 -0.1,0.91 3.86,-0.18 2.5,1.38 1.56,-0.59 1.22,1.75 2.1,0.8 1.02,-0.47 0.58,0.76 0.83,-0.81 0,0 1.07,0.11 -0.76,0.78 1.64,1.74 0.47,4.74 1.05,0.11 0.26,0.98 -0.62,1.81 -1.29,-1.67 -2.02,0.49 -0.24,2.09 0,0 -1.23,1.29 -0.69,5.04 -2.03,3.42 0,0 -3.56,0.59 -0.53,-1.11 -0.88,2.2 -1.07,-0.84 -0.6,0.66 1.26,2.49 -0.34,2.41 2.02,-0.17 -1.1,0.72 0.36,1.69 0,0 0,0 0,0 -2.21,1.05 0.97,1.68 -0.46,1.57 -4.08,2.36 0.43,3.35 -1.79,1.34 -2.99,0.15 -0.56,-0.8 -0.88,0.76 -4.19,-1.97 -0.28,2.71 -2.14,-0.76 -2.02,2.06 0,0 -1.18,0 0,0 -2.66,1.03 -1.9,-0.4 -0.53,1.74 -2,1.11 0,0 -1.86,0.04 -0.07,-1.62 -1.73,-0.73 1.91,-1.93 -0.66,-1.87 -6.12,-4.07 1.12,-1.41 -1.09,-0.02 0.22,-1.44 -1.08,0.36 -0.37,-1.32 -1.24,1.17 -5.33,-0.77 -1.6,-1.61 1.25,-2.37 0,0 1.36,-4.4 -3.29,-2.1 1.14,-1.58 1.03,0.15 -0.79,-1.95 1.35,-2.19 -2,-0.81 0.39,-3.58 -3.07,-2.39 2.62,-1.02 0,-1.35 -0.87,-0.74 -1.5,1.21 -0.71,-0.34 0.58,-0.83 -1.16,-1.23 1.22,-1.53 -0.99,-1.99 z"
        title="Eure"
        id="FR-27"
      />

      <path
        d="m 135.51408,88.801588 1.44,1.36 1.46,-0.76 1.14,1.75 5.67,1.07 3.47,1.77 -0.11,-0.67 4.5,-0.61 0.98,-1.99 4.31,-0.63 4.33,0.68 1.5,5.71 -1.08,-0.38 -0.07,1.71 -1.71,0.26 -0.35,2.750002 5.8,8.42 -0.62,3.71 1.12,-0.88 1.59,1.18 0,0 0.11,1.14 -1.06,0.99 0.8,1.9 -0.66,0.6 2.4,2.31 4.32,2.73 2,-2.59 1.6,1.94 -1.13,0.63 -0.47,1.7 -1.38,0.12 2.98,2.08 -1.49,1.72 1.83,-0.9 0.39,3.08 -0.88,1.32 1.28,1.09 -1.81,1.15 -1.71,2.91 -2.22,0.98 -2.42,-0.5 -0.81,2.22 2.26,-0.28 0.12,1.3 -2.15,2.27 -1.1,-0.45 -2.55,3.18 2.36,0.77 0.82,2.34 4.57,0.33 0.87,-1.04 2.84,2.07 2.53,-0.99 -0.75,1.23 0,0 -0.42,1.36 1.36,-0.04 3.39,2.84 -1.55,1.79 1.42,2.26 -1.47,3.65 -3.56,3.82 0,0 -2.03,0.31 -1.94,-1.29 -0.71,1.43 -1.3,0.32 -2.72,-1.18 0,0 -7.63,-2.23 -1.05,2.15 -2.05,0.66 -1.05,1.87 -2.8,0.5 -1.21,-1.64 -1.58,-0.12 -3.46,-9.09 0,0 2.62,0.48 -0.1,-0.68 1.01,0.78 3.95,-1.22 1.56,0.77 -2.41,-1.68 1.05,-1.03 -2.54,1.28 -0.11,-1.02 -1.99,-0.78 -1.2,-2.92 -1.75,-1.04 -0.18,-4.7 -1.66,-0.84 1.25,-0.86 0.91,-4.83 0.78,-0.35 -0.8,-0.65 0.21,-5.37 0.93,-0.47 1.27,0.85 -1.57,-1.3 -0.77,0.32 -0.72,2.27 -0.9,-2.41 -0.42,-4.06 0.72,-2.11 -0.92,-5.21 1.63,-0.32 -1.64,-0.7 -0.51,1.57 -3.52,-7.38 -2.69,-2.87 -1.39,-0.12 -1.59,-8.36 -1.65,-1.93 1.96,-2.350002 -0.33,-3.85 -1.11,-1.78 -2.99,-1.02 -0.02,-2.61 1.13,-0.67 z"
        title="Manche"
        id="FR-50"
      />

      <path
        d="m 180.36408,150.62159 2.62,-2.23 2.62,-0.79 1.3,-1.37 -0.92,-0.95 0.49,-0.61 2.06,0.41 -0.11,0.83 3.05,-0.18 4.37,-2.55 2.44,-0.02 0.94,1.6 1.04,-0.17 -0.55,0.83 1.15,0.79 0.72,-2.11 0.99,-0.11 4.76,1.02 2.69,1.59 -0.93,-1.23 1,-0.77 2.33,0.5 1.71,-2.31 0.86,0.29 -0.03,-0.87 2.56,-1.01 1.03,-2.23 1.92,-0.87 1.2,1.7 1.82,-1.18 1.43,0.22 0.55,-1.32 1.3,-0.4 0.01,1.12 1.25,-0.41 1.21,0.81 1.65,-1.86 1.58,1.4 0,0 -1.25,2.37 1.6,1.61 5.33,0.77 1.24,-1.17 0.37,1.32 1.08,-0.36 -0.22,1.44 1.09,0.02 -1.12,1.41 6.12,4.07 0.66,1.87 -1.91,1.93 1.73,0.73 0.07,1.62 1.86,-0.04 0,0 0.68,2.27 -0.54,1.36 1.17,0.15 0.18,1.7 3.09,1.39 0.56,0.67 -0.63,0.79 1.75,0.83 -1.01,1.47 0.55,1.11 -0.81,0.36 0.17,1.27 1.49,0.99 -2.57,4.22 -5.25,1.97 -0.73,1.17 -0.61,1.42 2.11,0.66 -0.75,1.75 1.75,2.94 -1.35,1.2 0,0 -1.55,0.85 -0.93,-0.48 -2.25,-4.12 -1.25,-0.58 -0.92,0.48 0.3,1.43 -0.62,-0.8 -3.67,-0.17 -1.76,-2.24 -0.14,-1.58 -0.43,0.72 -3.62,-1.45 -0.91,-5.61 0.51,-0.82 -1.1,-2.14 -2.28,-1.35 -5.33,0.97 0.06,0.91 -1.22,-0.43 0.51,1 -3.55,2.05 -0.56,1.92 -1.81,-0.19 -0.64,-1.03 -2.29,1.01 0,0 0.23,-4.37 -3.99,-0.36 -1.12,-3.35 1.17,-1.09 -2.57,-2.22 -1.74,-0.05 -0.57,0.98 0.89,0.78 -1.24,1.81 -2.07,-0.94 -1.33,2.39 -1.81,-1.61 -4.7,0.06 -1.72,2.24 -1.92,0.1 -2.24,1.71 -0.69,-2.64 -0.86,1.13 -1.73,-0.32 0.65,1.54 -2.08,0.75 -0.58,-1.79 -1.42,0.65 -1.95,-2.91 0,0 3.56,-3.82 1.47,-3.65 -1.42,-2.26 1.55,-1.79 -3.39,-2.84 -1.36,0.03 z"
        title="Orne"
        id="FR-61"
      />
      <path
        d="m 272.46408,67.141588 1.27,0.47 1.68,-0.84 0.15,1.97 2.56,1.69 3.74,4.53 3.06,1.64 4.45,10.26 0,0 -1.49,-0.38 -0.13,1.56 -1.36,0.29 0.05,1.76 -1.07,0.78 0.71,0.9 1.58,-1.37 0.31,0.47 -2.25,3.24 0.99,1.34 -1.13,1.42 1.13,0.79 -1.29,0.57 1.01,0.17 0.4,2.090002 1.02,0.41 -1.32,2.12 1.17,0.99 0.61,-1.42 1.28,0.5 -3.17,6.14 0,0 -0.83,0.81 -0.59,-0.76 -1.02,0.47 -2.1,-0.8 -1.22,-1.75 -1.56,0.59 -2.5,-1.38 -3.85,0.18 0.1,-0.91 -1.75,0.93 -0.32,1.28 -1.12,-0.05 -1.52,5.27 -3.08,-0.57 0.17,1.2 -1.86,-0.36 -0.98,2.04 -3.63,0.7 0.62,2.54 -2.34,0.48 -1.43,-1.9 -0.7,0.61 0.33,-1.52 -0.98,-1.73 -1.55,1.56 -0.15,-1.16 -2.25,-0.51 0.64,-1.45 2.46,0.53 -0.07,-2.96 -1.61,0.82 -0.07,-1.55 -2.35,0.01 -0.79,-1.97 -0.68,1.12 -0.81,-0.58 -4.31,1.08 -0.41,-1.23 -0.98,-0.01 0.56,-1.44 -2.32,0.81 -3.57,-3.42 -4.82,2.59 -3.59,0.74 0,0 0,0 0,0 -0.52,0.02 0,0 -0.17,-1.19 -0.41,0.82 -6.53,-2.01 -1.98,-2.54 4.06,-11.310002 1.23,-1.25 5.94,-2.61 10.37,-7.04 7.75,-1.14 7.77,-3.29 2.45,0.71 3.61,-1.43 11.3,-8.18 z"
        title="Seine-Maritime"
        id="FR-76"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "Normandie",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (p.getAttribute("title") == "Orne") {
        t.setAttribute("x", b.x + b.width / 2 - 15);
        t.setAttribute("y", b.y + b.height / 2 - 15);
      }
      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>