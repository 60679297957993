<template>
  <v-row
    class="grey--text text--lighten-5 font-weight-bold marque-title justify-center pa-4 border-text ellipsis "
    ref="detailsCar"
    v-resize="onResize"
    :style="{ fontSize: fontSizeValue }"
    no-gutters
  >
    <span class="format-marque text-h3 overide-h3">{{ car_data.marque || "" }}</span>
    <span class="ml-3 format-marque ellipsis text-h3 overide-h3" v-show="$vuetify.breakpoint.lgAndUp">{{
      car_data.famille || ""
    }}</span>
  </v-row>
</template>

<script>
export default {
  props: {
    car_data: {
      type: Object,
      default: () => {
        ({});
      },
    },
  },

  data() {
    return {
      fontSizeValue: "16px",
    };
  },
  methods: {
    onResize() {
      this.fontSizeValue = (22 / 100) * ((this.$refs.detailsCar.offsetWidth / 1920) * 100) + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.overide-h3 {
  font-size: 3em !important;
  line-height: 1.5em;
}

.format-marque {
  display: inline-block;
  vertical-align: middle;
}
.ellipsis {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}

.marque-title {
  z-index: 1;
  text-align: center;
  position: absolute;
  text-align: center;
  width: 100%;
}
.border-text {
  -webkit-text-stroke-width: 0.7px;
  -webkit-text-stroke-color: black;
  /* text-shadow: 1px 1px 2px #2787d5 !important; */
  text-shadow: 2px 2px 2px black !important;
}
</style>
