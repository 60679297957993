const weather = {
    data() {
        return {
            list_icon: {
                "01d": "045-sun.svg",
                "01n": "045-sun.svg",
                "02d": "096-cloud-2.svg",
                "02n": "096-cloud-2.svg",
                "03d": "098-cloud.svg",
                "03n": "098-cloud.svg",
                "04d": "098-cloud.svg",
                "04n": "098-cloud.svg",
                "09d": "063-rain-3.svg",
                "09n": "063-rain-3.svg",
                "10d": "063-rain-3.svg",
                "10n": "063-rain-3.svg",
                "11d": "024-storm-19.svg",
                "11n": "024-storm-19.svg",
                "13d": "058-sleet.svg",
                "13n": "058-sleet.svg",
                "50d": "075-haze.svg",
                "50n": "075-haze.svg"
            }
        }
    },

    mounted() {
        Date.prototype.timeNow = function () {
            return (
                (this.getHours() < 10 ? "0" : "") +
                this.getHours() +
                ":" +
                (this.getMinutes() < 10 ? "0" : "") +
                this.getMinutes()
            );
        };
    },

    methods: {
        getCurrentDate() {
            return new Date().toLocaleDateString("fr-FR", {
                // get proper date
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
            });
        },

        place_weather(refs) {
            if (this.weather_icon_departement.length > 0)
                this.weather_icon_departement = [];

            if (!refs["container-svg"])
                return -1;

            for (let i = 0; i < this.weather_paths.length; i++) {

                if (this.weather?.hasOwnProperty(this.weather_paths[i].title)) {

                    let { path, container_svg, boundingRect } = this.getAllElements(i, refs);

                    // this.weather (props) has not been received yet
                    if (path == null) return -1;

                    let icon_size = this.getIconSize();

                    this.weather_icon_departement.push({
                        id: `icon_${this.weather_paths[i].id}`,
                        src: require(`@/assets/weather/${this.list_icon[
                            this.weather[this.weather_paths[i].title].currently.icon || list_icon["01d"]
                        ]
                            }`),
                        x:
                            boundingRect.x -
                            container_svg.x +
                            boundingRect.width / 2 -
                            icon_size / 2 + (this.weather_paths[i].ajustement ? this.weather_paths[i].ajustement.x : 0),
                        y:
                            boundingRect.y - 22 -
                            container_svg.y +
                            boundingRect.height / 2 -
                            icon_size / 2 + (this.weather_paths[i].ajustement ? this.weather_paths[i].ajustement.y : 0),
                        width: icon_size,
                        height: icon_size,
                        temperature: Math.round(this.weather[this.weather_paths[i].title].currently.temperature)
                    });
                }
            }
        },

        getIconSize() {
            this.icon_size = 10;

            if (this.$vuetify.breakpoint.xs) this.icon_size = 30;
            if (this.$vuetify.breakpoint.sm) this.icon_size = 30;
            if (this.$vuetify.breakpoint.md) this.icon_size = 45;
            if (this.$vuetify.breakpoint.lgAndUp) this.icon_size = 55;
            return this.icon_size;
        },

        getAllElements(idx, refs) {
            let path = null,
                container_svg = null,
                boundingRect = null;

            path = refs[this.weather_paths[idx].title][0];

            if (!path || !path.getBoundingClientRect)
                return { path: null, container_svg, boundingRect };

            container_svg = refs["container-svg"].getBoundingClientRect();
            boundingRect = path.getBoundingClientRect();

            return { path, container_svg, boundingRect };
        },
    }
}

export const weather_props = {
    weather: { type: Object }
}

export default weather;