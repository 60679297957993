<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="364 330 200 200"
      style="transform: scale(1.5);"
    >
      <path
        d="m 450.89408,424.90159 4.99,-1.28 4.7,1.34 0.15,-0.85 -1.51,-0.55 -2.14,-3.89 0.5,-0.95 1.22,0.12 2.29,2.77 0.17,-3.85 -1.14,-0.08 0.18,-1.21 1.54,-1.33 0.5,-2.09 4.33,-3.15 0.82,0.71 0.33,-2.28 3.09,0.93 -0.3,1.15 0.98,0.1 2.18,3.36 1.45,-1.75 -1.2,-2.93 2.62,-1.1 1.27,1.03 0.84,-2 -0.62,-0.65 1.25,-0.74 2.05,3.04 0.95,-0.27 1.42,1.37 6.79,0.23 1.97,-5.45 2.51,-0.73 1.15,-1.97 3.13,-1.24 2.8,-2.88 1.63,0.21 0,0 0.69,2.13 -0.75,0.06 -0.72,2.58 -3.19,2.53 0.94,2.86 2.91,2.91 -2.3,0.48 -0.19,3.51 0,0 -3.16,1.7 -0.91,3.33 -1,-0.34 -1.43,1.63 -1.83,4.76 2.94,5.16 -0.43,2.23 3.73,2.92 0.48,2.08 1.02,0.03 2.91,3.13 -2.4,0.62 -2.25,-1.68 -3.38,2.66 -3.18,-0.96 1.2,1.79 -1.45,1.52 1.86,0.96 -3.18,1.42 0,0 -0.46,-0.9 -1.48,-0.03 -1.93,1.73 -1.01,-1.95 -1.36,1.03 -2.9,-0.05 -0.03,1.78 -1.29,1.52 -2.37,-0.74 -3.16,-3.12 -1.45,0.11 -2.33,3.06 -2.17,0.07 -3.51,4.31 -1.45,-2.6 -2.06,-0.93 -0.48,-1.53 -0.96,0.21 -0.08,1.26 -2.12,0.79 -0.84,-1.7 -2.02,-0.32 -0.86,1.19 0,0 -4.38,-5.31 -3.28,-0.27 -1.26,0.79 0.08,-2 2.55,-3.66 -1.15,0.02 -0.56,-1.52 -2.46,-0.57 1.49,-6.67 -1.65,0.2 -0.25,-3.02 0,0 3.33,-2.08 -0.59,-1.08 0.56,-1 1.31,-0.13 1.25,1.3 -0.35,1 1.96,0.24 0.17,-0.99 -1.63,-0.3 0.36,-1.19 z"
        title="Alpes-de-Haute-Provence"
        id="FR-04"
      />
      <path
        d="m 475.15408,370.50159 1.39,1.08 1.63,-0.89 1.28,1.21 0.03,1.9 3.58,1.06 1.47,-0.18 -0.26,-2.22 1.25,-0.96 1.3,0.56 1.51,-1.41 2.19,0.87 0,0 1.75,5.3 2.94,0.33 -0.23,1.48 1.07,1.67 -0.87,1.25 0.31,1.97 4.67,3.21 1.95,0.38 1.05,-1.06 3.6,2.26 -0.94,2 3.19,6.09 -3.71,-0.37 -1.52,2.01 0,0 -1.63,-0.21 -2.8,2.88 -3.13,1.24 -1.15,1.97 -2.51,0.73 -1.97,5.45 -6.79,-0.23 -1.42,-1.37 -0.95,0.27 -2.05,-3.04 -1.25,0.74 0.62,0.65 -0.84,2 -1.27,-1.03 -2.62,1.1 1.2,2.93 -1.45,1.75 -2.18,-3.36 -0.98,-0.1 0.3,-1.15 -3.09,-0.93 -0.33,2.28 -0.82,-0.71 -4.33,3.15 -0.5,2.09 -1.54,1.33 -0.18,1.21 1.14,0.08 -0.17,3.85 -2.29,-2.77 -1.22,-0.12 -0.5,0.95 2.14,3.89 1.51,0.55 -0.15,0.85 -4.7,-1.34 -4.99,1.28 0,0 0.44,-4.37 -1.64,0 -0.43,-1.95 -1.19,-0.33 0.88,-1.57 -3.38,0.18 -1.1,-1.18 -1.25,0.72 -1.21,-1.67 -1.21,-0.21 0.43,-1.18 -1.03,-1.93 0.82,-0.51 1.6,0.81 0.84,-0.92 -1.36,-0.72 -0.27,-2.98 5.98,1.97 1.1,-2.02 1.44,-0.09 -2.75,-2.45 2.11,-3.94 -0.26,-2.22 3.63,0.64 0.84,-1.34 1.67,0.51 1.7,-2.16 -1.31,-0.93 0,0 1.17,-3.09 2.32,0.76 2.83,-0.82 1.21,-1.23 -1.27,-1.36 3.02,-1.89 1.42,1.24 3.22,-2.82 4.7,0.72 2.03,-1.29 1.09,1.27 1.38,-0.15 0.11,-4.94 -1.48,-0.84 0.05,-2.63 -3,0.13 -1.96,-1.15 0.64,-2.83 1.02,-0.36 -0.54,-1.95 z"
        title="Hautes-Alpes"
        id="FR-05"
      />
      <path
        d="m 501.88408,415.28159 2.67,4.28 1.02,0.19 0.07,1.66 1.42,1.61 1.67,-0.48 3.01,1.85 1.83,-0.06 3.82,3.5 2.64,-0.31 0.6,1.78 1.46,-0.56 1.33,0.75 0.25,-1.05 1.26,0.28 4.19,-1.78 2.35,0.39 0.76,-1.57 1.96,0.11 -0.72,2.52 2.01,2.79 -0.4,2.36 -1.76,0.77 -0.44,3.13 -3.54,1.86 -0.31,2.57 -2.57,1.34 1.45,4.98 -1.84,2.2 -1.43,-0.53 -1.79,2.09 -2.92,0.53 -0.24,2.08 -0.74,-1.74 -0.45,1.21 -2.58,-0.22 -1.38,2.2 -2.16,-0.14 -1.27,2.89 0.47,3.24 -1.64,-1.44 -2.48,2.04 -0.74,-0.83 -2.05,0.24 -1.41,1.64 0.8,1.12 -1.15,1.16 0,0 -1.86,-1.5 0.46,-1.36 -0.71,-0.14 1.4,-3.96 -4.67,-1.67 -1.52,-2.17 0.51,-2.17 -0.89,-2.12 -1.68,0.1 -1.13,-1.03 -1.14,0.51 -0.98,-2.33 0,0 3.18,-1.42 -1.86,-0.96 1.45,-1.51 -1.2,-1.79 3.18,0.95 3.38,-2.66 2.25,1.68 2.4,-0.61 -2.9,-3.14 -1.03,-0.03 -0.48,-2.08 -3.72,-2.92 0.42,-2.23 -2.94,-5.16 1.83,-4.76 1.43,-1.63 1,0.34 0.91,-3.33 3.16,-1.71 0,0 0.72,0.16 z"
        title="Alpes-Maritimes"
        id="FR-06"
      />
      <path
        d="m 412.05408,440.35159 4.44,0.44 4.48,2.2 3.09,2.89 0.86,2.32 5.26,2.9 2.13,-0.74 3.38,0.6 4.76,3.06 4.32,1.44 3.15,0.03 2.8,-1.93 1.95,-0.04 1.51,-1.73 0,0 2.45,1.95 -0.71,1.7 -4.04,0.93 -0.87,1.86 0.56,1.55 -1.49,0.58 2.86,1.31 -1.06,3.89 3.72,3.54 -1.6,1.09 -2.81,0.14 0.93,2.46 -1.38,2.12 2.32,0.07 1.49,2 -0.1,0.83 -3.23,1.82 -0.48,3.15 0,0 -2.03,-0.39 -0.74,1.51 -2.84,-3.09 -1.52,1 -1.86,-0.83 -4.13,0.18 -0.45,-0.85 1.26,-1.79 -1.22,-1.53 0.61,-1.64 -1.43,-2.56 -1.32,-0.23 -2.86,1.79 -7.27,0.19 -1.33,-0.97 -0.68,-3.19 -1.13,-0.23 -0.18,-1.32 -2.07,-0.39 -1.31,1.06 0.72,-0.91 -1,0.59 -0.86,-2.03 0.69,2.52 -2.19,-0.8 1.51,1.31 -0.93,-0.25 -0.35,0.65 0.89,0.37 -0.67,0.68 1.78,1.14 -0.74,1.54 -1,0.07 -2.57,-1.18 -8,-0.67 -1,-1.16 1.56,-1.31 -0.21,-1.04 -2.38,-1.71 -12.58,-0.46 0,0 0.44,-2.25 3.4,-1.65 -0.41,-1.55 0.83,1.11 5.32,-3.08 0.43,-1.32 -1.9,-0.66 2.47,-4.19 2.27,-0.57 3.65,1.24 -0.63,-2.29 1.61,-3.22 -0.35,-2.8 1.01,-0.81 -1.07,-1.25 4.07,-3.21 z"
        title="Bouches-du-Rhône"
        id="FR-13"
      />
      <path
        d="m 454.39408,451.25159 0.86,-1.2 2.03,0.33 0.84,1.69 2.12,-0.78 0.08,-1.27 0.96,-0.21 0.48,1.54 2.06,0.93 1.45,2.6 3.51,-4.31 2.18,-0.07 2.33,-3.06 1.45,-0.11 3.16,3.12 2.37,0.73 1.29,-1.52 0.03,-1.78 2.9,0.06 1.36,-1.03 1,1.94 1.93,-1.73 1.48,0.04 0.46,0.89 0,0 0.98,2.33 1.14,-0.51 1.13,1.03 1.68,-0.1 0.89,2.12 -0.51,2.17 1.52,2.17 4.67,1.67 -1.4,3.96 0.71,0.14 -0.46,1.36 1.86,1.5 0,0 -0.39,1.58 -1.17,1.13 -1.38,-0.23 -0.14,1.19 -4.64,-0.23 -1.09,3.88 -5.45,3.87 0.34,0.99 2.13,-0.67 0.82,0.53 0.57,-0.82 0.81,0.72 -1.33,1.35 0.66,2.46 -1.76,1.54 -0.87,0.57 -0.76,-1.19 -1.59,-0.33 -2.68,2.06 -4.65,0.48 -1,1.7 0.34,1.44 -1.54,-0.03 -2.38,-1.78 -2.89,0.27 -1.84,1.58 -0.2,3.49 -2.49,-0.12 -0.07,-0.64 1.49,-0.23 -0.21,-1.98 -4.28,0.06 -0.82,-1.57 -3.13,0.12 -0.14,-1.18 -1.52,0.44 -0.14,0.82 1.2,0.07 -0.63,1.12 1.79,-0.2 0.41,0.97 -2.35,-0.62 -1.32,1.83 -1.29,-0.18 -1.29,-1.12 0.45,-2.58 -1.34,-0.12 -0.16,-1.22 -3.2,-0.27 -0.01,-1.42 -1.02,-0.62 0,0 0.48,-3.15 3.23,-1.82 0.1,-0.83 -1.49,-2 -2.32,-0.07 1.38,-2.12 -0.93,-2.46 2.81,-0.14 1.6,-1.09 -3.72,-3.54 1.06,-3.89 -2.86,-1.31 1.49,-0.58 -0.56,-1.55 0.87,-1.86 4.04,-0.93 0.71,-1.7 -2.45,-1.95 0,0 0.17,-0.56 z"
        title="Var"
        id="FR-83"
      />
      <path
        d="m 408.32408,416.87159 5.73,0.97 1.26,4.96 2.53,-1.93 1.76,0.06 5.72,-2.76 0.69,1.37 1.33,0.37 2.46,-2.16 -0.84,2.1 0.33,2.79 3.09,1.09 0.81,-1.16 3.57,1.65 0.83,-0.66 1.23,0.81 -0.08,2.6 2.18,0.2 0.68,1.8 1.94,0.27 0,0 0.25,3.02 1.65,-0.21 -1.49,6.68 2.46,0.56 0.56,1.52 1.15,-0.02 -2.54,3.67 -0.08,2 1.26,-0.79 3.27,0.27 4.38,5.31 0,0 -0.22,0.52 0,0 -1.51,1.73 -1.95,0.04 -2.8,1.93 -3.15,-0.03 -4.32,-1.44 -4.76,-3.06 -3.38,-0.6 -2.13,0.74 -5.26,-2.9 -0.86,-2.32 -3.09,-2.89 -4.48,-2.2 -4.44,-0.44 0,0 3.05,-2.32 -0.07,-1.37 1.19,0.09 0.12,-1.26 -2.39,-3.37 -2.74,-0.91 -0.64,-1.52 0.68,-4.58 -0.59,-1.55 -1.42,-0.07 -0.98,-3.18 0,0 0.05,-3.42 z m 14.12,-5.28 0.49,0.5 0,0 0.67,-0.2 -0.12,1.33 2.32,0.57 0,0 -0.01,0.35 0,0 -1.98,0.93 -1.51,3.92 -4.05,-0.68 0.22,-1.98 -0.87,-0.64 1.51,-2.73 0,0 0.48,-0.7 0,0 0.09,0.06 0,0 0.8,-0.34 0,0 0.68,-0.39 0,0 0.77,-0.2 0,0 0.43,0.05 0,0 0.08,0.15 z"
        title="Vaucluse"
        id="FR-84"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "ProvenceAlpesCotedAzur",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>