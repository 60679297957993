<template>
  <v-row
    class="justify-center align-center pa-0 absolute"
    ref="detailsCar"
    v-resize="onResize"
    :style="{ fontSize: fontSizeValue }"
    no-gutters
  >
    <v-card class="black--text card" dark color="grey lighten-4">
      <v-card-title class="text-break py-0 py-md-2 overide-title">
        <span class="font-weight-normal ellipsis text-break" style="max-width: 85%">
          {{ `${car_data.marque || ""} ${car_data.famille || ""} ${car_data.version || ""}` }}</span
        >
        <span class="ml-auto font-weight-medium" v-if="modified_price">{{ `${modified_price || ""}€` }}</span>
      </v-card-title>
      <v-card-text class="pb-0 overide-text">
        <v-row class="justify-around caption overide-caption" no-gutters>
          <v-col class="black--text text--lighten-2 text-break" v-if="car_data.code && car_data.reference">
            {{ `Référence : ${car_data.code || ""} ${car_data.reference || ""}` }}
          </v-col>
          <v-col>
            <v-row class="justify-end text-right" no-gutters>
              <v-col class="black--text text-break" v-if="modified_km">
                <span v-if="$vuetify.breakpoint.width > 446">{{
                  `Boîte ${car_data.boite == "Rob simple embray" ? "automatique -" : "manuelle -"} `
                }}</span>
                {{ car_data.annee || "" }} - {{ `${modified_km || ""} km` }}</v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "smallDetailsCar",

  props: {
    loyer: {
      type: Boolean,
      default: false,
    },
    loyer_duration: {
      type: Number,
      default: 0,
    },
    loyer_taeg: {
      type: Number,
      default: 0,
    },
    car_data: {
      type: Object,
      default: () => {
        ({});
      },
    },
    bottomLeft: {
      type: Boolean,
      default: false,
    },
    bottomRight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fontSizeValue: "16px",
    };
  },

  methods: {
    onResize() {
      this.fontSizeValue = (20 / 100) * ((this.$refs.detailsCar.offsetWidth / 1920) * 100) + "px";
    },

    formatted_loyer(loyer_duration, loyer_taeg) {
      /**
       * http://www.calculconversion.com/calcul-pret-automobile.html
       */
      let upper_part = (this.car_data.price * loyer_taeg) / 12;
      let bottom_part = 1 - Math.pow(1 + loyer_taeg / 12, loyer_duration * -1);

      let mensualité = upper_part / bottom_part;

      return Math.round(mensualité);
    },
  },

  computed: {
    modified_price() {
      this.car_data.price;

      return this.car_data?.price?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    modified_km() {
      this.car_data.km;

      return this.car_data?.km?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
}

.absolute {
  width: 100%;
  max-width: 100%;
  position: absolute;
  top: 0px !important;
}

.overide-text {
  font-size: 0.875em;
  line-height: 1.375em;
}

.overide-title {
  line-height: 1.375em;
  font-size: 1.1em !important;
}

.overide-caption {
  font-size: 0.85em !important;
  line-height: 1.25em;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
