<template>
  <v-container ref="container-svg" id="container-svg" class="no-overflow pa-0" style="position: relative">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      :viewBox="`${svg_viewBox.x} ${svg_viewBox.y} ${svg_viewBox.width} ${svg_viewBox.height}`"
    >
      <path
        v-for="path in weather_paths"
        :key="path.id"
        :d="path.d"
        :title="path.title"
        :ref="path.title"
        :id="path.id"
        transform="scale(1.2)"
        :class="{ highlight: weather[path.title] }"
      />
      <g v-for="city in extra_cities" :key="city.label">
        <circle :cx="city.dot.x" :cy="city.dot.y" v-if="city.dot" r="1" stroke="black" stroke-width="0.2" fill="#f8703d" />
        <text
          :x="city.text.x"
          :y="city.text.y"
          font-size="0.24rem"
          font-weight="bold"
          fill="#f0f0f0"
          :id="`text_${city.text.label}`"
        >
          {{ city.text.label }}
        </text>
      </g>
    </svg>
    <div
      v-for="depart in weather_icon_departement"
      :key="depart.id"
      :style="{ position: 'absolute', top: depart.y + 'px', left: depart.x + 'px' }"
    >
      <span class="font-weight-medium black--text temperature-shadow">{{ depart.temperature }}</span>
      <v-img
        :src="depart.src"
        :min-width="depart.width"
        :min-height="depart.height"
        :max-width="depart.width"
        :max-height="depart.height"
        class="w-heartbeat"
        contain
        alt
      />
    </div>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";
import weather_paths from "@/utils/weather_paths";

export default {
  name: "PaysdelaLoire",

  mixins: [mixins_weather],

  props: {
    ...weather_props,
  },

  data() {
    return {
      svg_viewBox: {
        x: 208,
        y: 420,
        width: 320,
        height: 220,
      },

      weather_icon_departement: [],
      weather_paths: weather_paths,

      extra_cities: [
        {
          dot: {
            x: 402,
            y: 610,
          },
          text: {
            x: 390,
            y: 607,
            label: "Perpignan",
          },
        },
        {
          dot: {
            x: 395,
            y: 624,
          },
          text: {
            x: 392,
            y: 621,
            label: "Céret",
          },
        },
        {
          dot: {
            x: 408,
            y: 581,
          },
          text: {
            x: 399,
            y: 586,
            label: "Narbonne",
          },
        },
        {
          dot: {
            x: 419,
            y: 569,
          },
          text: {
            x: 413,
            y: 566,
            label: "Béziers",
          },
        },
        {
          dot: {
            x: 454,
            y: 550,
          },
          text: {
            x: 443,
            y: 547,
            label: "Montpelliers",
          },
        },
        {
          dot: {
            x: 475,
            y: 538,
          },
          text: {
            x: 469,
            y: 543,
            label: "Nîmes",
          },
        },
        {
          dot: {
            x: 461,
            y: 514,
          },
          text: {
            x: 457,
            y: 511.5,
            label: "Alès",
          },
        },
        {
          dot: {
            x: 435,
            y: 524,
          },
          text: {
            x: 427,
            y: 521.5,
            label: "Le Vigan",
          },
        },
        {
          dot: {
            x: 437,
            y: 504,
          },
          text: {
            x: 431,
            y: 501,
            label: "Florac",
          },
        },
        {
          dot: {
            x: 388,
            y: 499,
          },
          text: {
            x: 382,
            y: 496,
            label: "Rodez",
          },
        },
        {
          dot: {
            x: 412,
            y: 518,
          },
          text: {
            x: 407,
            y: 515,
            label: "Millau",
          },
        },
        // {
        //   dot: {
        //     x: 360,
        //     y: 501,
        //   },
        //   text: {
        //     x: 350,
        //     y: 494,
        //     label: "Villefranche",
        //   },
        // },
        // {
        //   text: {
        //     x: 350,
        //     y: 498,
        //     label: "de Rouergue",
        //   },
        // },
        {
          dot: {
            x: 341,
            y: 585,
          },
          text: {
            x: 336,
            y: 590,
            label: "Pamiers",
          },
        },
        {
          dot: {
            x: 341,
            y: 595,
          },
          text: {
            x: 339,
            y: 601,
            label: "Foix",
          },
        },
        {
          dot: {
            x: 315,
            y: 593,
          },
          text: {
            x: 304,
            y: 590,
            label: "Saint-Girons",
          },
        },
        {
          dot: {
            x: 295,
            y: 585,
          },
          text: {
            x: 283,
            y: 582,
            label: "Saint-Gaudens",
          },
        },
        {
          dot: {
            x: 253,
            y: 591,
          },
          text: {
            x: 237,
            y: 588,
            label: "Argelès-Gazost",
          },
        },
        {
          dot: {
            x: 262,
            y: 576,
          },
          text: {
            x: 256,
            y: 573,
            label: "Tarbes",
          },
        },
        {
          dot: {
            x: 330,
            y: 552,
          },
          text: {
            x: 325,
            y: 549,
            label: "Toulouse",
          },
        },
        {
          dot: {
            x: 278,
            y: 557,
          },
          text: {
            x: 271,
            y: 562,
            label: "Mirande",
          },
        },
        {
          dot: {
            x: 276,
            y: 528,
          },
          text: {
            x: 269,
            y: 525,
            label: "Condom",
          },
        },
        {
          dot: {
            x: 313.5,
            y: 522,
          },
          text: {
            x: 295,
            y: 527,
            label: "Castelsarrasin",
          },
        },
        {
          dot: {
            x: 326,
            y: 524,
          },
          text: {
            x: 327,
            y: 529,
            label: "Montauban",
          },
        },
        {
          dot: {
            x: 329,
            y: 492,
          },
          text: {
            x: 324,
            y: 489,
            label: "Cahors",
          },
        },
        {
          dot: {
            x: 328,
            y: 474,
          },
          text: {
            x: 321,
            y: 471,
            label: "Gourdon",
          },
        },
        {
          dot: {
            x: 359,
            y: 481,
          },
          text: {
            x: 354,
            y: 478,
            label: "Figeac",
          },
        },
        {
          dot: {
            x: 371,
            y: 552,
          },
          text: {
            x: 365,
            y: 557,
            label: "Castres",
          },
        },
        {
          dot: {
            x: 367,
            y: 528,
          },
          text: {
            x: 364,
            y: 525,
            label: "Albi",
          },
        },
        {
          dot: {
            x: 368,
            y: 588,
          },
          text: {
            x: 361,
            y: 593,
            label: "Limoux",
          },
        },
      ],
      icon_size: 10,
    };
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  },

  mounted() {
    setTimeout(() => {
      this.place_weather(this.$refs);
    }, 202);
    setTimeout(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onResize);
    }, 205);
  },

  methods: {
    onResize() {
      this.place_weather(this.$refs);
    },
  },

  watch: {
    weather: {
      deep: true,
      handler: function(val, oldVal) {
        this.place_weather(this.$refs);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
path {
  fill: #618a1c;
  stroke-width: 1px;
  stroke: #618a1c;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}

.temperature-shadow {
  text-shadow: 2px 0 0 white;
}

.highlight {
  fill: #93d02d;
  stroke-width: 0.112417px;
  stroke: #74a425;
  pointer-events: all;
}
</style>
