<template>
  <v-row class="justify-center pa-0 " ref="detailsCar" v-resize="onResize" :style="{ fontSize: fontSizeValue }" no-gutters>
    <v-card :class="{ 'black--text': true }" shaped dark color="grey lighten-4">
      <v-card-title class="text-break py-0 pt-md-2 overide-title">
        <span class="mr-3 text-h5 font-weight-medium" v-html="car_data.marque || ''"></span>
        <span class="ml-auto text-h5 font-weight-medium" v-if="modified_price">{{ `${modified_price || ""}€` }}</span>
      </v-card-title>
      <v-card-subtitle class="text-break grey--text text--darken-2 d-flex py-0 py-md-2 overide-subtitle">
        <v-row class="d-flex" no-gutters>
          <v-col cols="12" class="grey--text text--darken-2 mb-2" tag="span">
            {{ `${car_data.famille || ""} ${car_data.version || ""}` }}
          </v-col>

          <v-row no-gutters v-if="loyer">
            <v-col class="text-right black--text font-weight-medium flex-shrink-0 flex-grow-1">
              À partir de {{ formatted_loyer(loyer_duration, loyer_taeg) }}€ / mois</v-col
            >
            <v-col class="red--text font-weight-bold flex-shrink-1 flex-grow-0">*</v-col>
          </v-row>
        </v-row>
      </v-card-subtitle>
      <v-card-text class="overide-text">
        <div
          class="grey--text text--darken-2 text-break"
          v-html="
            `- Boîte ${(car_data.boite && car_data.boite.toLowerCase()) == 'rob simple embray' ? 'automatique' : 'manuelle'}`
          "
        ></div>
        <div
          v-if="car_data.peinture"
          class="grey--text text--darken-2 text-break"
          v-html="
            `- Intérieur <span style='text-transform: lowercase;'>${car_data.peinture.int ||
              ''}</span>, extérieur <span style='text-transform: lowercase;'>${car_data.peinture.ext || ''}</span>`
          "
        ></div>

        <div
          v-for="(equipment, index) in modified_equipment"
          :key="index"
          class="grey--text text--darken-2 text-break"
          v-html="`- ${equipment}`"
        ></div>
        <div class="grey--text text--darken-2 text-break mt-2" v-html="`Pour plus d'information, contacter un commercial`"></div>

        <div class="black--text text--lighten-2 text-body-2 text-break mt-4" v-if="car_data.code && car_data.reference">
          {{ `Référence : ${car_data.code || ""} ${car_data.reference || ""}` }}
        </div>
        <div class="black--text d-flex text-break mt-3">
          <span class="mr-3" v-html="car_data.annee"></span>
          <span class="ml-auto" v-if="modified_km">
            {{ `${modified_km || ""} km` }}
          </span>
        </div>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: "lgDetailsCar",

  props: {
    loyer: {
      type: Boolean,
      default: false,
    },
    loyer_duration: {
      type: Number,
      default: 0,
    },
    loyer_taeg: {
      type: Number,
      default: 0,
    },
    car_data: {
      type: Object,
      default: () => {
        ({});
      },
    },
    orientation: {
      type: String,
      default: "landscape",
    },
  },

  data() {
    return {
      fontSizeValue: "16px",
    };
  },

  methods: {
    onResize() {
      this.fontSizeValue = (15 / 100) * ((this.$refs.detailsCar.offsetWidth / 320) * 100) + "px";
    },

    formatted_loyer(loyer_duration, loyer_taeg) {
      /**
       * http://www.calculconversion.com/calcul-pret-automobile.html
       */
      let upper_part = (this.car_data.price * loyer_taeg) / 12;
      let bottom_part = 1 - Math.pow(1 + loyer_taeg / 12, loyer_duration * -1);

      let mensualité = upper_part / bottom_part;

      return Math.round(mensualité) || "xxx";
    },
  },

  computed: {
    modified_price() {
      this.car_data.price;

      return this.car_data?.price?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    modified_km() {
      this.car_data.km;

      return this.car_data?.km?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    modified_equipment() {
      this.car_data.equipement;

      if (!this.car_data.equipement) return [];
      let equipement_array = this.car_data.equipement.split("|");

      for (let i = equipement_array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i);
        const temp = equipement_array[i];
        equipement_array[i] = equipement_array[j];
        equipement_array[j] = temp;
      }

      return equipement_array.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.overide-text {
  font-size: 0.875em;
  line-height: 1.375em;
}

.overide-subtitle {
  line-height: 1.375em;
  font-size: 0.875em;
}

.overide-title {
  line-height: 1.375em;
  font-size: 1.1em !important;
}

.text-h5 {
  font-size: 1.5em !important;
  line-height: 2em;
}

.text-body-2 {
  font-size: 0.875em !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0178571429em !important;
}
</style>
