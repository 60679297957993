<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="180 115 200 200"
      style="transform: scale(1.2);"
    >
      <path
        d="m 308.19408,220.89159 2.11,-0.54 3.42,2.67 2.73,-1.48 2.22,2.54 2.98,-0.23 2.23,1.9 -0.08,1.04 1.73,0.86 0.48,1.57 2.34,-0.62 0.3,-2.7 3.13,2.66 2.8,-1.58 0,0 2.34,5.07 -2.62,6.09 0.49,1.33 4.24,3.35 -0.14,2.03 2.17,6.53 -0.53,3.46 2.49,2.46 -0.58,2.97 0.65,1.16 -1.25,2.92 0.82,3.38 -1.57,3.44 0,0 -2.81,-0.57 -1.78,0.69 -2.95,2.86 -0.14,1.2 -1.98,-0.55 -1.56,1.08 -1.14,-1.84 -0.74,0.13 -0.59,1.61 -3.21,1.71 0.15,1.91 -0.95,-0.77 -1.45,0.75 1.07,1.19 -1.22,1.84 1.55,0.86 0.45,2.48 -3.24,2.06 -2.06,-0.81 -5.24,0.96 -1.56,2.77 -1.03,0.01 -0.74,3.03 0,0 -4.66,-0.28 0,0 -0.66,-2.03 2.18,-1.54 -1.93,-4.35 1.28,-5.24 -2.09,-1.32 0.65,-1.65 -2.8,-1.23 -0.81,-2.31 1.16,-0.22 0.9,-1.54 -3.07,-3.6 1.37,0.32 0.84,-1.24 -0.52,-0.81 1.81,-0.74 1.15,-1.87 -1.85,-0.1 -1.62,-1.23 1.5,-3.83 -3.29,-3.11 1.09,-1.48 -0.24,-2.14 -1.86,0.79 -0.38,-1.96 -2.96,1.49 -1.95,-0.01 -4.19,-1.66 2.81,-2.99 -0.1,-2.52 0,0 1.39,0.88 1.13,-0.47 0.9,-1.63 -1.17,-0.92 1.94,-2.05 3.44,1.68 2.12,-1.36 2.2,0.31 1.22,-1.2 -1.6,-2.06 -0.24,-2.26 -0.71,0.18 0.76,-3.37 0.96,0.85 2.1,-1.89 0.68,1.98 1.05,-0.11 0.71,-5.16 -2.09,0.29 0.75,-2.17 -0.69,-1.4 -2.91,-0.34 -0.36,-1.95 z"
        title="Cher"
        id="FR-18"
      />
      <path
        d="m 277.59408,138.79159 2.57,3.15 -0.72,1.27 1.61,0.65 -0.32,0.96 0.89,0.33 -0.79,0.54 0.09,4.39 1.88,0.9 -2.07,2.76 1.46,0.88 -0.33,1.68 1.58,0.76 0.98,2.24 2.03,-0.02 -0.25,2.34 2,0.17 1.24,1.3 -0.47,2.52 1.07,2.91 1.42,-0.03 0.32,1.28 1.09,0.39 1.55,0.02 0.62,-1.12 0,0 0.84,1.04 -0.6,2.2 2.06,0.28 0.09,5.2 -0.91,0.47 1.5,1.46 0,0 -1.33,2.53 0.72,2.63 -0.33,0.75 -0.26,-0.66 -0.66,0.44 0.95,1.34 -2.02,-0.03 -0.82,0.86 0.65,0.85 -1.51,3 -2.24,1.16 -1.11,-0.68 -0.19,0.82 -2.19,0.83 -2.33,-0.87 -0.76,0.91 -0.45,-0.85 -1.44,0.41 -0.17,1.72 -0.87,-0.09 -0.2,0.82 -2.03,-0.83 0.11,0.88 -1.51,0.17 0.38,2.78 0,0 -2.95,-1.53 -0.54,1.89 -2.07,-0.32 -0.71,1.68 -4.34,-0.45 -0.78,-1.07 -2.08,0.68 -1.29,-3.73 -1.73,-0.42 -0.45,-2.9 -1.58,0.44 -0.44,-0.77 -2.96,-0.23 2.24,-1.71 -0.26,-0.87 -1.6,0.13 -2.18,1.97 -0.31,-0.89 -4.21,0.57 0,0 0.41,-1.83 2.69,-0.34 -4.9,-3.44 0,0 1.35,-1.2 -1.75,-2.94 0.75,-1.75 -2.11,-0.66 0.61,-1.42 0.73,-1.17 5.25,-1.97 2.57,-4.22 -1.49,-0.99 -0.17,-1.27 0.81,-0.36 -0.55,-1.11 1.01,-1.47 -1.75,-0.83 0.63,-0.79 -0.56,-0.67 -3.09,-1.39 -0.18,-1.7 -1.17,-0.15 0.54,-1.36 -0.68,-2.27 0,0 2,-1.11 0.53,-1.74 1.9,0.4 2.66,-1.03 0,0 1.18,0 0,0 2.02,-2.06 2.14,0.76 0.28,-2.71 4.19,1.97 0.88,-0.76 0.56,0.8 2.99,-0.15 1.79,-1.34 -0.43,-3.35 4.08,-2.36 0.46,-1.57 -0.97,-1.68 z"
        title="Eure-et-Loir"
        id="FR-28"
      />
      <path
        d="m 270.35408,247.49159 5.07,-3.27 2.25,0.19 1.18,1.01 -0.29,-2.18 2.82,-0.16 -0.03,-1.15 0.81,-0.11 2.31,1.24 2.03,-1.16 2.52,2.71 2.66,0.76 0,0 0.1,2.52 -2.81,2.99 4.19,1.66 1.95,0.01 2.96,-1.49 0.38,1.96 1.86,-0.79 0.24,2.14 -1.09,1.48 3.29,3.11 -1.5,3.83 1.62,1.23 1.85,0.1 -1.15,1.87 -1.81,0.74 0.52,0.81 -0.84,1.24 -1.37,-0.32 3.07,3.6 -0.9,1.54 -1.16,0.22 0.81,2.31 2.8,1.23 -0.65,1.65 2.09,1.32 -1.28,5.24 1.93,4.35 -2.18,1.54 0.66,2.03 0,0 -3.31,0.87 -4.55,-1.99 -1.95,0.71 -1.95,-0.63 -1.9,0.96 -2.33,-1.85 -1.47,0.38 0.52,1.49 -1.29,2.11 -1.8,-1.69 -2.37,1.82 -0.79,-2 -2.68,1.73 -0.79,-2.05 -4.59,4.84 0,0 -2.55,-3.22 -1.69,0.29 -0.4,1.41 -1.86,-0.41 -1.67,0.75 -0.39,-1.46 -0.78,0.81 -0.51,-0.5 0,0 1.4,-2.93 -1.2,0.33 0.2,-0.76 -1.54,-0.46 -0.66,-1.37 0.94,0.09 -0.95,-1.67 0.49,-0.48 -2.22,-1.86 -3.04,0.03 -0.22,-1.85 -2.11,-0.26 -2.2,-1.8 -0.75,-1.7 1.44,-4.11 -0.59,-1.77 -1.87,-1.28 0,0 2.43,-0.55 1.32,1.19 1.04,-1.61 1.16,0.34 -1.1,-2.76 1.04,-0.64 1.09,-7.55 1.18,-0.97 -0.39,-2.45 1.96,-1.92 2.74,-0.78 2.63,1.62 0.92,-0.53 1.56,-3.06 0.8,0 0.06,-1.78 2.15,-0.94 z"
        title="Indre"
        id="FR-36"
      />
      <path
        d="m 240.85408,216.28159 0.95,-0.91 0.36,1.57 1.06,-0.95 1.53,1.12 1.19,-0.86 3.78,1.19 1.54,-0.86 -0.98,2.81 0.77,2.81 1.55,-0.32 0.84,-1.72 2.97,0.75 0.24,2.2 1.5,-1.48 1.24,2.51 0.59,-0.33 -1.35,2.49 1.03,0.53 0.71,3.72 1.07,-0.21 0.88,1.04 -0.84,1.29 -1.21,-0.13 1.59,2.37 -0.79,1.34 0.76,1 -1.04,1.5 0.32,1.39 2.42,1.98 0.52,-0.91 2.83,0.07 3.47,6.21 0,0 1.52,4.13 -2.15,0.94 -0.06,1.78 -0.8,0 -1.56,3.06 -0.92,0.53 -2.63,-1.62 -2.74,0.78 -1.96,1.92 0.39,2.45 -1.18,0.97 -1.09,7.55 -1.04,0.64 1.1,2.76 -1.16,-0.34 -1.04,1.61 -1.32,-1.19 -2.43,0.55 0,0 -2.18,-2.48 -0.78,-3.66 -1.82,-0.6 -1.99,-2.64 -0.53,-4.28 -2.39,-0.74 -0.68,-1.31 -2.07,0.09 1.27,2.97 -3.85,-0.1 -2.78,1.78 -3.04,-1.21 -1.73,1.14 -1.34,-3.64 0.89,-0.48 -0.4,-3.21 -1.44,0.52 -0.05,-1.51 -0.91,-0.25 -1.33,1.07 -1.44,-0.31 0.9,-1.98 -0.76,-1.44 -0.82,0.86 -1.13,-0.38 0.22,-1.08 -2.49,0.36 -0.04,-1.63 -1.05,-1.1 0,0 0.55,-1.53 -0.6,-0.39 1.08,-1.34 -0.08,-3.9 2.88,-3.98 -0.3,-0.9 1.78,-1.23 -1.22,-1.08 2.85,-6.17 0.19,-1.66 -1.33,-0.73 1.62,-3.37 0,0 -0.19,-0.91 0,0 1.29,-0.13 4.95,2.62 1.02,-0.62 -1.6,-2.52 0.78,-1.47 1.7,1.67 1.04,-0.17 0.61,-1.58 2.2,-0.71 0,0 0.87,-0.03 0,0 2.41,-0.92 z"
        title="Indre-et-Loire"
        id="FR-37"
      />
      <path
        d="m 250.17408,191.07159 4.21,-0.57 0.31,0.89 2.18,-1.97 1.6,-0.13 0.26,0.87 -2.24,1.71 2.96,0.23 0.44,0.77 1.58,-0.44 0.45,2.9 1.73,0.42 1.29,3.73 2.08,-0.68 0.78,1.07 4.34,0.45 0.71,-1.68 2.07,0.32 0.54,-1.89 2.95,1.53 0,0 1.82,-0.47 -0.41,2.28 -1.34,1.3 2.46,1.81 0.38,1.46 -2.71,3.6 1.83,1.44 -0.93,1.7 2.2,1.7 -0.93,0.99 2.17,-2.01 3.45,1.54 1.11,4.48 2.22,1.47 2.21,-1.33 0.48,-2.08 0.3,1.06 2.94,-0.31 0.18,1.3 0.96,0.11 1.84,-1.32 4.3,0.57 3.77,-0.56 1.57,2.29 -0.09,1.27 0,0 -4.93,2.31 0.36,1.95 2.91,0.34 0.69,1.4 -0.75,2.17 2.09,-0.29 -0.71,5.16 -1.05,0.11 -0.68,-1.98 -2.1,1.89 -0.96,-0.85 -0.76,3.37 0.71,-0.18 0.24,2.26 1.6,2.06 -1.22,1.2 -2.2,-0.31 -2.12,1.36 -3.44,-1.68 -1.94,2.05 1.17,0.92 -0.9,1.63 -1.13,0.47 -1.39,-0.88 0,0 -2.66,-0.76 -2.52,-2.71 -2.03,1.16 -2.31,-1.24 -0.81,0.11 0.03,1.15 -2.82,0.16 0.29,2.18 -1.18,-1.01 -2.25,-0.19 -5.07,3.27 0,0 -3.47,-6.21 -2.83,-0.07 -0.52,0.91 -2.42,-1.98 -0.32,-1.39 1.04,-1.5 -0.76,-1 0.79,-1.34 -1.59,-2.37 1.21,0.13 0.84,-1.29 -0.88,-1.04 -1.07,0.21 -0.71,-3.72 -1.03,-0.53 1.35,-2.49 -0.59,0.33 -1.24,-2.51 -1.5,1.48 -0.24,-2.2 -2.97,-0.75 -0.84,1.72 -1.55,0.32 -0.77,-2.81 0.98,-2.81 -1.54,0.86 -3.78,-1.19 -1.19,0.86 -1.53,-1.12 -1.06,0.95 -0.36,-1.57 -0.95,0.91 0,0 -0.61,0.66 -0.83,-1.77 1.86,-2.34 3.13,-0.95 -0.38,-1 3.43,-3.59 -0.61,-3.64 1.21,-0.69 0.89,1.45 0.17,-2.7 1.39,-0.49 -1.26,-2.84 0.98,-2.7 -1.44,-0.14 -0.57,-1.01 -0.01,-1.38 2.05,-0.15 0.09,-1.37 -1.35,0.07 z"
        title="Loir-et-Cher"
        id="FR-41"
      />
      <path
        d="m 298.02408,179.71159 1.95,0.13 2.65,-1.4 2.44,0.52 -0.49,-1.09 1.23,0.29 0.9,-2.04 1.77,0.76 -0.02,2.08 0.9,-1.04 1.37,0.22 0,0 0.46,-1.06 0,0 0.76,-0.25 1.24,1.55 1.88,-0.49 0,0 0.81,3.48 3.41,1.34 0.71,2.36 -0.68,2.7 -0.97,-0.51 -1.64,2.53 3.23,-0.02 1.57,-1.04 6.07,1.09 2.02,-1.36 -0.74,-1.33 1.1,0.83 1.5,-0.95 0.09,2.3 0.76,0.11 1.94,-1.63 2.94,-0.43 0,0 3.84,1.85 -0.56,1.07 0.82,-0.06 0.58,2.71 3.11,2.69 -1.05,0.64 1.07,0.79 0.12,2.1 -1.35,1.53 0,0 -0.49,0.34 0,0 -1.39,1.95 -1.7,0.3 0.15,2.01 -0.71,0.39 1.53,1.08 -0.81,1 0.51,2.88 -7.21,1.82 -0.16,2.49 1.27,-0.21 0.02,1.03 1.96,1.34 -0.3,0.82 1.43,1.44 -0.87,1.14 0.17,1.7 1.66,1.81 0,0 -0.74,0.76 -1.82,-0.54 -1.74,1.28 -0.67,-0.46 -0.39,0.81 1.12,1.12 0,0 -2.8,1.58 -3.13,-2.66 -0.3,2.7 -2.34,0.62 -0.48,-1.57 -1.73,-0.86 0.08,-1.04 -2.23,-1.9 -2.98,0.23 -2.22,-2.54 -2.73,1.48 -3.42,-2.67 -2.11,0.54 0,0 0.09,-1.27 -1.57,-2.29 -3.77,0.56 -4.3,-0.57 -1.84,1.32 -0.96,-0.11 -0.18,-1.3 -2.94,0.31 -0.3,-1.06 -0.48,2.08 -2.21,1.33 -2.22,-1.47 -1.11,-4.48 -3.45,-1.54 -2.17,2.01 0.93,-0.99 -2.2,-1.7 0.93,-1.7 -1.83,-1.44 2.71,-3.6 -0.38,-1.46 -2.46,-1.81 1.34,-1.3 0.41,-2.28 -1.82,0.47 0,0 -0.38,-2.78 1.51,-0.17 -0.11,-0.88 2.03,0.83 0.2,-0.82 0.87,0.09 0.17,-1.72 1.44,-0.41 0.45,0.85 0.76,-0.91 2.33,0.87 2.19,-0.83 0.19,-0.82 1.11,0.68 2.24,-1.16 1.51,-3 -0.65,-0.85 0.82,-0.86 2.02,0.03 -0.95,-1.34 0.66,-0.44 0.26,0.66 0.33,-0.75 -0.72,-2.63 z"
        title="Loiret"
        id="FR-45"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "CentreValdeLoire",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>