<template>
  <v-sheet
    :class="{ 'position-relative': true, 'no-cursor': noCursor, 'no-overflow': noOverflow }"
    color="black"
    height="100%"
    width="100%"
    tile
  >
    <slot></slot>
  </v-sheet>
</template>

<script>
export default {
  props: {
    noCursor: {
      type: Boolean,
      default: true,
    },
    noOverflow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.position-relative {
  position: relative;
}
.no-cursor:hover {
  cursor: none;
}

.no-overflow {
  overflow-y: hidden;
  overflow-x: hidden;
}
</style>
