<template>
  <v-container fluid fill-height class="pa-0">
    <!-- VIDEO PLAYER -->
    <RepetitiveCheck
      :start="type == 'video'"
      :cb="getPing"
      :check="check_vimeo_error"
      v-on:max_failure="onMaxFailure"
      :nbr_max_failure="new Number(20)"
      :millisecond_timeout="new Number(1000 * 5)"
    />
    <v-container v-if="Boolean(type == 'video' && url)" fluid fill-height class="pa-0">
      <my-vimeo v-on:video-end="onPubliciteEnd" :video_url="url" :hide_cursor="hide_cursor" />
    </v-container>
    <!-- END OF VIDEO PLAYER -->

    <!-- IMAGE PUBLICITE -->
    <v-container v-if="type == 'image' && url" fluid fill-height class="pa-0">
      <v-row fill-height align-content="center" justify="center" no-gutters>
        <v-img :src="url" max-height="100vh" @error="onImageError" @load="onImageLoad" contain></v-img>
      </v-row>
    </v-container>
    <repetitive-signal
      key="duration"
      :ready="start_repetitive"
      :milliseconds="duration_image_publicite"
      v-on:repetitive-signal-over="onImagePubliciteEnd"
    ></repetitive-signal>
    <!-- IMAGE PUBLICITE -->
  </v-container>
</template>

<script>
import RepetitiveCheck from "@/components/RepetitiveCheck/RepetitiveCheck";
import RepetitiveSignal from "@/components/RepetitiveSignal/RepetitiveSignal";

import MyVimeo from "@/components/Vimeo/MyVimeo";

export default {
  name: "ScenePublicite",

  components: {
    MyVimeo,
    RepetitiveCheck,
    RepetitiveSignal,
  },

  props: {
    type: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
    duration_image_publicite: {
      type: Number,
      default: null,
    },
    hide_cursor: { type: Boolean, default: true },
  },

  data() {
    return {
      ping: false,

      start_repetitive: false,
    };
  },

  beforeDestroy() {
    window.removeEventListener("message", this.fn_postMessage, false);
  },

  methods: {
    async getPing() {
      this.ping = null;
      try {
        if (
          document.getElementsByTagName("iframe").length >= 1 &&
          document.getElementsByTagName("iframe")[0]?.contentWindow?.postMessage
        ) {
          window.addEventListener("message", this.fn_postMessage, false);

          document.getElementsByTagName("iframe")[0].contentWindow.postMessage({ method: "ping" }, "https://player.vimeo.com");

          console.log("waiting ...", new Date().getMilliseconds());
          await this.$nextTick();
          await new Promise((resolved, reject) => setTimeout(resolved, 200));

          console.log("end waiting ...", new Date().getMilliseconds());

          window.removeEventListener("message", this.fn_postMessage);

          return this.ping;
        } else {
          return null;
        }
      } catch (error) {
        this.onPubliciteEnd();
        console.error(error);
        return null;
      }
    },

    check_vimeo_error(ref_previous_progress, nbr_max_failure) {
      if (ref_previous_progress.length < nbr_max_failure) return false;
      let sliced = ref_previous_progress.slice(ref_previous_progress.length - nbr_max_failure, ref_previous_progress.length);
      return sliced.every((el) => el === false || el == null || el == undefined);
    },

    fn_postMessage(message) {
      console.log("fn_postMessage", message?.origin, message?.data);
      if (message?.origin == "https://player.vimeo.com" && message?.data?.method == "ping") this.ping = true;
    },

    onImageLoad() {
      if (Boolean(this.type == "image" && this.url)) this.start_repetitive = true;
    },

    onImageError() {
      this.$emit("on-image-error", { url: this.url });
    },

    onImagePubliciteEnd() {
      this.onPubliciteEnd();
    },

    onPubliciteEnd() {
      this.start_repetitive = false;
      this.$emit("on-publicite-end", { url: this.url });
    },

    onMaxFailure() {
      this.start_repetitive = false;
      this.$emit("on-publicite-end", { url: this.url });
    },
  },
};
</script>

<style lang="scss" scoped></style>
