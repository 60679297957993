<template>
  <v-row justify="center" align="center" class="fill-height pa-0 ma-0" no-gutters>
    <transition :name="transition_name" mode="out-in">
      <component v-on:error="onError" :is="componentId"></component>
    </transition>
    <repetitive-signal
      key="interval"
      :ready="ready_interval"
      :milliseconds="interval"
      v-on:repetitive-signal-over="onIntervalEnd"
    ></repetitive-signal>
    <repetitive-signal
      key="duration"
      :ready="ready_duration"
      :milliseconds="duration"
      v-on:repetitive-signal-over="onDurationEnd"
    ></repetitive-signal>
  </v-row>
</template>

<script>
import WeatherLocal from "@/components/Weather/Local/WeatherLocal";
import WeatherFrance from "@/components/Weather/France/WeatherFrance";
import WeatherRegion from "@/components/Weather/Region/WeatherRegion";

import RepetitiveSignal from "@/components/RepetitiveSignal/RepetitiveSignal";

import default_options_values from "@/utils/defaultOptionsValues";

import { debounce } from "lodash";

export default {
  name: "Weather",

  components: { RepetitiveSignal, WeatherFrance, WeatherRegion, WeatherLocal },

  props: {
    ref_weather: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    interval: {
      type: Number,
      default: null,
    },
    intervalOnMounted: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: default_options_values.weather_duration * 1000,
    },
    transition_name: {
      type: String,
      default: "fade",
    },
  },

  data() {
    return {
      ready_interval: false,
      ready_duration: false,

      my_weather: [true, true, true],
      componentId: "",
    };
  },

  errorCaptured(err, vm, info) {
    console.error("weather error captured", err, vm, info);
    this.emitEnd();
  },

  beforeDestroy() {
    this.ready_duration = false;
    this.ready_interval = false;
  },

  methods: {
    emitReady() {
      this.$emit("weather-ready", true);
    },

    emitEnd() {
      /**
       * detroy component
       */
      this.componentId = "";

      /**
       * emit wether end
       */
      this.$emit("weather-end", true);
    },

    startShowing() {
      if (this.my_weather[0] == false) {
        this.componentId = "WeatherFrance";
        this.my_weather[0] = true;
      } else if (this.my_weather[1] == false) {
        this.componentId = "WeatherRegion";
        this.my_weather[1] = true;
      } else if (this.my_weather[2] == false) {
        this.componentId = "WeatherLocal";
        this.my_weather[2] = true;
      }
    },

    onIntervalEnd() {
      this.emitReady();
    },

    async onDurationEnd() {
      if (this.my_weather.filter((el) => el === false).length <= 0) {
        // no more weather to display - return to normal behavior
        this.emitEnd();
        this.ready_duration = false;
        this.ready_interval = true;
      } else {
        this.startShowing();

        // Reset ready_duration
        this.ready_duration = false;
        await this.$nextTick();
        this.ready_duration = true;
        // Reset ready_duration
      }
    },

    /**
     * Using debounce to avoid spaming onShowChange while manipulating options
     */
    debouncedOnShowChange: debounce(async function() {
      await this.onShowChange();
    }, 1000),

    async onShowChange() {
      this.my_weather = JSON.parse(JSON.stringify(this.ref_weather));
      if (this.show == true && this.my_weather.filter((el) => el === false).length > 0) {
        await this.startShowing();
        this.ready_duration = true;
        this.ready_interval = false;
      } else if (!this.show && this.intervalOnMounted) {
        this.ready_duration = false;
        this.ready_interval = true;
      } else {
        this.ready_duration = false;
        this.ready_interval = false;
      }
    },

    onError(err) {
      console.error(err);
      this.$emit("weather-end", true);
    },
  },

  watch: {
    show: {
      handler: async function(val, oldVal) {
        this.debouncedOnShowChange();
      },
      immediate: true,
    },

    interval: {
      handler: function(val) {
        if (val) {
          this.debouncedOnShowChange();
        }
      },
    },

    duration: {
      handler: function(val) {
        if (val) {
          this.debouncedOnShowChange();
        }
      },
    },

    ref_weather: {
      handler(val, oldVal) {
        if (val) {
          this.debouncedOnShowChange();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
