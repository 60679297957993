<template>
  <v-container ref="container-svg" id="container-svg" class="no-overflow pa-0" style="position: relative">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      :viewBox="`${svg_viewBox.x} ${svg_viewBox.y} ${svg_viewBox.width} ${svg_viewBox.height}`"
    >
      <path
        v-for="path in weather_paths"
        :key="path.id"
        :d="path.d"
        :title="path.title"
        :ref="path.title"
        :id="path.id"
        transform="scale(1.2)"
        :class="{ highlight: weather[path.title] }"
      />
      <g v-for="city in extra_cities" :key="city.label">
        <circle :cx="city.dot.x" :cy="city.dot.y" r="1" stroke="black" stroke-width="0.2" fill="#f8703d" />
        <text
          :x="city.text.x"
          :y="city.text.y"
          font-size="0.24rem"
          font-weight="bold"
          fill="#f0f0f0"
          :id="`text_${city.text.label}`"
        >
          {{ city.text.label }}
        </text>
      </g>
    </svg>
    <div
      v-for="depart in weather_icon_departement"
      :key="depart.id"
      :style="{ position: 'absolute', top: depart.y + 'px', left: depart.x + 'px' }"
    >
      <span class="font-weight-medium black--text temperature-shadow">{{ depart.temperature }}</span>
      <v-img
        :src="depart.src"
        :min-width="depart.width"
        :min-height="depart.height"
        :max-width="depart.width"
        :max-height="depart.height"
        class="w-heartbeat"
        contain
        alt
      />
    </div>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";
import weather_paths from "@/utils/weather_paths";

export default {
  name: "PaysdelaLoire",

  mixins: [mixins_weather],

  props: {
    ...weather_props,
  },

  data() {
    return {
      svg_viewBox: {
        x: 120,
        y: 183,
        width: 200,
        height: 200,
      },

      weather_icon_departement: [],
      weather_paths: weather_paths,

      extra_cities: [
        {
          dot: {
            x: 183,
            y: 298,
          },
          text: {
            x: 177,
            y: 304,
            label: "Nantes",
          },
        },
        {
          dot: {
            x: 146,
            y: 291.4,
          },
          text: {
            x: 136,
            y: 297.4,
            label: "St-Nazaire",
          },
        },
        {
          dot: {
            x: 167.5,
            y: 341.5,
          },
          text: {
            x: 150,
            y: 347.5,
            label: "Les Sables-d'Olonne",
          },
        },
        {
          dot: {
            x: 215.5,
            y: 345.5,
          },
          text: {
            x: 199.5,
            y: 351.5,
            label: "Fontenay-le-Comte",
          },
        },
        {
          dot: {
            x: 215,
            y: 307,
          },
          text: {
            x: 209.4,
            y: 313,
            label: "Cholet",
          },
        },
        {
          dot: {
            x: 249,
            y: 288,
          },
          text: {
            x: 242.5,
            y: 294,
            label: "Saumur",
          },
        },
        {
          dot: {
            x: 213,
            y: 258,
          },
          text: {
            x: 208,
            y: 264,
            label: "Segré",
          },
        },
        {
          dot: {
            x: 187,
            y: 256,
          },
          text: {
            x: 174,
            y: 262,
            label: "Châteaubriant",
          },
        },
        {
          dot: {
            x: 255,
            y: 258,
          },
          text: {
            x: 247,
            y: 264,
            label: "La Flèche",
          },
        },
        {
          dot: {
            x: 222,
            y: 250,
          },
          text: {
            x: 208,
            y: 246,
            label: "Château-Gontier",
          },
        },
        {
          dot: {
            x: 228,
            y: 213,
          },
          text: {
            x: 220.5,
            y: 209,
            label: "Mayette",
          },
        },
        {
          dot: {
            x: 276.5,
            y: 211,
          },
          text: {
            x: 269,
            y: 217,
            label: "Mamers",
          },
        },
      ],
      icon_size: 10,
    };
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  },

  mounted() {
    setTimeout(() => {
      this.place_weather(this.$refs);
    }, 202);
    setTimeout(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onResize);
    }, 205);
  },

  methods: {
    onResize() {
      this.place_weather(this.$refs);
    },
  },

  watch: {
    weather: {
      deep: true,
      handler: function(val, oldVal) {
        this.place_weather(this.$refs);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
path {
  fill: #618a1c;
  stroke-width: 1px;
  stroke: #618a1c;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}

.temperature-shadow {
  text-shadow: 2px 0 0 white;
}

.highlight {
  fill: #93d02d;
  stroke-width: 0.112417px;
  stroke: #74a425;
  pointer-events: all;
}
</style>
