<template>
  <div
    :class="{
      'font-weight-medium full-width bottom black': true,
      'small-letter-spacing': true,
      'text-xs': $vuetify.breakpoint.smAndDown,
      'text-md': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <span>
      <span class="red--text font-weight-bold">*</span>Montant calculé, sans apport initial, sur la base d'un remboursement
      mensuel d'une durée de {{ loyer_duration }} mois avec un taux annuel effectif global de
      {{ parseFloat((loyer_taeg * 100).toFixed(10)) }}%. Le montant indiqué est à titre d'exemple et peut ne pas correspondre à la
      réalité. Pour plus d'information, contacter un commercial.
    </span>
  </div>
</template>

<script>
export default {
  props: {
    loyer_duration: {
      type: Number,
      default: 0,
    },
    loyer_taeg: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
  text-align: center;
}

.text-xs {
  font-size: 0.45rem !important;
}

.text-md {
  font-size: 0.55rem !important;
}

.bottom {
  position: absolute;
  max-width: 100%;
  bottom: 0px;
  z-index: 1;
}

.small-letter-spacing {
  line-height: 0.55rem !important;
}
</style>
