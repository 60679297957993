
const logo = {
    methods: {
        async getSpecificConcessionLogo() {
            let logo = { url: null };
            try {
                logo = (await this.$axios.get("/concession/link/logo")).data;
            } catch (error) {
                console.error(error);
                this.displayErrorSnack(`Une erreur s'est produite lors de la récupération d'un logo`);
            }
            return { ...logo, type: "concession" };
        },

        async getSpecificGroupeLogo(groupe_name) {
            let logo = { url: null };
            try {
                logo = (
                    await this.$axios.get("/groupe/link/logo", {
                        params: {
                            groupe_name,
                        },
                    })
                ).data;
            } catch (error) {
                console.error(error);
                this.displayErrorSnack(`Une erreur s'est produite lors de la récupération d'un logo`);
            }
            return { ...logo, type: "groupe" };
        },

        async getSpecificFranchiseLogo(franchise_name) {
            let logo = { url: null };
            try {
                logo = (
                    await this.$axios.get("/franchise/link/logo", {
                        params: {
                            franchise_name,
                        },
                    })
                ).data;
            } catch (error) {
                console.error(error);
                this.displayErrorSnack(`Une erreur s'est produite lors de la récupération d'un logo`);
            }
            return { ...logo, type: "franchise" };
        },

        async onImgError(logo) {
            try {
                console.log("error logo", logo);
                if (logo.type == "concession") {
                    return await this.getSpecificConcessionLogo();
                } else if (logo.type == "groupe") {
                    return await this.getSpecificGroupeLogo(logo.company_name);
                } else if (logo.type == "franchise") {
                    return await this.getSpecificFranchiseLogo(logo.company_name);
                }
            } catch (error) {
                console.error(error);
                this.displayErrorSnack(`Une erreur s'est produite lors de la récupération d'un logo`);
            }
            return null;
            // logo.type // logo.company_name
        },
    },
}

export default logo;