<template>
  <v-container ref="container-svg" id="container-svg" class="no-overflow pa-0" style="position: relative">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      :viewBox="`${svg_viewBox.x} ${svg_viewBox.y} ${svg_viewBox.width} ${svg_viewBox.height}`"
    >
      <path
        v-for="path in weather_paths"
        :key="path.id"
        :d="path.d"
        :title="path.title"
        :ref="path.title"
        :id="path.id"
        transform="scale(1.2)"
        :class="{ highlight: weather[path.title] }"
      />
      <g v-for="city in extra_cities" :key="city.label">
        <circle :cx="city.dot.x" :cy="city.dot.y" r="1" stroke="black" stroke-width="0.2" fill="#f8703d" />
        <text
          :x="city.text.x"
          :y="city.text.y"
          font-size="0.24rem"
          font-weight="bold"
          fill="#f0f0f0"
          :id="`text_${city.text.label}`"
        >
          {{ city.text.label }}
        </text>
      </g>
    </svg>
    <div
      v-for="depart in weather_icon_departement"
      :key="depart.id"
      :style="{ position: 'absolute', top: depart.y + 'px', left: depart.x + 'px' }"
    >
      <span class="font-weight-medium black--text temperature-shadow">{{ depart.temperature }}</span>
      <v-img
        :src="depart.src"
        :min-width="depart.width"
        :min-height="depart.height"
        :max-width="depart.width"
        :max-height="depart.height"
        class="w-heartbeat"
        contain
        alt
      />
    </div>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";
import weather_paths from "@/utils/weather_paths";

export default {
  name: "HautsDeFrance",

  mixins: [mixins_weather],

  props: {
    ...weather_props,
  },

  data() {
    return {
      svg_viewBox: {
        x: 240,
        y: 0,
        width: 300,
        height: 200,
      },

      weather_icon_departement: [],
      weather_paths: weather_paths,

      extra_cities: [
        {
          dot: {
            x: 353,
            y: 10,
          },
          text: {
            x: 348,
            y: 16,
            label: "Calais",
          },
        },
        {
          dot: {
            x: 351,
            y: 80,
          },
          text: {
            x: 343,
            y: 85,
            label: "Abbeville",
          },
        },
        {
          dot: {
            x: 373,
            y: 90,
          },
          text: {
            x: 363,
            y: 87,
            label: "Amiens",
          },
        },
        {
          dot: {
            x: 421,
            y: 97,
          },
          text: {
            x: 412,
            y: 104,
            label: "St-Quentin",
          },
        },
        {
          dot: {
            x: 440,
            y: 115,
          },
          text: {
            x: 436,
            y: 111,
            label: "Laon",
          },
        },
        {
          dot: {
            x: 428,
            y: 158,
          },
          text: {
            x: 416,
            y: 164,
            label: "Château-Thierry",
          },
        },
        {
          dot: {
            x: 364,
            y: 130,
          },
          text: {
            x: 352,
            y: 136,
            label: "Beauvais",
          },
        },
        {
          dot: {
            x: 399,
            y: 130,
          },
          text: {
            x: 390,
            y: 136,
            label: "Compiègne",
          },
        },
        {
          dot: {
            x: 378,
            y: 4,
          },
          text: {
            x: 369,
            y: 9,
            label: "Dunkerque",
          },
        },
        {
          dot: {
            x: 411,
            y: 36,
          },
          text: {
            x: 407,
            y: 41,
            label: "Lille",
          },
        },
        {
          dot: {
            x: 391,
            y: 63,
          },
          text: {
            x: 391,
            y: 69,
            label: "Arras",
          },
        },
      ],
      icon_size: 10,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  },

  mounted() {
    setTimeout(() => {
      this.place_weather(this.$refs);
    }, 202);
    setTimeout(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onResize);
    }, 205);
  },

  methods: {
    onResize() {
      this.place_weather(this.$refs);
    },
  },

  watch: {
    weather: {
      deep: true,
      handler: function(val, oldVal) {
        this.place_weather(this.$refs);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
path {
  fill: #618a1c;
  stroke-width: 1px;
  stroke: #618a1c;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}

.temperature-shadow {
  text-shadow: 2px 0 0 white;
}

.highlight {
  fill: #93d02d;
  stroke-width: 0.112417px;
  stroke: #74a425;
  pointer-events: all;
}
</style>
