<template>
  <v-row v-resize="onResize" class="fill-height" align="end" no-gutters>
    <component
      :is="componentId"
      :car_data="car_data"
      :loyer="loyer"
      :loyer_duration="loyer_duration"
      :loyer_taeg="loyer_taeg"
      :orientation="orientation"
    ></component>
  </v-row>
</template>

<script>
import SmallDetailsCar from "@/components/Diapo/DetailsCar/small/DetailsCar";
import LargeDetailsCar from "@/components/Diapo/DetailsCar/large/DetailsCar";

export default {
  name: "ParentDetailsCar",

  props: {
    loyer: {
      type: Boolean,
      default: false,
    },
    loyer_duration: {
      type: Number,
      default: 0,
    },
    loyer_taeg: {
      type: Number,
      default: 0,
    },
    car_data: {
      type: Object,
      default: () => {
        ({});
      },
    },
    smallDetailsCar: {
      type: Boolean,
      default: false,
    },
  },
  components: { SmallDetailsCar, LargeDetailsCar },

  data() {
    return {
      componentId: "LargeDetailsCar",
      orientation: "landscape",
    };
  },

  mounted() {
    this.onResize();
  },

  methods: {
    onResize() {
      this.orientation = window.innerWidth >= window.innerHeight ? "landscape" : "portrait";
    },
  },
  watch: {
    smallDetailsCar: {
      handler: function(val) {
        if (val) this.componentId = "SmallDetailsCar";
        else this.componentId = "LargeDetailsCar";
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
