<template>
  <v-container class="pa-0" fluid>
    <transition :name="transition_name" mode="out-in">
      <v-row class="d-block" align-content="center" justify="center" v-if="url_traffic" no-gutters>
        <v-img
          :src="url_traffic"
          :class="{ kenbruns: kenbruns }"
          max-width="100vw"
          max-height="100vh"
          contain
          @error="
            () => {
              $emit('on-image-error', { url: url_traffic });
            }
          "
        />
      </v-row>
    </transition>
  </v-container>
</template>

<script>
export default {
  name: "Map",

  props: {
    url_traffic: {
      type: String,
      default: null,
    },
    kenbruns: {
      type: Boolean,
      default: true,
    },

    transition_name: {
      type: String,
      default: "fade",
    },
  },
};
</script>

<style lang="scss" scoped>
.kenbruns {
  animation: move 40s forwards;
  /* Add infinite to loop. */

  -ms-animation: move 40s forwards;
  -webkit-animation: move 40s forwards;
  -moz-animation: move 40s forwards;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */

    -webkit-transform: scale(1);
    /* Safari and Chrome */

    -o-transform: scale(1);
    /* Opera */

    -moz-transform: scale(1);
    /* Firefox */
  }
  100% {
    transform: scale(1.17);
    -ms-transform: scale(1.17);
    /* IE 9 */

    -webkit-transform: scale(1.17);
    /* Safari and Chrome */

    -o-transform: scale(1.17);
    /* Opera */

    -moz-transform: scale(1.17);
    /* Firefox */
  }
}
</style>
