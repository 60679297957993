<template>
  <v-container v-if="!loading" class="fill-height pa-0" fluid>
    <v-row justify="center" align="center" class="custom-card fill-height px-0" no-gutters>
      <v-col cols="10" class="fill-height mx-0 px-0">
        <v-row justify="center" align="center" class="fill-height">
          <v-col cols="12">
            <div class="text-center light-blue--text text--lighten-4 text-h2 font-weight-bold my-12 shadow-temperature">
              <v-icon x-large class="light-blue--text text--lighten-4">mdi-map-marker</v-icon>
              {{ label }}
            </div>
            <v-row justify="center" align="center">
              <v-col cols="10" md="3" class="text-center weather_card_border">
                <v-row
                  justify="center"
                  align="center"
                  class="light-blue--text text--lighten-4 text-body-1 font-weight-normal pb-5"
                  no-gutters
                  >{{ currentDate }}</v-row
                >
                <v-row justify="center" class="light-blue--text text--lighten-4 text-h4 font-weight-normal" no-gutters>{{
                  currently_summary
                }}</v-row>
                <v-row
                  justify="center"
                  class="light-blue--text text--lighten-4 text-h1 my-5 shadow-temperature font-weight-medium"
                  no-gutters
                  >{{ `${Math.trunc(currently_temperature)}°C` }}</v-row
                >
                <v-row
                  justify="center"
                  align="center"
                  v-if="currently_data != null"
                  class="text-center"
                  no-gutters
                  style="flex-wrap: nowrap;"
                >
                  <v-col cols="6">
                    <div class>
                      <v-img
                        class="mx-auto"
                        :src="require(`@/assets/weather/${list_icon[currently_data.icon]}`)"
                        contain
                        width="50"
                        height="50"
                      ></v-img>
                    </div>
                  </v-col>
                  <v-col cols="6" class="flex-grow-0 flex-shrink-1 light-blue--text text--lighten-4" style="min-width: 100px;">
                    <v-row class="flex-column" no-gutters>
                      <v-row class="text-h5 font-weight-regular" justify="start" align="center" no-gutters>
                        <span class="font-weight-medium blue--text text--lighten-2 mr-0 mr-md-1">
                          <v-icon small>mdi-arrow-down</v-icon>{{ `${Math.trunc(currently_data.temperatureMin)}°C` }}
                        </span>
                        <span class="font-weight-medium red--text text--lighten-3">
                          <v-icon small>mdi-arrow-up</v-icon>{{ `${Math.trunc(currently_data.temperatureMax)}°C` }}
                        </span>
                      </v-row>
                      <v-row no-gutters class="text-body-2">{{
                        `Précipitation ${Math.trunc(currently_data.precipProbability * 100)}%`
                      }}</v-row>
                      <v-row class="text-body-2" no-gutters>{{ `Humidité ${Math.trunc(currently_data.humidity)}%` }}</v-row>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="0" sm="1"></v-col>
              <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="7" class="px-0">
                <v-row justify="center" align="center" class="flex-column" no-gutters>
                  <v-col cols="12">
                    <v-row
                      align="center"
                      class="text-center light-blue--text text--lighten-4 font-weight-regular"
                      :class="{
                        'justify-space-around': $vuetify.breakpoint.lgAndUp,
                        'justify-center': $vuetify.breakpoint.mdAndDown,
                      }"
                    >
                      <v-col
                        v-for="day in responsiveDaily"
                        :key="day.time"
                        cols="6"
                        md="3"
                        lg="2"
                        class="weather_card_border pa-1 ma-1"
                        style="min-width: 140px"
                      >
                        <v-row class="flex-column" no-gutters>
                          <v-col class="text-h5 py-3 mb-2">{{ day.time_formatted }}</v-col>
                          <v-col>
                            <v-img
                              class="mx-auto"
                              :src="require(`@/assets/weather/${list_icon[currently_data.icon]}`)"
                              contain
                              width="50"
                              height="50"
                            ></v-img>
                          </v-col>
                          <!-- <v-col
                            class="font-weight-medium blue--text text--lighten-3 mb-0 pb-0"
                            v-html="`${Math.trunc(hr.temperatureMin || 0)}°C`"
                          ></v-col>
                          <v-col
                            class="font-weight-medium red--text text--lighten-3 my-0 py-0"
                            v-html="`${Math.trunc(hr.temperatureMax || 0)}°C`"
                          ></v-col> -->
                          <v-col class="text-subtitle-1 font-weight-regular mt-3">
                            <span class="font-weight-medium blue--text text--lighten-3 mr-2">
                              <v-icon small>mdi-arrow-down</v-icon>{{ `${Math.trunc(day.temperatureMin)}°C` }}
                            </span>

                            <span class="font-weight-medium red--text text--lighten-3">
                              <v-icon small>mdi-arrow-up</v-icon>{{ `${Math.trunc(day.temperatureMax)}°C` }}
                            </span>
                          </v-col>
                          <v-col no-gutters class="text-body-2">{{
                            `Précipitation ${Math.trunc(day.precipProbability * 100)}%`
                          }}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-col v-if="!$vuetify.breakpoint.mdAndDown && $vuetify.breakpoint.height > 800" cols="8" lg="12" class="mt-12 py-3">
              <v-row justify="center" align="center" class="weather_card_border" style="min-height: 180px" no-gutters>
                <v-col v-for="(hr, index) in responsiveHourly" :key="hr.time" :class="{ hourly_weather: index < 3 }">
                  <v-row
                    justify="center"
                    align="center"
                    class="light-blue--text text--lighten-4 text-center"
                    :class="{
                      'justify-space-around': $vuetify.breakpoint.lgAndUp,
                      'justify-center': $vuetify.breakpoint.mdAndDown,
                    }"
                    no-gutters
                  >
                    <v-col class="text-h5 py-1" cols="12">{{ hr.time_formatted }}</v-col>
                    <v-col class="py-1" cols="12">
                      <v-img
                        class="mx-auto text-h2"
                        :src="require(`@/assets/weather/${list_icon[currently_data.icon]}`)"
                        contain
                        width="50"
                        height="50"
                      ></v-img>
                    </v-col>
                    <v-col class="heading py-1 font-weight-medium" cols="12">{{ `${Math.trunc(hr.temperature || 0)}°C` }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import m_weather from "@/utils/mixins/weather";

export default {
  name: "WeatherFullFormat",

  mixins: [m_weather],

  data() {
    return {
      weather: null,

      label: "",
      currently_summary: "",
      currently_temperature: null,
      currently_data: null,
      hourly: null,
      daily: null,

      currentDate: null,
      currentTime: null,
      loading: true,

      jours_semaine: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"],
      timeout: null,
    };
  },

  beforeDestroy() {
    if (this.timeout != null) clearTimeout(this.timeout);
  },

  async mounted() {
    try {
      this.currentDate = this.getCurrentDate();
      this.currentTime = new Date().timeNow();

      this.weather = (await this.$axios.get("/weather")).data;
      const dateTimeFormat = new Intl.DateTimeFormat("fr-FR", { weekday: "short", day: "2-digit" });

      this.label = this.weather.label;
      this.currently_summary = this.weather.currently.summary;
      this.currently_temperature = this.weather.currently.temperature;
      this.currently_data = this.weather.currently;
      this.currently_data = {
        ...this.weather.daily.data[0],
        ...this.currently_data,
      };
      this.hourly = this.weather.hourly.data.map((el) => ({
        ...el,
        time_formatted: new Date(el.time * 1000).timeNow(),
      }));

      this.daily = this.weather.daily.data.map((el) => {
        const [{ value: weekday }, , { value: day }, , ,] = dateTimeFormat.formatToParts(el.time * 1000);

        return {
          ...el,
          time_formatted: `${weekday} ${day}`,
        };
      });
    } catch (error) {
      console.error(error);
      this.$emit("error", error);
    } finally {
      this.loading = false;
    }

    this.timeout = setTimeout(this.setCurrentTime, 20000);
  },

  methods: {
    setCurrentTime() {
      let now = new Date();

      this.currentTime = new Date().timeNow();

      this.timeout = setTimeout(this.setCurrentTime, 20000);
    },
  },

  computed: {
    responsiveDaily() {
      // v-if="index < 2 || (index >= 2 && $vuetify.breakpoint.lgAndUp)"
      this.$vuetify.breakpoint.lgAndUp;
      return this.$vuetify.breakpoint.lgAndUp ? this.daily : this.daily.slice(0, 3);
    },

    responsiveHourly() {
      this.$vuetify.breakpoint.lgAndUp;
      return this.$vuetify.breakpoint.lgAndUp ? this.hourly : this.hourly.slice(0, 2);
    },
  },
};
</script>

<style scoped>
.custom-card {
  background-color: transparent;
  background-image: linear-gradient(#297af9, #333fb7);
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  /* border-radius: 10px; */
  box-shadow: rgba(0, 0, 0, 0.06) 0 3px 20px 0;
  min-height: 60%;
}

.weather_card_border {
  border-width: 4px;
  border-style: solid;
  border-color: #8bc5ff;
  border-radius: 15px;
}

.shadow-temperature {
  text-shadow: -8px 7px 0px #003c87;
}

.big-icon {
  font-size: 4em;
}
</style>
