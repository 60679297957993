<template>
  <div
    v-if="logo"
    :class="{ 'ma-md-3 ma-sm-1': true, 'top-left': topLeft, 'bottom-right': bottomRight, 'bottom-left': bottomLeft }"
  >
    <div class="pa-2 logo-radius" :class="{ 'opacity-logo': opacity, 'logo-bg-white': bgWhite }">
      <v-img class="responsive-logo" :src="logo" @error="$emit('on-image-error', logo)" contain></v-img>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  props: {
    logo: {
      type: String,
      default: null,
    },
    topLeft: {
      type: Boolean,
      default: false,
    },
    bottomRight: {
      type: Boolean,
      default: false,
    },
    bottomLeft: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Boolean,
      default: false,
    },
    bgWhite: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .responsive-logo {
    width: 4rem;
    max-width: 4rem;
  }
}

.top-left {
  position: absolute;
  top: 0;
  z-index: 5;
}

.bottom-right {
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 5;
}

.bottom-left {
  position: absolute;
  bottom: 15px;
  left: 0;
  z-index: 5;
}

.logo-bg-white {
  // :class="{ 'white-custom': $route.query.s_bg_logo_white }"
  background-color: rgba(255, 255, 255, 1);
}

.responsive-logo {
  width: 7rem;
  height: 7rem;
}

.logo-radius {
  border-radius: 5em;
}

.opacity-logo {
  opacity: 0.8;
}
//  style="position: absolute; top: 0; z-index: 1"
</style>
