<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="494 440 200 200"
      style="transform: scale(1.5);"
    >
      <path
        d="m 571.15408,527.79159 2.81,0.45 5.4,2.69 2.03,0.48 1.74,-0.78 -0.09,1.73 1.93,2.54 4.8,2.82 0.99,-0.22 1.08,4.32 2.21,1.39 0.25,2.83 1.26,1.64 2.52,-0.15 -0.26,4.77 0.64,0.51 -0.72,0.83 1.6,1.03 -0.83,2.76 3.51,0.96 2.43,-1.89 1.11,0.38 0,0 0.17,8.07 -1.4,1.92 0.68,1.22 -1.7,1.12 0.29,1.11 -1.93,-0.65 -0.89,2.92 1.32,-1.61 2.12,0.59 -1.07,1.95 -2.89,1.58 0.2,3.66 -2.3,1.2 -0.28,1.83 2.05,-0.85 -2.13,3.13 -4.98,-1.37 0.86,-2.59 -1.71,-0.1 -0.29,-1.3 -1.15,0.49 -0.9,-1.2 -4.06,-0.59 -1.83,-1.94 -1.39,0.36 0.1,-1.59 -2.41,-0.52 -0.07,-3.46 0.81,-1.29 0.61,0.67 2.2,-0.96 1.79,-2.26 -4.31,-1.47 -1.27,0.8 -0.28,-2.29 -2.82,1.09 -0.1,-0.98 -1.9,-0.07 2.98,-2 -0.77,-1.43 2.48,-0.52 0.53,-1.05 -0.86,-0.75 1.03,-0.35 0.61,-2.5 -2.28,-2.11 -1.21,1.4 -3.21,-0.12 -1.42,0.92 0.61,-2.12 -1.23,-1.86 3,-0.7 -0.19,-1.94 3.62,-2.07 -1.17,-0.77 -0.8,-2.73 -1.69,0.66 -1.96,-1.91 -1.3,0.37 0.38,-1.16 -1.35,0.18 1.49,-1.26 -1.35,-0.18 1,-0.42 -0.16,-1.49 -0.61,0.04 0.29,-1.3 -1.37,-0.52 6.35,-1.73 -1.38,-1.72 -2.3,-0.36 -0.27,-0.75 1.29,-1.16 -0.86,-0.56 -2.3,0.9 -0.34,-2.31 1.02,0.34 0.15,-0.67 z"
        title="Corse-du-Sud"
        id="FR-2A"
      />
      <path
        d="m 606.45408,492.34159 1.68,1.41 1.22,10.22 -1.96,7.41 3.6,7.06 -0.02,9.96 1.16,4.93 -0.05,4.86 -0.37,5.08 -5.72,8.4 -0.39,5.22 0,0 -1.11,-0.38 -2.43,1.89 -3.51,-0.96 0.83,-2.76 -1.6,-1.03 0.72,-0.83 -0.64,-0.51 0.26,-4.77 -2.52,0.15 -1.26,-1.64 -0.25,-2.83 -2.21,-1.39 -1.08,-4.32 -0.99,0.22 -4.8,-2.82 -1.93,-2.54 0.09,-1.73 -1.74,0.78 -2.03,-0.48 -5.4,-2.69 -2.81,-0.45 0,0 1.48,-0.3 -0.14,-1.67 2.12,-0.04 -0.34,-1.42 1.31,-1.49 -1.2,-0.25 0.65,-2.38 2.23,-0.54 -0.45,-2.89 0.61,-0.17 0.15,1.03 2.46,0.19 0.63,-2.49 2.69,-0.33 0.49,-1.17 1.82,-0.59 3.84,-0.08 1.8,-1.01 -0.07,-1.86 2.82,-2.22 4.03,-0.21 3.19,3.3 1.91,-3.34 -0.1,-3.32 -1.37,-2.15 1.28,-1.84 -0.76,-1.93 1.51,-1.44 -0.76,-3.87 3.43,-0.98 z"
        title="Haute-Corse"
        id="FR-2B"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "Corse",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>