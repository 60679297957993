<template>
  <div></div>
</template>

<script>
export default {
  name: "RepetitiveSignal",

  props: {
    ready: {
      type: Boolean,
      default: false,
    },
    milliseconds: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      timeout_milliseconds: null,
    };
  },

  beforeDestroy() {
    this.clearDisplayTimeTimeout();
  },

  methods: {
    emitReady() {
      this.$emit("repetitive-signal-over", { milliseconds: this.milliseconds });
    },

    startTimeout() {
      this.clearDisplayTimeTimeout();
      if (Number.isFinite(this.milliseconds)) {
        this.timeout_milliseconds = setTimeout(() => {
          this.emitReady();
        }, this.milliseconds);
      }
    },

    clearDisplayTimeTimeout() {
      if (this.timeout_milliseconds) clearTimeout(this.timeout_milliseconds);
      this.timeout_milliseconds = null;
    },
  },

  watch: {
    ready: {
      handler: function(val, oldVal) {
        if (val == true && this.milliseconds) this.startTimeout();
        else if (val == false) {
          this.clearDisplayTimeTimeout();
        }
      },
      immediate: true,
    },

    milliseconds: {
      handler: function(val) {
        if (val && this.ready) this.startTimeout();
      },
      immediate: true,
    },
  },
};
</script>
