<template>
  <v-scroll-y-transition>
    <component v-on:error="onError" :is="componentId"></component>
  </v-scroll-y-transition>
</template>

<script>
import WeatherFullFormat from "@/components/Weather/Local/WeatherFullFormat";
import WeatherPaysage from "@/components/Weather/Local/WeatherPaysage";

export default {
  name: "WeatherLocal",

  components: {
    WeatherFullFormat,
    WeatherPaysage,
  },

  data() {
    return {
      componentId: "",
      orientation: "",
    };
  },

  beforeDestroy() {
    this.removeOrientationEvents();
  },

  mounted() {
    this.initOrientationEvents();
    this.setOrientation();
  },

  methods: {
    setOrientation() {
      this.orientation = window.matchMedia("(orientation: portrait)").matches ? "portrait" : "paysage";

      this.componentId = this.orientation == "paysage" ? "WeatherFullFormat" : "WeatherPaysage";
    },

    initOrientationEvents() {
      window.addEventListener("resize", this.setOrientation);
      window.addEventListener("orientationchange", this.setOrientation);
    },

    removeOrientationEvents() {
      window.removeEventListener("resize", this.setOrientation);
      window.removeEventListener("orientationchange", this.setOrientation);
    },

    onError(err) {
      this.$emit("error", err);
    },
  },
};
</script>

<style lang="scss" scoped></style>
