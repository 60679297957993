<template>
  <div class="parent p-relative">
    <div
      class="p-relative"
      :class="{
        'image-full-grid': detailsCar_overImage,
        'image-part-grid-left': !detailsCar_overImage && detailsCar_right,
        'image-part-grid-right': !detailsCar_overImage && !detailsCar_right,
      }"
    >
      <!-- VEHICULE TOP TITLE -->
      <!-- <top-title v-if="display_top_title && !smallDetailsCar" :car_data="car_data" /> -->
      <!-- END OF VEHICULE TOP TITLE -->

      <!-- CONDITION LOYER -->
      <condition-loyer
        v-if="loyer && display_condition_loyer"
        :loyer="loyer"
        :loyer_duration="loyer_duration"
        :loyer_taeg="loyer_taeg"
      />
      <!-- CONDITION LOYER -->
      <!-- PHOTO VEHICULE -->
      <transition name="fade" mode="out-in">
        <v-row class="justify-center align-center fill-height ma-0" v-if="url" no-gutters>
          <v-img
            max-width="100%"
            max-height="100%"
            :src="url"
            contain
            @load="onImageLoad"
            @error="
              () => {
                $emit('on-image-error', { url: url });
              }
            "
          ></v-img>
        </v-row>
      </transition>
      <!-- PHOTO VEHICULE -->
    </div>
    <!-- DETAILS CAR -->
    <div :class="{ 'div-detailscar-left': !detailsCar_right, 'div-detailscar-right': detailsCar_right }" style="z-index: 5;">
      <details-car
        v-if="display_details_car"
        :car_data="car_data"
        :loyer="loyer"
        :loyer_duration="loyer_duration"
        :loyer_taeg="loyer_taeg"
        :smallDetailsCar="smallDetailsCar"
      />
    </div>
    <!-- DETAILS CAR -->

    <slot name="additional-absolute"></slot>
    <!-- REPETITIVE SIGNAL -->
    <repetitive-signal
      key="duration"
      :ready="start_repetitive"
      :milliseconds="duration"
      v-on:repetitive-signal-over="onRepetitiveEnd"
    ></repetitive-signal>
    <!-- END OF REPETITIVE SIGNAL -->

    <!-- LEGAL NOTICE -->
    <Legal />
    <!-- LEGAL NOTICE -->
  </div>
</template>

<script>
import ConditionLoyer from "@/components/Diapo/ConditionLoyer/ConditionLoyer";
import TopTitle from "@/components/Diapo/TopTitle/TopTitle";
import DetailsCar from "@/components/Diapo/DetailsCar/index";
import Legal from "@/components/Legal/Legal";

import RepetitiveSignal from "@/components/RepetitiveSignal/RepetitiveSignal";

export default {
  name: "SceneVehicule",

  components: {
    ConditionLoyer,
    TopTitle,
    DetailsCar,
    RepetitiveSignal,
    Legal,
  },

  props: {
    /**
     * General
     */
    duration: {
      type: Number,
      default: null,
    },
    loyer: {
      type: Boolean,
      default: false,
    },
    loyer_duration: {
      type: Number,
      default: 0,
    },
    loyer_taeg: {
      type: Number,
      default: 0,
    },
    car_data: {
      type: Object,
      default: () => ({}),
    },
    url_image: {
      type: String,
      default: null,
    },
    display_condition_loyer: {
      type: Boolean,
      default: true,
    },
    display_top_title: {
      type: Boolean,
      default: true,
    },
    display_details_car: {
      type: Boolean,
      default: true,
    },
    start_duration: {
      type: Boolean,
      default: true,
    },
    transition_name: {
      type: String,
      default: "fade",
    },
    transition_duration: {
      type: Number,
      default: 1000,
    },

    /**
     * DetailsCar
     */
    detailsCar_right: {
      type: Boolean,
      default: false,
    },
    detailsCar_top: {
      type: Boolean,
      default: false,
    },
    detailsCar_overImage: {
      type: Boolean,
      default: true,
    },
    smallDetailsCar: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      start_repetitive: false,
      url: null,
    };
  },

  methods: {
    onImageLoad() {
      if (this.start_duration) this.start_repetitive = true;
    },
    onRepetitiveEnd() {
      this.$emit("vehicule-end", { ms: this.duration });
    },
  },

  watch: {
    url_image: {
      handler: async function(newVal, oldVal) {
        if (newVal) {
          if (this.url) {
            this.url = null;
            await this.$nextTick();
            await new Promise((resolved) => setTimeout(resolved, this.transition_duration));
          }
          this.start_repetitive = false;
          this.url = newVal;
          /**
           *  ! start_repetitive is set to true when the new image is loaded !
           */
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.parent {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(10, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
}

.image-full-grid {
  place-content: center;
  grid-area: 1 / 1 / 11 / 11;
  z-index: 1;
}

.image-part-grid-left {
  place-content: center;
  grid-area: 1 / 1 / 11 / 9;
  z-index: 1;
  // background-color: orchid;
}

.image-part-grid-right {
  place-content: center;
  grid-area: 1 / 3 / 11 / 11;
  z-index: 1;
  // background-color: orchid;
}

.div-detailscar-left {
  grid-area: 3 / 1 / 10 / 3;
  z-index: 1;
  // background-color: palegreen;
}

.div-detailscar-right {
  grid-area: 3 / 9 / 10 / 11;
  z-index: 1;
  // background-color: palegreen;
}
</style>
