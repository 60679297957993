<template>
  <div
    :class="{
      'font-weight-medium full-width bottom black': true,
      'small-letter-spacing': true,
      'text-xs': $vuetify.breakpoint.smAndDown,
      'text-md': $vuetify.breakpoint.mdAndUp,
    }"
  >
    <transition :name="transition_name" mode="out-in">
      <div :key="refresh_key">{{ legal_text[legal_index].text }}</div>
    </transition>
    <repetitive-signal
      key="duration"
      :ready="ready_duration"
      :milliseconds="duration"
      v-on:repetitive-signal-over="onDurationEnd"
    ></repetitive-signal>
  </div>
</template>

<script>
import RepetitiveSignal from "@/components/RepetitiveSignal/RepetitiveSignal";

export default {
  name: "Legal",

  components: {
    RepetitiveSignal,
  },

  props: {
    duration: {
      type: Number,
      default: 1000 * 7,
    },
    transition_name: {
      type: String,
      default: "fade",
    },
  },

  data() {
    return {
      ready_duration: false,

      legal_text: [
        { text: "Pour les trajets courts, privilégiez la marche ou le vélo #SeDéplacerMoinsPolluer", displayed: false },
        { text: "Pensez à covoiturer #SeDéplacerMoinsPolluer", displayed: false },
        { text: "Au quotidien, prenez les transports en commun #SeDéplacerMoinsPolluer", displayed: false },
      ],
      legal_index: 0,
      componentId: "",

      refresh_key: new Date().toISOString(),
    };
  },

  mounted() {
    this.ready_duration = true;
    this.legal_text[0].displayed = true;
  },
  methods: {
    resetRotation() {
      this.legal_text[0].displayed = false;
      this.legal_text[1].displayed = false;
      this.legal_text[2].displayed = false;
    },

    rotationText() {
      if (this.legal_text[0].displayed == false) {
        this.legal_text[0].displayed = true;
        this.legal_index = 0;
      } else if (this.legal_text[1].displayed == false) {
        this.legal_text[1].displayed = true;
        this.legal_index = 1;
      } else if (this.legal_text[2].displayed == false) {
        this.legal_text[2].displayed = true;
        this.legal_index = 2;
      }
      this.refresh_key = new Date().toISOString();
    },

    async onDurationEnd() {
      console.log("onDurationEnd");
      if (this.legal_text.filter((el) => el.displayed === false).length <= 0) {
        this.resetRotation();
      }
      this.rotationText();
      // Reset ready_duration
      this.ready_duration = false;
      await this.$nextTick();
      this.ready_duration = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100%;
  text-align: center;
}

.text-xs {
  font-size: 1.75vh !important;
}

.text-md {
  font-size: 1.75vh !important;
}

.bottom {
  position: absolute;
  max-width: 100%;
  bottom: 0px;
  z-index: 1;
}

.small-letter-spacing {
  line-height: 1.75vh !important;
}
</style>
