<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="70 230 300 300"
      style="transform: scale(1);"
    >
      <path
        d="m 223.25408,313.20159 2.65,0.88 0.83,1.22 4.7,-0.49 1.52,1.2 2.25,-1.03 -0.07,-1.22 -1.25,-0.03 -0.23,-0.9 2.01,-2.09 1.97,2.97 2.34,0.88 0.95,-1.22 2.85,-0.2 -0.5,-0.93 1.57,-1.68 3.02,0.58 1.04,-0.57 0.57,0.67 0,0 -0.62,0.44 1.04,1.43 -0.85,2.73 1.82,1.91 1.08,-0.83 1.76,1.41 0.61,3.11 -3.37,2.38 -1.99,-0.89 0.6,2.93 -1.82,5.42 -2.94,-0.75 0.13,2.49 -2.54,1.3 -0.97,1.79 0,0 -2.69,4.53 -1.12,0.2 -0.35,-0.9 -0.79,0.73 -0.27,4.8 -2.3,1.64 -0.72,2.23 -2.12,0.01 -2.08,2.58 -1.17,-1.02 0.61,1.33 -1.7,0.57 -1.19,3.41 0.79,4.25 -1.85,0.41 -0.7,1.56 -1.22,0.17 -0.03,1.3 -1.65,1.92 -1.51,-1.25 -1.82,0.02 -2.09,2.08 0,0 -0.05,-2.15 -1.81,-1.13 -3.13,-0.06 0.21,-2.46 -1.42,0.16 -0.58,-1.08 -1.54,0.04 -1.01,-1 -1.55,1.41 -1.12,-0.63 0.28,-2.33 1.15,0.39 0.21,-1.13 -1.46,0.29 -1.27,-1.7 3.15,-1.52 -1.34,-2.63 -0.81,-0.05 1.86,-2.72 -1.11,-0.34 0.64,-0.78 -2.87,-0.8 1.09,-1.72 -0.56,-0.77 -3.24,-2.93 -1.81,0.11 0.95,-1.8 1.07,0.02 -1.66,-1.81 0.11,-2.32 0.79,-0.64 -2.5,-0.5 0.5,-1.32 1.72,-0.13 0.25,-1.09 3.93,0 0.81,-1.21 2.14,0.24 0.63,1.62 2.44,-0.99 0.28,0.76 0.43,-3.44 1.36,-2.01 -1.38,-1.32 0.55,-1.04 -0.75,-0.9 2.18,-0.12 0.45,-1.32 -0.67,-1.13 0,0 2.69,-1.37 -0.81,-0.41 1.52,-1.79 -0.65,-1.46 2.29,0.11 1.82,-1.19 0.14,-1.13 1.27,0.1 0.08,-0.96 1.57,0.05 1.37,1.4 z"
        title="Charente"
        transform="scale(1)"
        id="FR-16"
      />
      <path
        d="m 156.83408,316.03159 1.54,0.54 2.28,2.68 3.22,0.61 0.07,3.28 2.01,2.5 -0.83,2.26 0.53,1.18 -1.49,1.74 -1.58,-4.83 -4.89,-4.34 -0.86,-5.62 z m -4.29,-12.52 1.52,1.4 -1.65,-0.02 0.51,1.81 1.79,-1.42 1.87,-0.13 -1.1,1.29 3.5,0.17 2.62,1.1 0.79,1.68 0.91,-0.19 -2.4,1.19 -6.33,-3.54 -2.98,0.01 -1.17,-2.49 2.12,-0.86 z m 16.37,-3.54 1.7,-0.04 0.88,-1.95 3.28,-0.1 1.86,-1.17 -1.31,2.94 1.17,0.68 1.91,-0.96 1.55,0.55 0.39,-1.36 1.62,-0.14 -0.08,1.02 2.24,1.26 0,0 0.58,2.17 -0.72,1.35 2.64,1.66 -0.1,2.24 0.95,-0.27 0.68,1.09 1.97,0.53 -1.26,0.44 0.67,0.83 2.77,-0.54 1.65,0.91 0.3,1.5 2.68,0.58 0.85,-1.05 -0.05,1.25 0.85,0.55 1.23,-0.59 0.34,1.1 3.05,-0.71 1.32,2.1 1.91,0.49 0.83,-0.6 0.2,1.63 1.19,-0.66 -0.19,1.6 1.03,0.64 0.07,1.24 1.42,0.54 0,0 0.68,1.13 -0.45,1.31 -2.18,0.13 0.75,0.9 -0.55,1.03 1.38,1.32 -1.35,2.01 -0.44,3.44 -0.28,-0.76 -2.44,0.98 -0.63,-1.62 -2.14,-0.23 -0.81,1.21 -3.94,-0.01 -0.25,1.09 -1.71,0.13 -0.5,1.32 2.5,0.5 -0.78,0.64 -0.11,2.32 1.66,1.81 -1.07,-0.02 -0.95,1.8 1.81,-0.11 3.24,2.93 0.56,0.76 -1.08,1.73 2.87,0.8 -0.64,0.78 1.1,0.34 -1.86,2.72 0.81,0.05 1.33,2.62 -3.15,1.53 1.27,1.7 1.46,-0.29 -0.21,1.13 -1.15,-0.39 -0.28,2.32 1.12,0.64 1.56,-1.42 1,1.01 1.54,-0.04 0.58,1.08 1.42,-0.16 -0.21,2.46 3.13,0.06 1.81,1.13 0.05,2.16 0,0 -0.89,1.28 0,0 0.66,0.62 0,0 -1.47,1.13 -0.12,2.24 0,0 -2.31,-1.15 -2.01,1.86 -1.96,-0.28 -2.82,-1.2 -0.89,-1.63 -1.15,-0.36 -0.26,0.64 -1.52,-1.84 -1.37,1.53 -1.56,-3.79 0.61,-1.87 -0.82,-0.48 0.14,-1.69 -2.08,-1 -3.84,-0.15 -0.93,-2.95 -0.34,1.52 -4.97,-0.42 0,0 -1.41,-5.12 -2.92,-4.56 -3.94,-3.37 -1.94,-0.45 -2.58,-3.88 -0.71,0.33 -3.08,-1.75 -3.76,-2.95 -1.26,0.25 -0.06,-5.54 4.3,-1 -1.69,-3.2 2.22,0.04 0.79,-0.84 1.14,-3.32 -1.08,-1.12 0.99,-0.85 -1.26,-2.11 2.27,-0.46 -2.03,-5.5 -1.73,-0.69 0.59,-1.22 -1.87,-0.65 0.63,-1.06 -2.96,0.09 1.2,-3.45 3.9,-3.25 -0.39,-3.1 z"
        title="Charente-Maritime"
        transform="scale(1)"
        id="FR-17"
      />
      <path
        d="m 294.04408,336.79159 1.13,-0.92 0.82,0.57 0.5,-1.24 1.41,0.26 0.46,-1.69 2.02,-0.96 1.43,2.13 1.96,-0.31 1.01,0.71 0.28,-0.86 1.78,1.25 -0.51,1 0.76,-0.78 0.52,0.99 1.85,0.23 0,0.81 0,0 -0.07,0.81 0,0 2.22,-0.27 1.12,-2.39 3.66,0.56 2.31,-2.28 0,0 1.33,1.67 -0.45,2.58 0.71,0.47 -0.41,1.06 -1.41,0 -1.03,2.65 1.16,2.08 1.18,0.39 -0.38,4.47 0,0 -0.88,3.51 1.43,1.02 0.08,1.18 -1.8,0.7 -5.24,-2.65 0.22,4.61 -1.81,0.89 -1.12,1.96 -0.83,-0.17 -1.18,2.51 -1.94,1.59 0.28,2.37 1.35,0.76 -2.23,1.84 -0.27,3.22 -1.25,-0.25 -1.84,1.55 1.84,3.43 -1.15,0.96 0,0 -0.83,-0.04 0,0 -1.4,0.57 0,0 -3.13,0.1 -1.57,1.28 -0.31,-1.21 -1.34,-0.27 -4.4,3.21 -2.14,-0.85 -0.02,-1.14 -1.8,-0.73 -2.5,-3.47 -2.95,-0.75 -1.23,0.68 -0.32,-1.02 -3.74,1.58 0,0 -2.91,-5.01 1.41,-1.16 -2.57,-0.94 -1.69,0.48 -2.32,-1.82 0,0 0.61,0 0,0 0.88,-1.46 -2.55,-0.92 2.1,-2.17 -0.11,-0.9 -2.01,-0.93 0.37,-2.87 1.2,-0.1 2.49,-3.42 -2.75,-0.99 -0.03,-1.28 0.83,0.41 0.63,-1.21 -1.67,-0.63 0,0 1.35,-2.74 2.66,1.36 2.39,-3.5 1.44,0.24 0.43,-1.86 1.05,-0.66 0.62,0.93 3.74,-0.39 0.53,-1.28 1.76,-0.19 2.99,-3.52 2.81,-1.06 0.35,-1.35 1.92,1.09 1.72,-0.13 0.32,-0.99 1.17,0.1 z"
        title="Corrèze"
        transform="scale(1)"
        id="FR-19"
      />
      <path
        d="m 274.01408,298.19159 4.59,-4.84 0.79,2.05 2.68,-1.73 0.79,2 2.37,-1.82 1.8,1.69 1.29,-2.11 -0.52,-1.49 1.47,-0.38 2.33,1.85 1.9,-0.96 1.95,0.63 1.95,-0.71 4.55,1.99 3.31,-0.87 0,0 4.66,0.28 0,0 0.29,2.3 1.97,0.72 -1.35,1.57 2.6,1.97 0.95,-1.27 1.4,2.9 0.42,-0.78 2.11,0.96 3.53,8.19 0,0 -0.71,3.4 0.89,2.32 1.25,0.73 -0.36,2.61 0.67,1.43 -1.86,0.62 -0.83,1.46 0.51,1.1 -2.35,1.61 -0.31,1.35 -1.98,-0.15 0.07,1.28 -2.42,1.05 2.29,4.04 2,1.23 0,0 -2.31,2.28 -3.66,-0.56 -1.12,2.39 -2.22,0.27 0,0 0.07,-0.81 0,0 0,-0.81 -1.85,-0.23 -0.52,-0.99 -0.76,0.78 0.51,-1 -1.78,-1.25 -0.28,0.86 -1.01,-0.71 -1.96,0.31 -1.43,-2.13 -2.02,0.96 -0.46,1.69 -1.41,-0.26 -0.5,1.24 -0.82,-0.57 -1.13,0.92 0,0 -1.06,-1.67 0.09,-1.72 1.09,-0.76 -0.77,-1.63 -1.94,-1.74 -0.36,0.93 -2.24,-1.36 -0.06,-2.04 -1.75,1.48 -2.84,0.42 -2.35,-1.33 -0.51,-1.43 0.97,-0.55 0.2,0.61 0.05,-1.12 0.93,0.45 -0.83,-1.49 0.66,-0.55 -2.18,-0.35 -0.92,1.01 -2.28,-1.05 2.75,-2.75 -1.83,-1.84 0.74,-1.48 -0.42,-2.69 -2.3,-1.53 0.94,-1.04 -0.45,-1.17 -0.52,0.41 -1.24,-1 0.24,-1.6 -1.96,0.11 -1.61,-2.29 1.86,-1.61 -0.56,-1.31 1.35,-0.46 -0.99,-0.57 1.21,-1.33 0.06,-1.91 z"
        title="Creuse"
        transform="scale(1)"
        id="FR-23"
      />
      <path
        d="m 241.41408,336.11159 0.96,1.29 2.81,-0.43 2.24,1.67 -0.97,2.94 0.99,1.59 1.25,-0.27 0.28,1.21 1.36,-0.49 0.99,-2.34 1.18,1.28 2.01,-0.86 3.01,0.29 2.8,4.71 1.26,-0.99 2.1,1.3 -1.89,2.95 1.73,-0.14 0.62,1.25 1.32,-0.37 1.12,0.51 -0.07,0.83 0.78,-0.21 0,0 1.67,0.63 -0.63,1.21 -0.83,-0.41 0.03,1.28 2.75,0.99 -2.49,3.42 -1.2,0.1 -0.37,2.87 2.01,0.93 0.11,0.9 -2.1,2.17 2.55,0.92 -0.88,1.46 0,0 -0.61,0 0,0 2.32,1.82 1.69,-0.48 2.57,0.94 -1.41,1.16 2.91,5.01 0,0 -1.58,0.81 0,0 0.18,0.78 0,0 1.17,4.49 -1.2,0.35 1.1,1.98 -3.19,1.96 0.18,1.9 -2.81,0.88 -0.04,1.17 1.04,0.65 -0.28,1.43 -0.69,-0.2 -0.5,1.59 -0.71,-0.36 -1.92,2.25 -3.13,0.8 0.16,2.03 -2.37,2.41 0.4,1.17 -1.16,-0.03 0,0 -0.18,-1.32 -2.45,-1.09 -1.36,-1.71 -4.3,1.66 -0.28,1.08 -1.52,-0.19 -0.66,-1.56 1.44,-2.43 -2.31,-1.87 -1.39,1.3 -3.26,0.15 -2.41,-1.78 -0.09,0.81 -1.53,-0.42 -1.49,1.95 -1.8,-0.72 -3.73,1.96 -2.59,-0.61 -0.31,-3.74 -2.13,-2.55 0,0 -1.44,-3.67 0.94,0.15 1.09,-1.3 -1.52,-1.25 -1.61,-0.29 0.16,0.9 -2.14,2.18 -1.81,-0.55 -1.52,0.61 -0.48,-0.84 -1.15,0.85 -2.02,-1.79 -2.33,-0.17 2.82,-3.62 -1.33,-1.94 2.95,-7.2 -1.17,-2.65 -2.17,-0.21 -1.33,1.01 0,0 0.12,-2.24 1.47,-1.12 0,0 -0.66,-0.63 0,0 0.89,-1.28 0,0 2.09,-2.08 1.82,-0.02 1.51,1.25 1.65,-1.92 0.03,-1.3 1.22,-0.17 0.7,-1.56 1.85,-0.41 -0.79,-4.25 1.19,-3.41 1.7,-0.57 -0.61,-1.33 1.17,1.02 2.08,-2.58 2.12,-0.01 0.72,-2.23 2.3,-1.64 0.27,-4.8 0.79,-0.73 0.35,0.9 1.12,-0.2 z"
        title="Dordogne"
        transform="scale(1)"
        id="FR-24"
      />
      <path
        d="m 171.17408,344.92159 0.89,1.47 -0.8,0.05 -0.32,1.4 5.59,4.22 5.31,5.54 4.79,15.18 3.9,4.58 0.51,-0.37 -3.56,-6.7 -1.94,-11.61 0,0 4.97,0.42 0.34,-1.52 0.93,2.95 3.84,0.15 2.08,1 -0.14,1.69 0.82,0.48 -0.61,1.87 1.56,3.79 1.37,-1.53 1.52,1.84 0.26,-0.64 1.15,0.36 0.89,1.63 2.82,1.2 1.96,0.28 2.01,-1.86 2.31,1.15 0,0 1.32,-1.01 2.17,0.21 1.17,2.64 -2.95,7.2 1.33,1.94 -2.82,3.62 2.33,0.18 2.02,1.79 1.15,-0.85 0.49,0.84 1.52,-0.61 1.8,0.55 2.14,-2.18 -0.15,-0.89 1.6,0.29 1.52,1.24 -1.09,1.31 -0.94,-0.15 1.44,3.67 0,0 -1.68,0.72 -1.24,-0.86 -0.37,2.39 -0.71,0.13 -0.56,-1.75 -0.52,1.06 -1.4,-0.15 -0.29,1.82 -0.61,-0.48 -0.8,0.69 1.5,2.03 2.01,0.29 -0.84,1.66 -1.03,-0.32 0.5,1.64 -2.87,1.49 -0.51,2.01 -2.81,-0.07 0.78,1.17 -1.46,1.65 0.11,2.44 0.89,0.65 -1,1.76 1.62,2.46 -4.26,1.23 -0.27,0.96 2.05,2.41 0.09,1.42 -2.22,2.06 -1.48,-0.26 -0.77,0.81 0,0 -2.12,-2.66 -1.46,0.49 0.04,3.48 -1.94,0.74 -4.99,-0.89 0.23,-4.61 -1.97,-0.82 -0.05,-1.21 -3.5,-0.94 -0.38,-1.39 -4.77,-2.22 0.66,-0.82 -0.71,-2.05 -5.57,0.63 -1.35,1.16 -4.58,-1.37 -4.51,1.22 1.94,-3.38 -0.21,-1.55 -1.64,0.05 -2.39,-1.47 -0.93,1.74 -6.14,2.02 0,0 -0.11,-4.44 2.7,-6.77 2,0.21 0.12,0.89 5.67,0.33 -0.83,-2.51 -1.89,-0.97 1.48,-0.02 -5.54,-4.62 -0.26,1.77 -2.27,2.55 -0.77,5.03 -0.63,-1.04 4.13,-38.94 0.22,-10.45 2.59,-4.77 1.39,-1.02 -0.01,0.75 z"
        title="Gironde"
        transform="scale(1)"
        id="FR-33"
      />
      <path
        d="m 163.18408,408.94159 6.14,-2.02 0.93,-1.74 2.39,1.47 1.64,-0.05 0.2,1.55 -1.94,3.38 4.51,-1.22 4.58,1.37 1.35,-1.16 5.57,-0.64 0.71,2.06 -0.66,0.81 4.77,2.22 0.38,1.39 3.51,0.94 0.04,1.21 1.98,0.83 -0.23,4.6 4.99,0.9 1.94,-0.74 -0.04,-3.48 1.46,-0.49 2.12,2.66 0,0 0.51,4.28 5.21,0.15 1.53,1.15 4.24,0.34 -3.18,5.73 0.57,2.45 0,0 -0.86,1.36 0.88,2.45 -0.71,1.19 -3.32,-1.89 1.16,-1.43 -1.79,-1.74 -0.38,1.33 -2,0.75 -0.09,1.12 -1.26,-0.85 -1.38,0.89 -0.83,-0.66 -2.49,2.4 2.03,0.9 0.19,0.97 -0.93,0.41 0.62,2.74 -1.37,0.1 0.33,3.31 0.99,0.75 -2.29,1.72 0.32,3.01 -0.88,0.14 0.02,0.91 -0.8,-0.36 0.17,1.5 1.27,0.04 0.19,1.76 0,0 -1.69,-0.07 -2.18,2.01 -2.44,-1.04 -2.27,1.1 -0.54,-0.75 1.28,-1.28 -0.73,-0.63 -4.08,2.93 -0.86,-0.74 -1.18,1.16 -2.56,-1.04 0.65,-1.06 -2.71,0.82 -0.45,1.13 -2.13,-2.21 -3.19,2.13 -2.47,-0.49 -1.82,1.06 -1.69,-0.6 -0.14,2.19 -3.13,-0.26 -0.53,-0.81 -2.59,2.03 -1.14,-1.04 1.66,-0.76 -2.16,-1.61 -4.51,2.79 -5.19,0.07 -0.53,-0.97 -1.52,-0.14 -0.44,-1.3 -1.93,0.54 0,0 3.22,-6.79 5.57,-28.52 z"
        title="Landes"
        transform="scale(1)"
        id="FR-40"
      />
      <path
        d="m 209.52408,422.79159 0.77,-0.81 1.48,0.26 2.22,-2.06 -0.08,-1.42 -2.05,-2.41 0.26,-0.95 4.26,-1.23 -1.62,-2.46 0.99,-1.76 -0.88,-0.65 -0.12,-2.44 1.46,-1.64 -0.78,-1.18 2.81,0.07 0.52,-2.01 2.87,-1.48 -0.5,-1.64 1.03,0.32 0.85,-1.67 -2.02,-0.29 -1.5,-2.03 0.81,-0.69 0.61,0.48 0.29,-1.83 1.4,0.15 0.52,-1.06 0.56,1.75 0.71,-0.13 0.37,-2.39 1.24,0.86 1.68,-0.72 0,0 2.13,2.55 0.31,3.74 2.59,0.61 3.73,-1.96 1.8,0.72 1.49,-1.95 1.53,0.42 0.09,-0.81 2.41,1.78 3.26,-0.15 1.39,-1.3 2.31,1.87 -1.44,2.43 0.66,1.56 1.52,0.19 0.28,-1.08 4.3,-1.66 1.36,1.71 2.45,1.09 0.18,1.32 0,0 -2.54,2.14 -1.39,-0.41 1.47,2.16 0.02,3.55 1.44,0.76 0.46,3.09 0,0 -0.57,0.9 -4.07,0.06 -0.44,0.95 -0.87,-2.24 -1,0.16 -1.12,4.11 1,0.82 0.93,-0.38 1.44,1.61 -0.93,0.44 -0.9,4.38 -2.21,0.38 0.26,0.75 1.07,-0.23 0.36,2.14 -1.33,0.65 -3.02,-0.98 0.42,1.76 -1.78,0.33 -0.65,2.39 0,0 -3.25,2.38 -2.94,-3.22 -1.53,1.45 -3.94,-0.05 -0.78,1.57 -2.56,1.3 -2.75,-0.17 -0.59,1.08 -3.58,-1.84 -2.4,2.87 -1.1,-1.65 -2.26,1.07 0,0 -0.57,-2.45 3.18,-5.73 -4.24,-0.34 -1.53,-1.15 -5.21,-0.15 z"
        title="Lot-et-Garonne"
        transform="scale(1)"
        id="FR-47"
      />
      <path
        d="m 151.98408,462.87159 1.93,-0.55 0.44,1.3 1.53,0.14 0.53,0.97 5.19,-0.07 4.51,-2.8 2.15,1.61 -1.65,0.76 1.13,1.04 2.59,-2.03 0.53,0.8 3.13,0.26 0.14,-2.18 1.69,0.6 1.82,-1.06 2.47,0.49 3.2,-2.13 2.13,2.21 0.45,-1.13 2.71,-0.82 -0.66,1.06 2.56,1.03 1.19,-1.15 0.86,0.74 4.08,-2.93 0.74,0.63 -1.28,1.28 0.54,0.75 2.27,-1.1 2.43,1.04 2.18,-2 1.69,0.07 0,0 2.72,-0.66 0.69,0.91 2.66,-0.09 0,0 0.12,2.2 1.13,-0.27 1.08,2.03 -0.41,1.18 1.31,1.12 -1.97,0.51 -0.22,1.59 0.85,0.93 -0.69,0.46 1.01,0.04 1.75,-2 -0.12,4.16 1.38,1.48 -0.81,1.19 -1.53,-0.22 -0.81,1.58 1.2,1.76 -1.33,2.95 -0.99,-0.24 0.22,2.61 -1.59,0.59 -0.46,-0.76 -1.19,2.9 -1.95,0.83 -0.25,2.68 0.78,0.86 -3.35,0.83 0.05,1.28 -1.19,0.52 0.28,4.03 -1.92,0.94 0.59,3.83 0,0 -3.38,2.85 -2,0.2 -2.64,-1.8 -1.99,2.68 -5.16,-5.93 -1.64,-0.13 -1.51,-4.58 -2.26,0.96 -4.04,-0.71 -1.56,0.59 -7.1,-4 -1.16,0.91 -1.25,-1.7 -2.33,-0.98 -1.4,0.66 -1.93,-1.41 1.59,-2.83 -2.57,0.66 -0.88,4.44 -3.57,-1 -1.37,-2.38 2.44,-2.23 1.35,-3.49 -0.27,-3.9 -6.08,-2.04 -1.15,0.32 -0.28,1.88 -1.61,0.19 -0.66,-3.13 -1.87,-0.47 -2.49,1.07 -0.34,-1.94 -2.05,-1.16 0.69,-0.82 -0.61,-0.42 4.87,-0.93 1.57,-1.38 4.38,-6.63 z m 58.97,9.74 0,0 0.69,1.31 -0.9,-0.12 -0.29,0.86 0.98,0.7 0,0 0.94,-0.13 0,0 -0.09,-2.78 0,0 -1,-0.76 0,0 -0.49,0.35 0.16,0.57 z m -0.77,3.22 0,0 -0.75,1.72 0.78,1.75 1.73,-1.24 -0.82,-2.31 0,0 -0.46,-0.37 -0.48,0.45 z"
        title="Pyrénées-Atlantiques"
        transform="scale(1)"
        id="FR-64"
      />
      <path
        d="m 178.23408,260.22159 1.64,0.36 0.39,-1.21 1.44,0.15 0.87,-1.1 3.02,1.3 1.48,-0.22 0.35,-0.95 2.96,0.38 2.3,-2.16 -0.53,-1.92 2.56,-1.18 1.45,0.12 -0.9,1.71 0.95,-0.94 2.11,0.13 0.45,-1.48 2.34,0.26 3.1,-1.15 2.54,0.7 0.84,-0.85 1.75,0.39 -1.37,2.12 1.07,-0.2 0.82,0.99 1.13,-0.62 0,0 1.05,3.36 -0.67,-0.25 0.28,1.64 1.27,-0.52 0.93,0.74 -0.51,1.32 1.51,3.22 -1.08,1.99 2.87,1.25 -0.77,1.19 -1.19,-0.8 -1.43,1.63 2.32,0.37 -1.41,1.64 0.16,1.17 2.5,2.15 -1.7,1 0.19,0.98 -0.98,0.37 0.56,0.62 -2.51,3.31 2.84,-0.88 -0.54,1.7 1.49,-0.02 0.01,1.19 0.79,0.19 -2.05,1.39 -0.2,1.06 0.83,0.57 -0.59,1.15 -0.98,-0.15 0.08,2.4 -0.72,0.14 0.16,0.83 1.34,0.1 -0.56,4.07 2.36,1.62 -0.83,1.19 0.99,0.49 -0.91,1.1 2.57,1.26 1.96,-2.6 2.18,1.13 -1.07,1.58 0.87,1.46 -1.76,0.7 0.6,2.26 -1.27,0.99 -0.22,1.6 1.31,0.29 1.35,1.79 1.92,-0.41 -1.08,3.78 0,0 -0.98,0.85 -1.37,-1.4 -1.57,-0.05 -0.08,0.96 -1.27,-0.1 -0.14,1.13 -1.82,1.19 -2.29,-0.11 0.65,1.46 -1.52,1.79 0.81,0.41 -2.69,1.37 0,0 -1.42,-0.54 -0.07,-1.24 -1.03,-0.64 0.19,-1.6 -1.19,0.65 -0.2,-1.63 -0.83,0.6 -1.91,-0.49 -1.33,-2.1 -3.05,0.71 -0.34,-1.1 -1.22,0.59 -0.85,-0.55 0.06,-1.24 -0.85,1.05 -2.68,-0.58 -0.31,-1.5 -1.65,-0.92 -2.77,0.55 -0.67,-0.83 1.27,-0.44 -1.97,-0.53 -0.68,-1.09 -0.94,0.27 0.1,-2.23 -2.64,-1.66 0.72,-1.35 -0.59,-2.17 0,0 1.3,0.09 0.85,-1.36 2.09,0.51 0.36,-1.28 1.4,-0.42 0.18,-0.88 1.74,0.02 0.81,-1.53 -2.77,-1.74 -0.61,1.35 -0.87,-0.38 0.16,-2.06 1.2,-1.39 -1.62,-3.93 1.6,-0.31 0.25,-1.38 -1.01,-1.62 0.54,-2.78 -1.84,-1.11 0.96,-1.4 -1.77,-1.38 0.97,-0.84 -2.95,-4.09 1.19,-2.51 -2.86,-1.49 -2.65,-3.03 0.95,-2.2 -2.14,-0.81 0.22,-0.82 -1,0.12 0.61,-0.98 -1.46,-0.19 z"
        title="Deux-Sèvres"
        transform="scale(1)"
        id="FR-79"
      />
      <path
        d="m 210.99408,254.85159 0.66,-2.22 2.08,0.87 -0.17,-1.67 1.38,-2.82 1.1,-0.91 1.48,0.67 0,0 1.05,1.1 0.04,1.63 2.49,-0.36 -0.22,1.08 1.13,0.38 0.82,-0.86 0.76,1.44 -0.9,1.98 1.44,0.31 1.33,-1.07 0.91,0.25 0.05,1.51 1.44,-0.52 0.4,3.21 -0.89,0.48 1.34,3.64 1.73,-1.14 3.04,1.21 2.78,-1.78 3.85,0.1 -1.27,-2.97 2.07,-0.09 0.68,1.31 2.39,0.74 0.53,4.28 1.99,2.64 1.82,0.6 0.78,3.66 2.18,2.48 0,0 1.87,1.28 0.59,1.77 -1.44,4.11 0.75,1.7 2.2,1.8 2.11,0.26 0.22,1.85 3.04,-0.03 2.22,1.86 -0.49,0.48 0.95,1.67 -0.94,-0.09 0.66,1.37 1.54,0.46 -0.2,0.76 1.2,-0.33 -1.4,2.93 0,0 -0.86,-0.29 -1.14,2.52 -1.16,-0.99 -2.22,0.09 -1.83,4.98 -4.23,-0.54 -2.26,2.37 0.05,1.12 -1.64,-0.04 -0.7,1.1 1.78,1.99 0.46,2.49 -0.94,0.58 0,0 -0.57,-0.67 -1.04,0.57 -3.02,-0.58 -1.57,1.68 0.5,0.93 -2.85,0.2 -0.95,1.22 -2.34,-0.88 -1.97,-2.97 -2.01,2.09 0.23,0.9 1.25,0.03 0.07,1.22 -2.25,1.03 -1.52,-1.2 -4.7,0.49 -0.83,-1.22 -2.65,-0.88 0,0 1.08,-3.78 -1.92,0.41 -1.35,-1.79 -1.31,-0.29 0.22,-1.6 1.27,-0.99 -0.6,-2.26 1.76,-0.7 -0.87,-1.46 1.07,-1.58 -2.18,-1.13 -1.96,2.6 -2.57,-1.26 0.91,-1.1 -0.99,-0.49 0.83,-1.19 -2.36,-1.62 0.56,-4.07 -1.34,-0.1 -0.16,-0.83 0.72,-0.14 -0.08,-2.4 0.98,0.15 0.59,-1.15 -0.83,-0.57 0.2,-1.06 2.05,-1.39 -0.79,-0.19 -0.01,-1.19 -1.49,0.02 0.54,-1.7 -2.84,0.88 2.51,-3.31 -0.56,-0.62 0.98,-0.37 -0.19,-0.98 1.7,-1 -2.5,-2.15 -0.16,-1.17 1.41,-1.64 -2.32,-0.37 1.43,-1.63 1.19,0.8 0.77,-1.19 -2.87,-1.25 1.08,-1.99 -1.51,-3.22 0.51,-1.32 -0.93,-0.74 -1.27,0.52 -0.28,-1.64 0.67,0.25 z"
        title="Vienne"
        transform="scale(1)"
        id="FR-86"
      />
      <path
        d="m 264.16408,295.86159 0.51,0.5 0.78,-0.81 0.39,1.46 1.67,-0.75 1.86,0.41 0.4,-1.41 1.69,-0.29 2.55,3.22 0,0 1.2,0.65 -0.06,1.91 -1.21,1.33 0.99,0.57 -1.35,0.46 0.56,1.31 -1.86,1.61 1.61,2.29 1.96,-0.11 -0.24,1.6 1.24,1 0.52,-0.41 0.45,1.17 -0.94,1.04 2.3,1.53 0.42,2.69 -0.74,1.48 1.83,1.84 -2.75,2.75 2.28,1.05 0.92,-1.01 2.18,0.35 -0.66,0.55 0.83,1.49 -0.93,-0.45 -0.05,1.12 -0.2,-0.61 -0.97,0.55 0.51,1.43 2.35,1.33 2.84,-0.42 1.75,-1.48 0.06,2.04 2.24,1.36 0.36,-0.93 1.94,1.74 0.77,1.63 -1.09,0.76 -0.09,1.72 1.06,1.67 0,0 0.5,1.09 -1.17,-0.1 -0.32,0.99 -1.72,0.13 -1.92,-1.09 -0.35,1.35 -2.81,1.06 -2.99,3.52 -1.76,0.19 -0.53,1.28 -3.74,0.39 -0.62,-0.93 -1.05,0.66 -0.43,1.86 -1.44,-0.24 -2.39,3.5 -2.66,-1.36 -1.35,2.74 0,0 -0.78,0.21 0.07,-0.83 -1.12,-0.51 -1.32,0.37 -0.62,-1.25 -1.73,0.14 1.89,-2.95 -2.1,-1.3 -1.26,0.99 -2.8,-4.71 -3.01,-0.29 -2.01,0.86 -1.18,-1.28 -0.99,2.34 -1.36,0.49 -0.28,-1.21 -1.25,0.27 -0.99,-1.59 0.97,-2.94 -2.24,-1.67 -2.81,0.43 -0.96,-1.29 0,0 0.97,-1.79 2.54,-1.3 -0.13,-2.49 2.94,0.75 1.82,-5.42 -0.6,-2.93 1.99,0.89 3.37,-2.38 -0.61,-3.11 -1.76,-1.41 -1.08,0.83 -1.82,-1.91 0.85,-2.73 -1.04,-1.43 0.62,-0.44 0,0 0.94,-0.58 -0.46,-2.49 -1.78,-1.99 0.7,-1.1 1.64,0.04 -0.05,-1.12 2.26,-2.37 4.23,0.54 1.83,-4.98 2.22,-0.09 1.16,0.99 1.14,-2.52 z"
        title="Haute-Vienne"
        transform="scale(1)"
        id="FR-87"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "NouvelleAquitaine",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 90vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>