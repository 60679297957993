<template>
  <v-row v-if="!loading" class="francemap custom-card fill-height pa-0 ma-0" justify="center" align="center" no-gutters>
    <v-container fluid class="pa-0 ma-0">
      <v-row justify="center" align="center" no-gutters>
        <v-col
          cols="12"
          justify="center"
          align="start"
          class="text-h4 text-uppercase font-weight-bold flex-grow-0 flex-shrink-1 text-center pa-5"
          no-gutters
        >
          {{ `${currentDate}` }}
        </v-col>

        <v-col cols="12" v-if="loading == false" class="text-center">
          <component :is="region" :weather="weather">{{ `Météo de la région ${region_ref}` }}</component>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import HautsdeFrance from "@/components/Weather/Regions/HautsdeFrance";
import Grandest from "@/components/Weather/Regions/GrandEst";
import BourgogneFrancheComte from "@/components/Weather/Regions/BourgogneFrancheComté";
import Normandie from "@/components/Weather/Regions/Normandie";
import IledeFrance from "@/components/Weather/Regions/IledeFrance";
import Bretagne from "@/components/Weather/Regions/Bretagne";
import PaysdelaLoire from "@/components/Weather/Regions/PaysdelaLoire";
import CentreValdeLoire from "@/components/Weather/Regions/CentreValdeLoire";
import NouvelleAquitaine from "@/components/Weather/Regions/NouvelleAquitaine";
import AuvergneRhoneAlpes from "@/components/Weather/Regions/AuvergneRhoneAlpes";
import Occitanie from "@/components/Weather/Regions/Occitanie";
import ProvenceAlpesCotedAzur from "@/components/Weather/Regions/ProvenceAlpesCotedAzur";
import Corse from "@/components/Weather/Regions/Corse";

import mixins_weather from "@/utils/mixins/weather";

export default {
  name: "WeatherRegion",

  components: {
    HautsdeFrance,
    Grandest,
    BourgogneFrancheComte,
    Normandie,
    IledeFrance,
    Bretagne,
    PaysdelaLoire,
    CentreValdeLoire,
    NouvelleAquitaine,
    AuvergneRhoneAlpes,
    Occitanie,
    ProvenceAlpesCotedAzur,
    Corse,
  },
  mixins: [mixins_weather],

  data() {
    return {
      loading: true,

      currentDate: null,
      weather: null,
      region: "",
      region_ref: "",
    };
  },

  async mounted() {
    try {
      this.currentDate = this.getCurrentDate();

      this.region_ref = (await this.$axios.get("/region-label"))?.data?.region;

      this.region = this.replaceAll(
        this.replaceAll(this.replaceAll(this.replaceAll(this.replaceAll(this.region_ref, " ", ""), "-", ""), "é", "e"), "ô", "o"),
        "'",
        ""
      );
      this.weather = (await this.$axios.get("/region-weather"))?.data;
    } catch (error) {
      console.error(error);
      this.$emit("error", error);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, "g"), replace);
    },
  },
};
</script>

<style lang="css" scoped>
.badge {
  border-radius: 55px;
  background-color: #80808082;
}

.absolute {
  position: absolute;
}

.custom-card {
  background-color: transparent;
  background-image: linear-gradient(#297af9, #333fb7);
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  /* border-radius: 10px; */
  box-shadow: rgba(0, 0, 0, 0.06) 0 3px 20px 0;
  min-height: 60%;
}
</style>
