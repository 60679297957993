<template>
  <v-container ref="container-svg" id="container-svg" class="no-overflow pa-0" style="position: relative">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      :viewBox="`${svg_viewBox.x} ${svg_viewBox.y} ${svg_viewBox.width} ${svg_viewBox.height}`"
    >
      <path
        v-for="path in weather_paths"
        :key="path.id"
        :d="path.d"
        :title="path.title"
        :ref="path.title"
        :id="path.id"
        transform="scale(1.2)"
        :class="{ highlight: weather[path.title] }"
      />
      <g v-for="city in extra_cities" :key="city.label">
        <circle :cx="city.dot.x" :cy="city.dot.y" r="1" stroke="black" stroke-width="0.2" fill="#f8703d" />
        <text
          :x="city.text.x"
          :y="city.text.y"
          font-size="0.24rem"
          font-weight="bold"
          fill="#f0f0f0"
          :id="`text_${city.text.label}`"
        >
          {{ city.text.label }}
        </text>
      </g>
    </svg>
    <div
      v-for="depart in weather_icon_departement"
      :key="depart.id"
      :style="{ position: 'absolute', top: depart.y + 'px', left: depart.x + 'px' }"
    >
      <span class="font-weight-medium black--text temperature-shadow">{{ depart.temperature }}</span>
      <v-img
        :src="depart.src"
        :min-width="depart.width"
        :min-height="depart.height"
        :max-width="depart.width"
        :max-height="depart.height"
        class="w-heartbeat"
        contain
        alt
      />
    </div>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//   <div class="no-overflow">
//     <svg
//       xmlns:mapsvg="http://mapsvg.com"
//       xmlns:dc="http://purl.org/dc/elements/1.1/"
//       xmlns:cc="http://creativecommons.org/ns#"
//       xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
//       xmlns:svg="http://www.w3.org/2000/svg"
//       xmlns="http://www.w3.org/2000/svg"
//       version="1.1"
//       id="svg2"
//       preserveAspectRatio="xMidYMid meet"
//       viewBox="0 86 200 200"
//       style="transform: scale(1.5);"
//     >
//       <path
//         d="m 91.074077,143.57159 -0.83,1.71 -0.08,-1.33 0.91,-0.38 z m -9.69,-0.23 0.35,2.02 5.31,-1.91 -0.46,2.25 1.21,-0.18 -0.63,0.84 3.06,-0.19 0.32,1.47 -1.74,0.92 2.78,1.44 1.16,-0.61 0.76,0.88 -0.51,2.32 5.32,4.52 -0.2,3.35 4.490003,2.47 -0.08,2.28 1.26,1.32 0.14,-2.14 2.11,0.39 3.21,-4.6 3.36,-1.43 -0.62,-1.41 2.13,-0.48 0.79,1.27 4.14,-3.52 1.32,1.58 -2.09,2.71 0.96,0.63 2.93,-2.15 -0.51,0.53 1.87,3.06 -0.31,1.34 0.9,-2.83 0.7,2.17 1.19,-2.62 1.04,1.14 0,0 1.32,1.86 1.96,0.34 0.07,1.41 1.54,-1.56 0,0 1.46,1.45 -0.46,2.79 1.35,-2.44 0,0 0.88,-0.74 0.54,0.88 0.29,3.37 -1.74,2.43 0.98,5.94 -1.66,0.29 0.44,3.16 -0.7,-0.6 -2.02,1.28 -1.28,-1.03 -2.38,1.44 0.68,0.54 -0.48,0.89 -1.88,-0.61 -1.32,0.73 0.04,2.42 -1.89,-0.04 -0.29,4.16 -1.46,-0.43 -0.67,1.02 0,0 -2.57,0.53 -0.35,0.92 -2.77,-3.88 -4.61,1.01 0.88,1.5 -1.41,3.54 -4.49,2.74 -0.69,-1.92 0.96,-2.16 -0.43,-2.03 -1.8,1.11 -2.11,-0.23 -0.8,1.83 -1.270003,-3.59 -1.93,0.19 -1.67,-1.43 -2.13,-0.4 -2.38,0.68 -0.64,-2.33 -0.92,-0.37 -3.23,-0.19 -1.1,2.69 -2.15,-0.04 -0.91,1.4 -1.14,-0.56 -1.28,0.7 -0.73,-0.89 -1.47,0.24 -0.45,-1.28 -3.38,1.55 -0.87,-0.86 0.79,-0.84 -1.08,-0.91 -1.02,0.7 -3.96,-0.62 0,0 1.91,-0.26 -1.59,-2.1 1.61,-3.56 -1.43,-0.96 1.45,-1.25 -1.39,0.09 -0.15,-3.91 -2.07,-0.64 0.14,-1.98 2.12,-1.64 -2.31,-1.95 0.08,-1.13 2.73,-2.6 -1.37,-1.29 -0.98,0.22 0.27,-1.7 -2.17,-0.81 -0.74,-3.97 0,0 1.47,-1.72 0.88,1.08 1.42,-0.87 -0.65,-2.62 1.45,-1.35 -1.48,-0.71 0,-2.07 0.73,-0.33 -0.83,0.04 0.32,-0.75 1.03,0.92 0.81,-0.65 -0.25,-1.31 1.53,-1.04 -0.07,0.91 0.97,-0.79 0.98,1.37 1.29,-0.33 -0.53,1.43 1.77,-0.13 0.37,-0.97 2.87,-1.42 2.36,0.25 1.58,-2.08 z"
//         title="Côtes-d'Armor"
//         id="FR-22"
//       />
//       <path
//         d="m 2.9140771,168.75159 1.57,1.49 -2.76,1.41 -0.72,-0.11 0.88,-1.1 -1.73000003,0.47 2.76000003,-2.16 z m 46.9999999,-16.42 0.76,0.16 -0.2,2.77 1.12,0.83 0.79,-0.41 -0.1,-1.72 0.59,1.8 2.32,-0.25 0.37,0.85 -0.37,-1.82 0.98,-1.57 0.45,0.86 -0.1,-1.16 1.56,1.18 2.62,-0.33 0.97,1.05 2.28,-0.43 -0.61,2.33 0,0 0.74,3.97 2.17,0.81 -0.27,1.7 0.98,-0.22 1.37,1.29 -2.73,2.6 -0.08,1.13 2.31,1.95 -2.12,1.64 -0.12,1.99 2.06,0.63 0.15,3.91 1.39,-0.09 -1.45,1.25 1.43,0.96 -1.61,3.56 1.59,2.1 -1.91,0.26 0,0 -6.21,2.77 -0.83,1.91 0.07,0.96 1.55,0.12 -0.55,1.66 1.45,0.5 1.44,4.62 4.48,-0.25 1.69,0.85 0.96,1.82 2.1,-1.75 1.04,0.99 0.22,1.98 -1.35,1.29 0.85,2.6 -2.06,-0.04 -0.36,1.67 -1.38,0.8 -1.56,-1.35 -0.25,5.07 0,0 -6.17,-0.86 -1.85,-1.73 -1.43,0.9 -3.85,-0.02 -2.68,-4.06 0.48,-0.48 -1.98,-1.88 -1.4,0.7 0.6,1.78 -2.74,0.61 -2.7,-1.66 -1.69,0.91 -1.86,3.72 -7.31,0.01 -0.54,-1.5 1.23,-0.5 0.12,-1.42 -3.03,-6.84 -3.94,-2.7 -1.36,-0.34 -0.67,0.73 -2.79,-1.78 -2.69,0.17 -1.64,-0.81 1.25,-0.28 -0.32,-1.3 6.73,-0.72 0.69,-0.87 2.06,0.21 2.89,-1.25 2.24,-0.15 2.38,1.25 0.66,-0.71 0.67,-3.32 -0.94,-0.32 -0.82,-2.77 -7.18,-1.99 -2.13,4.12 -0.78,-0.04 0.27,-2.45 -0.83,-1.53 1.2,-0.91 -3.16,-0.93 -0.37,-0.97 2.43,-0.16 -0.07,-3.02 1.63,-0.64 -1.02,2.37 0.85,1.16 0.7,-0.72 0.66,0.92 3.48,-0.83 0.85,1.21 3.78,-1.44 1.48,0.11 0.44,1 1.62,-2.09 -1.13,0.7 -3.67,-0.78 1.45,-1.07 -1.1,-1.43 -1.59,0.63 -0.28,1.1 -1.77,0.16 0.39,-0.9 -2.1,0.78 2.41,-3.53 -0.98,-0.78 -0.53,0.89 -1.97,-0.05 -5.13,2.74 -3.06,-1.06 -1.1,1.49 -2.71,0.13 -0.63,-2.31 1.1,-0.75 -1.4,-2.18 1.42,-3.36 -0.64,-2.96 1.03,-1.8 1.66,-0.64 0.33,-1.01 3.87,-0.41 0.27,-1.63 1.67,0.29 -0.22,-1.41 1.14,-0.23 -0.02,-0.81 2.69,0.83 1.54,-1.11 1.41,0.18 -1.25,-1 3.79,-1.42 2.04,0.79 -0.66,1.44 0.67,0.5 0.9,-0.98 3.13,-0.2 -0.99,-0.38 1.5,-1.77 3.59,-0.53 1.69,1.2 0.25,-1.86 2.91,-1.19 z m -1.52,-1.83 1.25,0.92 -1.94,-0.14 0.69,-0.78 z"
//         title="Finistère"
//         id="FR-29"
//       />
//       <path
//         d="m 127.57408,157.44159 0.65,0.66 2.04,-0.37 -0.32,0.8 0.91,-0.06 0.08,1.45 1.41,1.28 -0.44,1.02 0,0 -1.54,1.56 -0.07,-1.41 -1.96,-0.34 -1.32,-1.86 0,0 -1.11,-2.1 1.67,-0.63 z m 11.13,-3.92 0.35,1.66 -1.52,2.1 1.49,2.04 5.63,0.34 5.33,-1.66 0,0 3.46,9.09 1.58,0.12 1.21,1.64 2.8,-0.5 1.05,-1.87 2.05,-0.66 1.05,-2.15 7.63,2.23 0,0 -0.46,5.85 1.49,5.35 -2.35,4.09 2.13,10.7 1.22,1.36 -0.5,2.28 0.7,1.78 -1.38,1.17 -3.08,-0.14 -0.07,0.95 -1.22,0.39 -0.89,4.12 -0.82,0.48 0.37,1.4 -1.43,0.99 0.07,1.95 -0.75,0.63 0,0 -0.36,2.05 0,0 -4.84,-1.39 -1.03,-1.79 -3.76,-0.44 0.24,1.99 -6.19,2.43 -1.49,3.29 -1.49,-0.17 -1.15,0.93 -5.75,-0.67 -3.94,2.29 -0.76,-1.38 -5.19,3.85 0,0 -1.29,-5.65 0.76,-0.36 0,0 0.17,-0.5 0,0 2.05,-0.11 -0.15,-0.79 -2.74,-1.18 0,0 0.63,0 0,0 3.2,-3.87 -0.77,-2.19 -1.41,1.6 -0.93,-0.33 0.58,-0.81 -0.79,-1.8 1.53,-1.78 -2.75,-3.97 -1.22,0.28 -2.64,-1.25 -2.13,0.53 1.05,-3.01 0.82,-0.83 2.27,0.02 0.85,-1.44 -2.15,-0.33 -1.47,1.11 0.07,-2.73 -1.38,-1.69 0,0 0.67,-1.02 1.46,0.43 0.29,-4.16 1.89,0.04 -0.04,-2.42 1.32,-0.73 1.88,0.61 0.48,-0.89 -0.68,-0.54 2.38,-1.44 1.28,1.03 2.02,-1.28 0.7,0.6 -0.44,-3.16 1.66,-0.29 -0.98,-5.94 1.74,-2.43 -0.29,-3.37 -0.54,-0.88 -0.88,0.74 0,0 -0.58,0.14 -0.89,-2.99 1.38,0.32 -2.45,-1.54 0.44,-0.71 -1.29,-1.21 0.1,-1.05 1.68,-0.85 0.1,-1.27 1.71,0.13 -0.53,-0.41 1.01,-0.82 1.23,0.78 2.58,-1.06 z"
//         title="Ille-et-Vilaine"
//         id="FR-35"
//       />
//       <path
//         d="m 80.424077,235.16159 3.75,1.43 0.73,2.13 3.24,0.92 -0.56,1.7 -0.73,0.52 -3.41,-1.45 -1.82,0.54 -0.82,-0.61 -1.01,-2.95 0.63,-2.23 z m -13.17,-49.25 3.96,0.62 1.02,-0.7 1.08,0.91 -0.79,0.84 0.87,0.86 3.38,-1.55 0.45,1.28 1.47,-0.24 0.73,0.89 1.28,-0.7 1.14,0.56 0.91,-1.4 2.15,0.04 1.03,-2.66 3.23,0.19 0.92,0.37 0.64,2.33 2.38,-0.68 2.13,0.4 1.67,1.43 1.93,-0.19 1.270003,3.59 0.8,-1.83 2.11,0.23 1.8,-1.11 0.43,2.03 -0.96,2.16 0.69,1.92 4.49,-2.74 1.41,-3.54 -0.88,-1.5 4.61,-1.01 2.77,3.88 0.35,-0.92 2.57,-0.53 0,0 1.38,1.69 -0.07,2.73 1.47,-1.11 2.15,0.33 -0.85,1.44 -2.27,-0.02 -0.82,0.83 -1.05,3.01 2.13,-0.53 2.64,1.25 1.22,-0.28 2.75,3.97 -1.53,1.78 0.79,1.8 -0.58,0.81 0.93,0.33 1.41,-1.6 0.77,2.19 -3.2,3.87 0,0 -0.63,0 0,0 2.74,1.18 0.15,0.79 -2.05,0.11 0,0 -0.17,0.5 0,0 -0.76,0.36 1.29,5.65 0,0 0.69,1.22 -0.8,1.06 0.11,3.76 -2.46,0.69 -0.26,1.95 -1.06,-0.32 0.17,-1.08 -2.49,1.11 -0.59,-1.19 -1.63,-0.13 -0.78,3.38 -3.5,0.29 -0.9,-1.27 -1.43,1.77 0,0 -1.56,-0.12 -0.26,-2.58 2.52,-0.38 -2.85,-1.59 -4.67,1.23 -0.36,-0.94 -1.1,0.08 -1,1.29 -1.87,-0.54 -3.710003,1.21 -1.43,-0.73 -0.99,-2.13 -1.29,-0.1 -0.27,-1.13 -2.2,-0.21 -1.05,-1.41 -0.63,0.81 -0.84,-1.58 0.04,1.2 -4.13,0.2 -0.46,2.3 1.83,3.61 -1.69,0 -1.18,-3.07 0.86,-1.21 -0.81,-3.91 -5.1,-4.73 -2.41,-0.73 -0.9,0.58 -0.2,-1.52 -3.96,0.74 -3.04,-3.97 0,0 0.25,-5.07 1.56,1.35 1.38,-0.8 0.36,-1.67 2.06,0.04 -0.85,-2.6 1.35,-1.29 -0.22,-1.98 -1.04,-0.99 -2.1,1.75 -0.96,-1.82 -1.69,-0.85 -4.48,0.25 -1.44,-4.62 -1.45,-0.5 0.56,-1.65 -1.55,-0.12 -0.07,-0.96 0.83,-1.91 6.26,-2.78 z"
//         title="Morbihan"
//         id="FR-56"
//       />
//     </svg>
//     <v-row justify="start" no-gutters>
//       <v-col class="text-left text-body-1 font-italic">
//         <slot></slot>
//       </v-col>
//     </v-row>
//   </div>
// </template>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";
import weather_paths from "@/utils/weather_paths";

export default {
  name: "Bretagne",

  mixins: [mixins_weather],

  props: {
    ...weather_props,
  },

  data() {
    return {
      svg_viewBox: {
        x: -40,
        y: 115,
        width: 300,
        height: 200,
      },

      weather_icon_departement: [],
      weather_paths: weather_paths,

      extra_cities: [
        {
          dot: {
            x: 158,
            y: 202.5,
          },
          text: {
            x: 150,
            y: 200,
            label: "Dinan",
          },
        },
        {
          dot: {
            x: 86,
            y: 184,
          },
          text: {
            x: 81,
            y: 181.5,
            label: "Lannion",
          },
        },
        {
          dot: {
            x: 33.5,
            y: 208,
          },
          text: {
            x: 29,
            y: 205.5,
            label: "Brest",
          },
        },
        {
          dot: {
            x: 54,
            y: 236,
          },
          text: {
            x: 47,
            y: 233.5,
            label: "Quimper",
          },
        },
        {
          dot: {
            x: 175,
            y: 230,
          },
          text: {
            x: 166,
            y: 227.5,
            label: "Rennes",
          },
        },
        {
          dot: {
            x: 98,
            y: 196,
          },
          text: {
            x: 89,
            y: 193.5,
            label: "Guingamp",
          },
        },
        {
          dot: {
            x: 120,
            y: 223,
          },
          text: {
            x: 113,
            y: 220.5,
            label: "Loudéac",
          },
        },
        {
          dot: {
            x: 90,
            y: 257,
          },
          text: {
            x: 83,
            y: 254.5,
            label: "Loudéac",
          },
        },
        {
          dot: {
            x: 115,
            y: 269,
          },
          text: {
            x: 109,
            y: 266.5,
            label: "Vannes",
          },
        },
        {
          dot: {
            x: 154.5,
            y: 261,
          },
          text: {
            x: 149,
            y: 258.5,
            label: "Redon",
          },
        },
      ],
      icon_size: 10,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  },

  mounted() {
    setTimeout(() => {
      this.place_weather(this.$refs);
    }, 202);
    setTimeout(() => {
      window.addEventListener("resize", this.onResize);
      window.addEventListener("orientationchange", this.onResize);
    }, 205);
  },

  methods: {
    onResize() {
      this.place_weather(this.$refs);
    },
  },

  watch: {
    weather: {
      deep: true,
      handler: function(val, oldVal) {
        this.place_weather(this.$refs);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
path {
  fill: #618a1c;
  stroke-width: 1px;
  stroke: #618a1c;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}

.temperature-shadow {
  text-shadow: 2px 0 0 white;
}

.highlight {
  fill: #93d02d;
  stroke-width: 0.112417px;
  stroke: #74a425;
  pointer-events: all;
}
</style>
