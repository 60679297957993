<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="345 50 220 200"
      style="transform: scale(1);"
    >
      <path
        d="m 415.99408,61.321588 1.71,0.09 0.81,1.04 -1.19,1.1 0.18,2.05 -0.87,-0.88 -0.54,2.22 -0.75,0.14 0.88,1.74 -2.14,4.46 0.06,0.68 2.34,0.64 1.7,2.49 -1.71,2.75 1.15,3.02 -0.96,1.71 2.26,0.47 3.71,-0.92 0.55,1.15 3.56,1.24 1.38,3.16 1.44,-0.14 0.17,1.43 4.33,-0.16 2.54,2.68 -1.05,1.56 0.32,1.17 1.41,-1.26 1.99,0.92 0,0 -0.74,1.5 -1.68,-0.05 -1.06,2.19 -2.06,1.130002 -1.14,-1.760002 -2.96,0.41 -0.84,-1.77 -1.28,-0.1 0.01,2.02 -0.94,1.160002 0,0 0,0.48 0,0 -1.29,2.18 2.16,2.72 0.17,2.45 -1.59,1.07 1,1.55 -1.92,0.63 -1.13,2.09 -0.11,1.62 1.41,1.29 -0.35,0.98 -4.13,2.35 0,0 -1.25,-0.92 0.11,-0.92 -1.94,0.21 -0.45,2.23 -2.1,-1.41 -2.56,0.57 -2.64,-1.06 -2.33,1.64 -1.76,-2.62 0.26,-1.17 -6.28,1.19 -2.08,-1.17 -0.69,-2.11 -1.51,0.69 -0.26,-0.78 -1.22,-0.01 -2.52,-3.62 -1.92,-0.03 -0.41,-1.12 -5.94,-0.32 0,0 -0.42,-2.04 1.27,-0.46 -1.09,-1.41 -0.04,-2.5 1.58,-1.13 -0.14,-1.11 -1.09,-0.19 1.17,-1.680002 -2.26,-3.25 0.73,-1.01 3.07,0.3 0.47,-2.88 2.44,-1.34 2.69,-3.72 -1.82,-1.47 1.96,-5.67 0.1,-2.29 -1.58,-0.63 0.6,-2.9 0,0 3.23,-0.67 5.66,2.04 2.72,-0.63 2.63,-2.18 4.64,-1.11 0.51,-3.38 -0.81,-0.99 0.92,-1.86 2,-1.05 0.52,-1.61 2.55,-2.07 z"
        title="Ardennes"
        id="FR-08"
      />
      <path
        d="m 362.99408,159.08159 1,1.99 1.82,0.5 0.54,2.45 4.07,-0.02 3.64,1.47 1.65,-1.42 -0.55,-2.67 2.01,0.36 0.36,-1.68 1.66,-0.11 2.2,-3.8 2.36,-0.17 0.91,-2.19 2.14,0.94 1.38,-1.34 2.83,0.37 0.39,-0.91 0.84,0.66 1.53,-0.53 1.4,2.27 -0.69,3.96 3.53,3.38 3.85,1.44 0.65,-0.59 1.41,1.39 1.97,-1.74 2.12,-0.05 -0.37,0.62 1.46,0.8 0,0 -0.67,3.81 -1.03,0.55 2.06,1.31 1.16,3.09 2.24,0.19 -0.22,1.32 3.54,1.53 -1.1,1.18 2,3.53 -1.07,2.87 1.17,1.31 -1.12,1.91 0.6,1.56 -0.9,0.83 0.21,1.5 -4.3,-0.75 -1.06,1.74 -1.99,0.63 2.71,2.09 -0.74,1.51 0,0 -1.44,0.44 -3.54,-1.04 -2.03,1.5 0.77,2.38 -9.97,0.42 -0.9,2.25 0,0 -2,-0.32 -0.94,-1.18 0.18,-1.41 -1.31,0.29 0.74,0.34 -0.43,1.26 -1.46,-1.12 0,0 -0.8,0.73 0,0 -1.57,1.35 -0.85,-1.17 -1.23,-0.05 -0.64,1.27 -1.67,-1.07 -0.76,0.75 -3.79,0.07 0.77,-3.12 -0.78,-1.35 -0.6,1.41 -1.18,-0.26 -0.52,-1.29 1.31,0.09 0.05,-0.78 -2.06,-1.8 -1.29,-4.57 -2.14,-0.91 0.36,-2.07 -1.41,-0.52 0.35,1.12 -2.31,1 -1.53,-3.1 -2.14,0.33 1.76,-2.55 -0.86,-0.43 0.76,-2.25 -1.73,-0.95 0.41,-1.03 -1.81,-1.19 -1.66,-2.92 -1.55,-0.61 -1.59,1.1 -0.58,-1.97 0,0 0.31,-1.6 -1.27,-0.57 0.59,-1.79 -0.94,-1.65 2.26,-0.67 -1.3,-2.34 3.09,-0.86 -0.67,-1.8 2.04,-1.21 -0.51,-0.93 z"
        title="Aube"
        id="FR-10"
      />
      <path
        d="m 359.91408,144.33159 1.78,-3.77 1.76,-0.16 0.14,-1.56 1.22,-0.22 -0.54,-1.09 1.28,-0.38 0.85,-2.39 1.51,-0.63 -1.12,-1.83 -2.72,0.39 0.07,-1.47 2.13,-1.07 -1.55,-2.68 0.89,-1.95 2.66,-0.23 0.78,0.75 1.8,-1.05 0.03,-1.41 -1.42,0.27 -0.66,-1.9 -0.69,0.62 -1.18,-1 1.01,-1.39 -1.08,-1.22 0.56,-0.94 -0.85,-2.84 5.51,-2.72 3.05,0.73 0.18,-2.25 2.83,-1.69 1.49,1.87 3.2,1.09 0.48,-2.93 0,0 5.94,0.32 0.41,1.12 1.92,0.03 2.52,3.62 1.22,0.01 0.26,0.78 1.51,-0.69 0.69,2.11 2.08,1.17 6.28,-1.19 -0.26,1.17 1.76,2.62 2.33,-1.64 2.64,1.06 2.56,-0.57 2.1,1.41 0.45,-2.23 1.94,-0.21 -0.11,0.92 1.25,0.92 0,0 1.69,1.71 -2.18,1.6 2.32,4.61 0.42,3.34 -1.66,2.38 1.8,-0.89 1.15,0.76 -0.09,1.92 -1.65,0.59 1.87,0.63 -0.96,2.18 -3.24,1.34 0,0 -0.18,0.35 0,0 -0.87,0.86 -0.02,1.92 1.04,1.62 -1.99,1.57 0.05,1.19 1.94,0.55 1.85,2.84 1.17,0.17 0.22,1.99 -0.73,0.05 -0.38,1.55 0,0 -1.61,-0.29 -0.5,0.9 -1.15,-0.87 -1.79,1.36 -2.09,-0.82 -1.49,0.63 -0.37,1.13 2.86,0.21 0.53,0.89 0,1.4 -3.76,1.11 0.55,1.82 0.82,-0.16 -0.56,0.84 0.66,1.6 -3.38,-0.79 -1.99,0.66 0,0 -1.46,-0.8 0.37,-0.62 -2.12,0.05 -1.97,1.74 -1.41,-1.39 -0.65,0.59 -3.85,-1.44 -3.53,-3.38 0.69,-3.96 -1.4,-2.27 -1.53,0.53 -0.84,-0.66 -0.39,0.91 -2.83,-0.37 -1.38,1.34 -2.14,-0.94 -0.91,2.19 -2.36,0.17 -2.2,3.8 -1.66,0.11 -0.36,1.68 -2.01,-0.36 0.55,2.67 -1.65,1.42 -3.64,-1.47 -4.07,0.02 -0.54,-2.45 -1.82,-0.5 -1,-1.99 0,0 -1.63,-1.02 0.58,-0.84 -3.45,0.7 0.35,-1.09 -0.81,-0.7 1.52,-2.16 -0.35,-2.49 -3.06,-1.27 0.6,-1.6 1.43,-0.4 -0.08,-0.8 -1.58,-0.35 3.48,-0.33 z"
        title="Marne"
        id="FR-51"
      />
      <path
        d="m 409.10408,164.46159 1.99,-0.66 3.38,0.79 -0.66,-1.6 0.56,-0.84 -0.82,0.16 -0.55,-1.82 3.76,-1.11 0,-1.4 -0.53,-0.89 -2.86,-0.21 0.37,-1.13 1.49,-0.63 2.09,0.82 1.79,-1.36 1.15,0.87 0.5,-0.9 1.61,0.29 0,0 0.89,0.39 -0.69,1.2 0.33,2.85 1.31,0.02 1.02,-1.18 0.58,2.28 1.99,0.03 1.12,2.21 0.84,-0.28 1.89,2.27 4.82,1.17 0.76,1.66 2.14,0.59 0.45,1.82 2.48,1.47 0,0 -3.14,1.84 2.01,0.74 -1.02,1.39 0.39,1.6 2.18,-1.55 1.88,0.52 2.67,4.6 0.94,-1.11 1.24,1 0,2.12 1.91,0.48 1.83,2.46 -2.11,0.95 0.15,1.96 -1.13,1.67 0.57,0.78 -1.77,1.42 1.21,0.97 -0.03,-1 1.67,0.57 1.06,2.03 0.84,-0.36 1.43,1 0.77,2.28 -0.32,2.72 0.92,0.27 1.55,-1.7 1.57,3.07 0,0 -0.02,1.59 -1.4,-0.39 -1.19,2.18 0.25,1.16 -2.79,-0.45 -0.79,2.43 -2.1,-0.05 -0.62,2.57 1.23,0.7 0.13,1.29 -1.01,1.5 0.26,2.32 -3.9,0.77 -1.22,-2.17 -1.74,2.12 -1.77,-0.67 -0.86,0.74 -2.15,-0.16 -1.58,4.29 0,0 -0.71,0.86 -0.71,-1.16 -1.3,0.03 -2.28,2.01 -0.56,-1.2 0.88,-1.59 -0.87,0.36 -2.69,-2.2 0.21,-1.73 -2.53,2.08 -1.57,-1.53 -1.08,0.19 0.3,-1.66 -1.32,0.18 -0.03,-1.12 -2.5,1.5 -0.4,-4.68 -1.8,-0.32 3.05,-2.25 0.19,-0.99 -1.39,-1.2 -0.27,-1.7 -1.12,-0.3 -0.98,-3.07 -1.4,0.21 -0.37,1.32 -1.2,-0.77 1.4,-1.95 -0.82,-1.25 -2.53,-0.11 1.02,-1.62 -0.67,-1.07 -2.3,0.07 -1.36,-1 0,0 0.74,-1.51 -2.71,-2.09 1.99,-0.63 1.06,-1.74 4.3,0.75 -0.21,-1.5 0.9,-0.83 -0.6,-1.56 1.12,-1.91 -1.17,-1.31 1.07,-2.87 -2,-3.53 1.1,-1.18 -3.54,-1.53 0.22,-1.32 -2.24,-0.19 -1.16,-3.09 -2.06,-1.31 1.03,-0.55 z"
        title="Haute-Marne"
        id="FR-52"
      />
      <path
        d="m 455.27408,99.561588 1.5,1.380002 0.82,-0.35 0,1.48 2.14,1.38 0,0 0.38,1.14 1.27,-0.3 0.6,1.14 -0.01,2.3 -1.34,1.47 0.93,0.72 -0.34,1.48 2.19,1.78 -1.35,0.21 0.07,1.01 1.6,1.3 0.8,-0.36 0.05,3.41 1.47,1.07 -2.67,2.91 2.3,1.79 -2.09,1.35 0.91,2.06 -2.99,0.21 -0.3,1.08 1.39,2.81 2.38,0.69 1.21,1.49 -0.1,2.25 1.27,-0.27 0.37,1.18 1.43,-0.34 0.67,2.09 1.67,-0.56 5.74,1.42 0.67,1.31 -1.74,3.4 2.28,0.79 -1.6,0.61 2.25,0.76 0.1,2.05 1.54,0.28 1.57,-0.91 0.87,1.48 1.4,-0.07 1.06,1.06 2.06,-0.4 1.51,2.58 2.63,0.59 0.21,2.39 0.78,-1.09 1.94,1.4 1.05,-0.44 0.95,1.7 2.21,-0.07 0.79,1.39 2.39,0.08 0.78,-0.95 0.96,1.61 2.38,-0.07 -0.7,1.1 1.72,0.86 0.73,1.74 1.28,0.42 -1.08,0.47 0.79,0.14 0,0 0.13,1.42 1.6,-0.07 0,0 -5.79,2.18 -3.6,3.81 -1.17,-1.2 -1.45,1.24 0,0 -0.42,0.81 0,0 -1.5,0.99 0.23,-0.84 -0.89,0.17 -0.08,-0.73 -4.96,-1.09 -0.98,-2.79 -2.24,1.99 0.5,1.55 -1.89,-0.97 -6.01,2.51 -0.29,-1.46 -2.18,0.63 -1.41,-1.87 -2.58,2.26 -3.43,-0.66 0.05,1.7 -1.47,1.75 -1.02,-0.55 -0.18,-1.33 -0.67,1.49 -2.85,-0.24 -1.7,0.86 -0.49,-2.88 -1.89,-0.26 1.12,-2.08 -1.39,-0.15 -0.15,1.97 -1.59,-0.33 1.71,-2.22 -0.5,-1.49 0.83,-1.58 -1.98,-0.96 -2.93,1.71 -0.92,-0.88 0,0 0.49,-2.71 -2.5,-1.29 -0.03,-1.74 3.14,-1.2 0.47,-1.07 -1.84,0.21 -0.66,-1.92 0.97,-3.05 -2.07,-2.78 1.13,-0.45 -0.24,-1.13 1.74,-1.56 -0.94,-0.33 1.04,-1.71 -0.69,-2.82 1.03,-0.89 -1.78,-1.94 2.18,-1.86 2.07,-0.35 -0.55,-1.4 -1.47,-0.74 2.2,-3.52 -0.73,-1.47 -1.28,0.1 0.66,-2.75 -2.3,0.12 -0.79,-1.23 1.05,-0.87 -1.17,-1.16 0.87,-2.56 -1.68,0.09 -0.24,-1.27 0.88,-0.89 -0.73,-2.22 1.64,-0.89 0.38,-1.76 -0.32,-0.61 -1.24,0.38 0,0 -0.19,-0.71 0,0 0.4,-2.07 -1.13,-0.61 -0.17,-1.25 1.17,-0.94 -1.83,-0.26 0.01,-0.9 -2.43,-1.4 -0.76,1.09 -4.87,0.88 -0.56,1.52 -1.41,-1.12 2.35,-1.28 -1.4,-0.35 0.61,-1.33 -1.07,-0.33 0.19,-1.57 -1.5,-0.63 0.52,-1.03 1.25,1.14 -0.7,0.31 0.71,-0.15 0.01,-0.94 0,0 3.46,-2.04 2.28,1.45 1.28,-2.73 4.3,0.72 0.52,-1.420002 z"
        title="Meurthe-et-Moselle"
        id="FR-54"
      />
      <path
        d="m 440.43408,96.951588 1.31,2.04 0.49,2.560002 -0.7,0.58 0.95,1.3 0,0 -0.01,0.94 -0.71,0.15 0.7,-0.31 -1.25,-1.14 -0.52,1.03 1.5,0.63 -0.19,1.57 1.07,0.33 -0.61,1.33 1.4,0.35 -2.35,1.28 1.41,1.12 0.56,-1.52 4.87,-0.88 0.76,-1.09 2.43,1.4 -0.01,0.9 1.83,0.26 -1.17,0.94 0.17,1.25 1.13,0.61 -0.4,2.07 0,0 0.19,0.71 0,0 1.24,-0.38 0.32,0.61 -0.38,1.76 -1.64,0.89 0.73,2.22 -0.88,0.89 0.24,1.27 1.68,-0.09 -0.87,2.56 1.17,1.16 -1.05,0.87 0.79,1.23 2.3,-0.12 -0.66,2.75 1.28,-0.1 0.73,1.47 -2.2,3.52 1.47,0.74 0.55,1.4 -2.07,0.35 -2.18,1.86 1.78,1.94 -1.03,0.89 0.69,2.82 -1.04,1.71 0.94,0.33 -1.74,1.56 0.24,1.13 -1.13,0.45 2.07,2.78 -0.97,3.05 0.66,1.92 1.84,-0.21 -0.47,1.07 -3.14,1.2 0.03,1.74 2.5,1.29 -0.49,2.71 0,0 -1.94,2.33 -3.17,-0.79 0.51,0.78 -1.52,1.08 0.15,1.03 -1.19,-1.05 -3.09,0.68 -0.5,1.41 -1.54,-0.73 0,0 -2.48,-1.47 -0.45,-1.82 -2.14,-0.59 -0.76,-1.66 -4.82,-1.17 -1.89,-2.27 -0.84,0.28 -1.12,-2.21 -1.99,-0.03 -0.58,-2.28 -1.02,1.18 -1.31,-0.02 -0.33,-2.85 0.69,-1.2 -0.89,-0.39 0,0 0.38,-1.55 0.73,-0.05 -0.22,-1.99 -1.17,-0.17 -1.85,-2.84 -1.94,-0.55 -0.05,-1.19 1.99,-1.57 -1.04,-1.62 0.02,-1.92 0.87,-0.86 0,0 0.18,-0.35 0,0 3.24,-1.34 0.96,-2.18 -1.87,-0.63 1.65,-0.59 0.09,-1.92 -1.15,-0.76 -1.8,0.89 1.66,-2.38 -0.42,-3.34 -2.32,-4.61 2.18,-1.6 -1.69,-1.71 0,0 4.13,-2.35 0.35,-0.98 -1.41,-1.29 0.11,-1.62 1.13,-2.09 1.92,-0.63 -1,-1.55 1.59,-1.07 -0.17,-2.45 -2.16,-2.72 1.29,-2.18 0,0 0,-0.48 0,0 0.94,-1.160002 -0.01,-2.02 1.28,0.1 0.84,1.77 2.96,-0.41 1.14,1.760002 2.06,-1.130002 1.06,-2.19 1.68,0.05 0.74,-1.5 0,0 z"
        title="Meuse"
        id="FR-55"
      />
      <path
        d="m 474.57408,102.71159 4.91,3.16 0.11,-0.7 1.17,0.41 1.3,-0.79 4.8,2.84 0.61,0.77 -0.72,1.13 2.54,2.33 -1.4,1.18 1.95,2.89 2.32,1.37 -0.38,1.34 1.19,0.58 0.27,2.14 1.17,-0.15 -0.5,1.96 1.12,1.46 1.71,-0.28 2.31,0.98 1.1,-1.75 -0.99,-2.02 0.92,-0.64 1.35,0.86 1.89,-0.86 0.89,1.27 0.55,-0.44 2.51,1.21 0.72,4.86 1.49,-0.99 -0.28,-1.17 0.77,-0.47 0.2,1.05 0.89,-0.18 1.31,1.37 3.69,-0.6 1.99,0.95 1.34,-1.81 1.51,0.02 0.1,-1.78 3.31,-0.77 -0.37,1.2 2.42,-0.27 0.25,2.39 1.49,2.38 3.97,1.27 0.23,1.22 0,0 -2.42,6.09 -1.73,1.55 -3.52,-2.27 -5.24,1.58 -1.46,-0.77 0.14,-1.15 -2.34,-0.9 -1.11,0.53 -1.58,-1.64 -1.95,-0.01 -1.04,-3.56 0.65,-0.45 -0.78,-0.5 -1.28,0.76 0.41,0.94 -1.94,4.01 0.07,1.94 -1.74,-0.32 -0.49,1.77 -1.44,-0.01 0.45,2.51 3.9,1.19 0.08,0.98 1.85,-0.22 0.04,0.73 -1.87,0.43 0.04,1.77 1.54,0.28 -0.92,0.73 0.47,0.96 2,-1.1 0.68,-2.45 1.75,-0.51 1.52,1.94 0.07,-0.7 1.53,0.45 -0.45,0.9 1.62,0.61 1.11,1.94 -2.06,4.31 -0.94,0.41 0.96,1.86 1.62,0.15 -1.68,1.84 0.63,0.13 -0.59,2.38 -4.15,3.94 -3.69,-0.49 0,0 -0.79,-0.14 1.08,-0.47 -1.28,-0.42 -0.73,-1.74 -1.72,-0.86 0.7,-1.1 -2.38,0.07 -0.96,-1.61 -0.78,0.95 -2.39,-0.08 -0.79,-1.39 -2.21,0.07 -0.95,-1.7 -1.05,0.44 -1.94,-1.4 -0.78,1.09 -0.21,-2.39 -2.63,-0.59 -1.51,-2.58 -2.06,0.4 -1.06,-1.06 -1.4,0.07 -0.87,-1.48 -1.57,0.91 -1.54,-0.28 -0.1,-2.05 -2.25,-0.76 1.6,-0.61 -2.28,-0.79 1.74,-3.4 -0.67,-1.31 -5.74,-1.42 -1.67,0.56 -0.67,-2.09 -1.43,0.34 -0.37,-1.18 -1.27,0.27 0.1,-2.25 -1.21,-1.49 -2.38,-0.69 -1.39,-2.81 0.3,-1.08 2.99,-0.21 -0.91,-2.06 2.09,-1.35 -2.3,-1.79 2.67,-2.91 -1.47,-1.07 -0.05,-3.41 -0.8,0.36 -1.6,-1.3 -0.07,-1.01 1.35,-0.21 -2.19,-1.78 0.34,-1.48 -0.93,-0.72 1.34,-1.47 0.01,-2.3 -0.6,-1.14 -1.27,0.3 -0.38,-1.14 0,0 3.22,0.28 0.56,2.67 2.57,0.26 0.64,-1.22 1.78,0.84 1.12,-2.52 0.63,0.39 1.33,-1.45 z"
        title="Moselle"
        id="FR-57"
      />
      <path
        d="m 509.02408,164.19159 3.69,0.49 4.15,-3.94 0.59,-2.38 -0.63,-0.13 1.68,-1.84 -1.62,-0.15 -0.96,-1.86 0.94,-0.41 2.06,-4.31 -1.11,-1.94 -1.62,-0.61 0.45,-0.9 -1.53,-0.45 -0.07,0.7 -1.52,-1.94 -1.75,0.51 -0.68,2.45 -2,1.1 -0.47,-0.96 0.92,-0.73 -1.54,-0.28 -0.04,-1.77 1.87,-0.43 -0.04,-0.73 -1.85,0.22 -0.08,-0.98 -3.9,-1.19 -0.45,-2.51 1.44,0.01 0.49,-1.77 1.74,0.32 -0.07,-1.94 1.94,-4.01 -0.41,-0.94 1.28,-0.76 0.78,0.5 -0.65,0.45 1.04,3.56 1.95,0.01 1.58,1.64 1.11,-0.53 2.34,0.9 -0.14,1.15 1.46,0.77 5.24,-1.58 3.52,2.27 1.73,-1.55 2.42,-6.09 0,0 1.52,0.57 2.44,-0.76 0.12,0.82 2.44,-1.41 3,2.04 0.92,-0.96 1.3,0.44 0.58,-1.02 1.78,1.91 5.02,2.36 5.6,1.53 -1.45,0.56 -2.01,3.32 -2.47,6.42 -2.48,1.03 -0.58,1.6 -1.82,0.21 -0.33,2.21 -5.18,5.07 -1.62,3.57 0.25,4.34 -1.56,1.53 -1.49,5.98 0.51,4.19 -2.14,1.58 -1.11,4.98 -3.72,6.27 0,0 -2.4,-0.38 0.01,-1.42 -2.07,-0.65 0.29,-2.69 -6.7,-2.99 0.69,-1.2 -1.62,0.07 0.35,-0.78 -1.05,-1.41 -3.2,-0.33 0,0 -0.8,-1.83 -2.62,0.4 -1.7,-1.19 1.34,-1.57 -0.97,-1.29 1.38,-5.33 -1.18,-1.24 1.84,0.36 -0.66,-1 0,0 -1.6,0.07 z"
        title="Bas-Rhin"
        id="FR-67"
      />
      <path
        d="m 514.12408,178.23159 3.2,0.33 1.05,1.41 -0.35,0.78 1.62,-0.07 -0.69,1.2 6.7,2.99 -0.29,2.69 2.07,0.65 -0.01,1.42 2.4,0.38 0,0 -0.3,5.37 2.19,3.85 -2.73,5.74 0.23,2.34 -1.35,3.82 0.76,2.8 -1.51,2.24 0.48,2.22 2.83,3.49 -0.27,1.31 -3.57,2.67 1.31,0.71 -1.75,2.78 -2.37,-0.79 -0.44,0.91 1.44,0.57 -2.87,2.38 -2.4,-0.56 -3.54,1.22 -0.46,-1.1 -2.58,-0.28 1.32,-3.07 -2.88,-0.55 0,0 0.44,-1.33 -2.37,-4.18 -1.86,-0.47 -0.86,0.73 -0.63,-0.83 0.24,-2.26 1.44,-2.01 -0.8,-1.76 0.41,-1.38 -4.01,-2.96 -3.07,-0.71 -1.25,-2.43 0,0 3.46,-1.71 -0.96,-2.53 1.36,-1.17 -0.72,-0.59 1.33,-4.97 2.35,-1.35 3.18,-5.46 0.32,-1.28 -1.04,-0.94 4.69,-9.48 z"
        title="Haut-Rhin"
        id="FR-68"
      />
      <path
        d="m 510.75408,165.54159 0.66,1 -1.84,-0.36 1.18,1.24 -1.38,5.33 0.97,1.29 -1.34,1.57 1.7,1.19 2.62,-0.4 0.8,1.83 0,0 -1.11,0.78 -4.69,9.48 1.04,0.94 -0.32,1.28 -3.18,5.46 -2.35,1.35 -1.33,4.97 0.72,0.59 -1.36,1.17 0.96,2.53 -3.46,1.71 0,0 -0.52,0.44 0,0 -1.54,-2.2 -5.8,-3.3 -1.78,-2.6 -1.64,0.82 -0.84,1.82 -2.71,1.09 -1.78,-3.47 -1.91,-1.17 -4.23,0.31 -3.45,1.46 0.14,-0.95 -2.44,-1.18 0.64,-0.58 -0.35,-1.92 -1.01,-1.05 -3.8,1.26 -1.54,2.91 -1.32,-0.05 -0.89,1.32 -0.9,-0.14 1.34,-1.86 -1.13,-0.57 -2.01,3.18 0,0 -1.57,-3.07 -1.55,1.7 -0.92,-0.27 0.32,-2.72 -0.77,-2.28 -1.43,-1 -0.84,0.36 -1.06,-2.03 -1.67,-0.57 0.03,1 -1.21,-0.97 1.77,-1.42 -0.57,-0.78 1.13,-1.67 -0.15,-1.96 2.11,-0.95 -1.83,-2.46 -1.91,-0.48 0,-2.12 -1.24,-1 -0.94,1.11 -2.67,-4.6 -1.88,-0.52 -2.18,1.55 -0.39,-1.6 1.02,-1.39 -2.01,-0.74 3.14,-1.84 0,0 1.54,0.73 0.5,-1.41 3.09,-0.68 1.19,1.05 -0.15,-1.03 1.52,-1.08 -0.51,-0.78 3.17,0.79 1.94,-2.33 0,0 0.92,0.88 2.93,-1.71 1.98,0.96 -0.83,1.58 0.5,1.49 -1.71,2.22 1.59,0.33 0.15,-1.97 1.39,0.15 -1.12,2.08 1.89,0.26 0.49,2.88 1.7,-0.86 2.85,0.24 0.67,-1.49 0.18,1.33 1.02,0.55 1.47,-1.75 -0.05,-1.7 3.43,0.66 2.58,-2.26 1.41,1.87 2.18,-0.63 0.29,1.46 6.01,-2.51 1.89,0.97 -0.5,-1.55 2.24,-1.99 0.98,2.79 4.96,1.09 0.08,0.73 0.89,-0.17 -0.23,0.84 1.5,-0.99 0,0 0.42,-0.81 0,0 1.45,-1.24 1.17,1.2 3.6,-3.81 z"
        title="Vosges"
        id="FR-88"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "Grandest",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (p.getAttribute("title") == "Meurthe-et-Moselle") {
        t.setAttribute("x", b.x + b.width / 2 - 15);
        t.setAttribute("y", b.y + b.height / 2 + 5);
      }

      if (this.weather[p.getAttribute("title")]) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>