<template>
  <div class="no-overflow">
    <svg
      xmlns:mapsvg="http://mapsvg.com"
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="svg2"
      preserveAspectRatio="xMidYMid meet"
      viewBox="217 52 200 200"
      style="transform: scale(2.4);"
    >
      <path
        d="m 311.66408,141.36159 2.92,0.04 0.78,2.62 0,0 0.04,1.49 2.23,-0.38 -0.34,1.18 -2.46,-0.74 -2.8,0.87 0,0 -4.41,-2.24 0.83,-1.32 1.08,0.63 z"
        title="Ville de Paris"
        id="FR-75"
      />

      <path
        d="m 342.79408,127.66159 3.83,1.13 -0.47,1.02 1.55,2.05 -0.97,2.39 3.48,2.51 0.55,2.12 1.67,-0.62 1,2.56 1.6,-1.28 0.64,3.55 2.6,0.73 0.64,1.43 1,-0.92 0,0 0.08,2.4 -3.48,0.33 1.58,0.35 0.08,0.8 -1.43,0.4 -0.6,1.6 3.06,1.27 0.35,2.49 -1.52,2.16 0.81,0.7 -0.35,1.09 3.45,-0.7 -0.58,0.84 1.63,1.02 0,0 -2.41,0.74 0.51,0.93 -2.04,1.21 0.67,1.8 -3.09,0.86 1.3,2.34 -2.26,0.67 0.94,1.65 -0.59,1.79 1.27,0.57 -0.31,1.6 0,0 -1.6,-0.61 -0.46,1.72 -1.2,0.16 -2.22,-0.74 -1.16,1.06 -4.72,-0.5 -1.54,1.48 -2.33,-0.65 -1.33,3.38 1.33,3.44 -1.78,2.66 -2.78,1.46 -0.01,1.26 0,0 -2.94,0.43 -1.94,1.63 -0.76,-0.11 -0.09,-2.3 -1.5,0.95 -1.1,-0.83 0.74,1.33 -2.02,1.36 -6.07,-1.09 -1.57,1.04 -3.23,0.02 1.64,-2.53 0.97,0.51 0.68,-2.7 -0.71,-2.36 -3.41,-1.34 -0.81,-3.48 0,0 -0.48,-1.21 1.71,0.02 0.44,-1.41 -0.6,-0.3 2.27,-1.32 -0.43,-0.41 3.01,-0.72 -1.8,-1.75 0.49,-4.73 -0.69,-2.52 1.88,-3.53 -1.26,-1.67 1.2,-0.3 -0.51,-1.71 2.26,-1.02 -0.53,-0.9 0,0 0.55,-3.11 1.19,-1.34 -1.12,-0.69 0.34,-2.04 0,0 -0.91,-2.76 0.44,-1.26 -1.03,-1.05 1.13,-0.57 0.7,-2.43 -1.68,-2.83 0.81,-0.09 -0.56,-2.01 -0.74,0.06 0,0 -0.22,-0.74 2.01,-1.51 -1.03,-0.86 0.88,-1.2 0,0 1.77,-1.8 2.64,2.78 0.99,-0.71 1.32,0.91 2.33,-2.25 1.81,1.67 2.02,-0.87 1.69,0.44 1.15,-0.91 0.78,1.29 0.89,-1.29 2.39,0.4 z"
        title="Seine-et-Marne"
        id="FR-77"
      />
      <path
        d="m 277.59408,138.79159 -0.36,-1.69 1.1,-0.72 -2.02,0.17 0.34,-2.41 -1.26,-2.49 0.6,-0.66 1.07,0.84 0.88,-2.2 0.53,1.11 3.56,-0.59 0,0 2.68,-0.04 2.08,2.11 4.45,-2 1.47,1.22 -0.61,1.51 1.03,1 1.32,-1.93 1.24,1.65 2.26,0.01 1.29,1.49 4.04,-1.23 -0.02,1.61 1.86,0.49 0.39,1.93 1.27,0.14 -0.16,2.71 0,0 -1.36,0.88 -0.86,1.52 0.53,1.41 -0.7,0.31 0.74,3.84 1.33,1 0,0 -3.78,1.77 -0.25,2.57 -0.67,0.8 -1.48,-0.18 -0.66,1.92 -0.73,-0.2 2.12,2.73 -1.22,0.63 -0.63,3.04 -3.4,-0.19 1.78,2.15 -1.35,0.54 -0.99,3.7 0,0 -0.62,1.12 -1.55,-0.02 -1.09,-0.39 -0.32,-1.28 -1.42,0.03 -1.07,-2.91 0.47,-2.52 -1.24,-1.3 -2,-0.17 0.25,-2.34 -2.03,0.02 -0.98,-2.24 -1.58,-0.76 0.33,-1.68 -1.46,-0.88 2.07,-2.76 -1.88,-0.9 -0.09,-4.39 0.79,-0.54 -0.89,-0.33 0.32,-0.96 -1.61,-0.65 0.72,-1.27 z"
        title="Yvelines"
        id="FR-78"
      />

      <path
        d="m 306.30408,149.78159 1.57,-0.42 1.66,0.76 0.52,1.6 0.91,-0.44 0,0 1.06,-0.39 1.6,0.66 0.14,1.14 1.72,0.11 4.17,-1.35 1.28,2.97 1.09,-0.01 0,0 0.53,0.9 -2.26,1.02 0.51,1.71 -1.2,0.3 1.26,1.67 -1.88,3.53 0.69,2.52 -0.49,4.73 1.8,1.75 -3.01,0.72 0.43,0.41 -2.27,1.32 0.6,0.3 -0.44,1.41 -1.71,-0.02 0.48,1.21 0,0 -1.88,0.49 -1.24,-1.55 -0.76,0.25 0,0 -0.46,1.06 0,0 -1.37,-0.22 -0.9,1.04 0.02,-2.08 -1.77,-0.76 -0.9,2.04 -1.23,-0.29 0.49,1.09 -2.44,-0.52 -2.65,1.4 -1.95,-0.13 0,0 -1.5,-1.46 0.91,-0.47 -0.09,-5.2 -2.06,-0.28 0.6,-2.2 -0.84,-1.04 0,0 0.99,-3.7 1.35,-0.54 -1.78,-2.15 3.4,0.19 0.63,-3.04 1.22,-0.63 -2.12,-2.73 0.73,0.2 0.66,-1.92 1.48,0.18 0.67,-0.8 0.25,-2.57 z"
        title="Essonne"
        id="FR-91"
      />
      <path
        d="m 309.98408,138.21159 2.23,0.6 -0.55,2.55 0,0 -2.13,2.15 -1.08,-0.63 -0.83,1.32 4.41,2.24 0,0 -1.07,4.84 0,0 -0.91,0.44 -0.52,-1.6 -1.66,-0.76 -1.57,0.42 0,0 -1.33,-1 -0.74,-3.84 0.7,-0.31 -0.53,-1.41 0.86,-1.52 1.36,-0.88 0,0 z"
        title="Hauts-de-Seine"
        id="FR-92"
      />
      <path
        d="m 321.14408,134.29159 0.74,-0.06 0.56,2.01 -0.81,0.09 1.68,2.83 -0.7,2.43 -1.13,0.57 1.03,1.05 -0.44,1.26 0.91,2.76 0,0 -2.58,-2.99 -5.04,-0.22 0,0 -0.78,-2.62 -2.92,-0.04 0,0 0.55,-2.55 -2.23,-0.6 0,0 0.66,-1.02 1.55,0.55 1.29,-1.01 3.04,1.21 2.11,-0.92 z"
        title="Seine-Saint-Denis"
        id="FR-93"
      />
      <path
        d="m 315.36408,144.02159 5.04,0.22 2.58,2.99 0,0 -0.34,2.04 1.12,0.69 -1.19,1.34 -0.55,3.11 0,0 -1.09,0.01 -1.28,-2.97 -4.17,1.35 -1.72,-0.11 -0.14,-1.14 -1.6,-0.66 -1.06,0.39 0,0 1.07,-4.84 0,0 2.8,-0.87 2.46,0.74 0.34,-1.18 -2.23,0.38 z"
        title="Val-de-Marne"
        id="FR-94"
      />
      <path
        d="m 285.98408,120.40159 0.41,-0.58 1.09,1.06 -1.05,1.12 1.12,1.61 2.57,-0.27 0.36,0.75 1.02,-0.21 -0.06,0.8 4.8,-0.39 1.04,-0.84 1.08,0.49 3.28,-2.18 0.64,1.28 1.35,-0.23 0.31,0.93 0.48,-0.6 0.94,1.58 1.99,-1 0.36,1.75 2.42,-0.51 1.17,-1.69 1.82,2.43 0.72,-0.74 2.7,0.83 -0.24,0.82 1.07,-0.47 1.82,1.46 -0.56,0.72 1.74,0.41 0.91,-1.54 0.16,1.62 1.34,1.17 0,0 -0.88,1.2 1.03,0.86 -2.01,1.51 0.22,0.74 0,0 -2.51,2.73 -2.11,0.92 -3.04,-1.21 -1.29,1.01 -1.55,-0.55 -0.66,1.02 0,0 -3.36,2.61 0,0 0.16,-2.71 -1.27,-0.14 -0.39,-1.93 -1.86,-0.49 0.02,-1.61 -4.04,1.23 -1.29,-1.49 -2.26,-0.01 -1.24,-1.65 -1.32,1.93 -1.03,-1 0.61,-1.51 -1.47,-1.22 -4.45,2 -2.08,-2.11 -2.68,0.04 0,0 2.03,-3.42 0.69,-5.04 z"
        title="Val-d'Oise"
        id="FR-95"
      />
    </svg>
    <v-row justify="start" no-gutters>
      <v-col class="text-left text-body-1 font-italic">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import mixins_weather from "@/utils/mixins/weather";
import { weather_props } from "@/utils/mixins/weather";

export default {
  name: "IledeFrance",

  mixins: [mixins_weather],

  props: {
    ...weather_props
  },

  data() {
    return {};
  },

  async mounted() {},

  methods: {
    addIconOverRegion(p) {
      let tmp = null;
      var t = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "foreignObject"
      );

      var svg = document.getElementsByTagName("svg");

      var b = p.getBBox();

      t.setAttribute("x", b.x + b.width / 2 - 10);
      t.setAttribute("y", b.y + b.height / 2 - 10);
      t.setAttribute("width", 20);
      t.setAttribute("height", 20);

      if (p.getAttribute("title") == "Seine-Saint-Denis") {
        t.setAttribute("x", b.x + b.width / 2 + 20);
        t.setAttribute("y", b.y + b.height / 2 - 10);
      }
      if (p.getAttribute("title") == "Seine-et-Marne") {
        t.setAttribute("x", b.x + b.width / 2 - 10);
        t.setAttribute("y", b.y + b.height / 2 + -5);
      }
      if (p.getAttribute("title") == "Val-de-Marne") {
        t.setAttribute("x", b.x + b.width / 2 - 5);
        t.setAttribute("y", b.y + b.height / 2 - 13);
      }

      if (
        p.getAttribute("title") != "Hauts-de-Seine" &&
        p.getAttribute("title") != "Ville de Paris" &&
        this.weather[p.getAttribute("title")]
      ) {
        tmp = require(`@/assets/weather/${
          this.list_icon[this.weather[p.getAttribute("title")].currently.icon]
        }`);

        var div = document.createElement("div");
        div.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");

        var img = document.createElement("img");
        img.src = tmp;
        img.classList.add("w-heartbeat");

        div.appendChild(img);
        t.appendChild(div);

        svg[0].appendChild(t);
      }
    }
  },
  watch: {
    weather: {
      deep: true,
      immediate: true,
      handler: function(val, oldVal) {
        this.$nextTick(() => {
          var paths = document.querySelectorAll("path");
          for (let i = 0; i < paths.length; i++) {
            this.addIconOverRegion(paths[i]);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
path {
  fill: #93d02d;
  stroke-width: 0.972417px;
  pointer-events: all;
}

svg {
  max-width: 80vw;
  max-height: 80vh;
  overflow-x: hidden;
}

.no-overflow {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>