<template>
  <v-container class="fill-height bg_weather" fluid>
    <v-container class="fill-height white--text">
      <v-row justify="center" align="center" class="fill-height" no-gutters>
        <v-col class="custom-card py-9" cols="12" lg="9">
          <vue-element-loading :active="loading" spinner="bar-fade-scale" :background-color="'rgba(0, 0, 0, 0.5)'">
            <looping-rhombuses-spinner :animation-duration="1200" :size="95" color="#ff1d5e" />
          </vue-element-loading>
          <v-row justify="center" align="center" class="pt-2 text-label" no-gutters>
            <v-icon class="icon-label" dark>mdi-map-marker</v-icon>
            {{ label }}
          </v-row>
          <v-row v-if="$vuetify.breakpoint.height > 800" justify="center" align="center" class="mt-6 text-date" no-gutters>{{
            currentDate
          }}</v-row>
          <v-row v-if="$vuetify.breakpoint.height > 800" justify="center" align="center" class="my-6 text-time" no-gutters>{{
            currentTime
          }}</v-row>
          <v-row justify="center" align="center" class no-gutters>
            <v-col class="text-h5 text-center mt-12 font-weight-medium" cols="12" v-html="currently_summary || ''"></v-col>
            <v-col
              class="text-h1 font-weight-thin text-center mt-5"
              cols="12"
              v-html="`${Math.trunc(currently_temperature || 0)}°C`"
            ></v-col>
          </v-row>
          <v-row
            v-if="$vuetify.breakpoint.height > 800"
            justify="center"
            align="center"
            class="mt-8 pt-8 mx-3 pb-12 text-center text-body-2"
            no-gutters
          >
            <v-col v-for="(hr, index) in daily" :key="hr.time" :class="{ hourly_weather: index < 3 }" cols="3">
              <v-row class="flex-column">
                <v-col class="my-1 py-1">{{ hr.time_formatted }}</v-col>
                <v-col class="my-1 py-1">
                  <v-img
                    class="mx-auto"
                    :src="require(`@/assets/weather/${list_icon[hr.icon]}`)"
                    contain
                    width="50"
                    height="50"
                  ></v-img>
                  <!-- <v-icon class="big-icon" dark>{{ list_icon[hr.icon] }}</v-icon> -->
                </v-col>
                <v-col class="font-weight-medium blue--text text--lighten-3 mb-0 pb-0">
                  <v-icon small>mdi-arrow-down</v-icon>{{ `${Math.trunc(hr.temperatureMin || 0)}°C` }}</v-col
                >
                <v-col class="font-weight-medium red--text text--lighten-3 my-0 py-0">
                  <v-icon small>mdi-arrow-up</v-icon>{{ `${Math.trunc(hr.temperatureMax || 0)}°C` }}</v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import { LoopingRhombusesSpinner } from "epic-spinners";

import weather from "@/utils/mixins/weather";

export default {
  name: "WeatherCurrentDate",

  mixins: [weather],

  components: { LoopingRhombusesSpinner },

  data() {
    return {
      weather: null,

      label: "",
      currently_summary: "",
      currently_temperature: null,
      daily: null,

      currentDate: null,
      currentTime: null,
      loading: true,

      timeout: null,
    };
  },

  beforeDestroy() {
    if (this.timeout != null) clearTimeout(this.timeout);
  },

  async mounted() {
    try {
      this.currentDate = this.getCurrentDate();
      this.currentTime = new Date().timeNow();

      this.weather = (await this.$axios.get("/weather")).data;

      const dateTimeFormat = new Intl.DateTimeFormat("fr-FR", { weekday: "short", day: "2-digit" });

      this.label = this.weather.label;
      this.currently_summary = this.weather.currently.summary;
      this.currently_temperature = this.weather.currently.temperature;
      this.daily = this.weather.daily.data.map((el) => {
        const [{ value: weekday }, , { value: day }, , ,] = dateTimeFormat.formatToParts(el.time * 1000);

        return {
          ...el,
          time_formatted: `${weekday} ${day}`,
        };
      });
    } catch (error) {
      console.error(error);
      this.$emit("error", error);
    } finally {
      this.loading = false;
    }

    this.timeout = setTimeout(this.setCurrentTime, 20000);
  },

  methods: {
    setCurrentTime() {
      let now = new Date();

      this.currentTime = new Date().timeNow();

      this.timeout = setTimeout(this.setCurrentTime, 20000);
    },
  },
};
</script>

<style scoped>
.icon-label {
  font-size: 6vw;
}

.text-label {
  font-size: 8vw;
}

.text-date {
  font-size: 2.8vw;
}

.text-time {
  font-size: 2.8vw;
}

.custom-card {
  background-color: transparent;
  background-image: linear-gradient(#297af9, #333fb7);
  background-position: 0 0;
  background-repeat: repeat;
  background-size: auto;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 3px 20px 0;
  max-height: 90%;
}

.big-icon {
  font-size: 3em;
}

.hourly_weather {
  border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.bg_weather {
  background: #2a329e;
}

.forecast {
  background-color: #2f45b9;
  border-radius: 15px;
}
</style>
