<template> </template>

<script>
export default {
  name: "RepetitiveCheck",

  props: {
    start: {
      type: Boolean,
      default: false,
    },

    /**
     * cb
     */
    cb: {
      type: Function,
      default: null,
    },

    /**
     * should return a Boolean
     *
     * true: end
     *
     * false: continue
     */
    check: {
      type: Function,
      default: function() {
        return this.ref_results.length >= this.nbr_max_failure &&
          this.ref_results.every((el) => el === false || el == null || el == undefined)
          ? true
          : false;
      },
    },

    millisecond_timeout: {
      type: Number,
      default: 1000 * 60,
    },

    nbr_max_failure: {
      type: Number,
      default: 7,
    },
  },

  data() {
    return {
      timeout: null,

      ref_results: [],
    };
  },
  beforeDestroy() {
    this.clearTimeout();
  },

  mounted() {
    if (this.start) this.loop();
  },

  methods: {
    loop() {
      if (!this.start) return;
      this.timeout = setTimeout(this.body, this.millisecond_timeout);
    },

    async body() {
      if (this.cb) this.ref_results.push(await this.cb()); // call cb
      if (this.start && !this.check(this.ref_results, this.nbr_max_failure)) this.loop();
      // if nbr_max_failure < current nbr error then recall loop
      else if (this.check(this.ref_results, this.nbr_max_failure))
        // if too many error then emit signal
        this.emitMaxFailure();
      console.log(this.ref_results);
    },

    emitMaxFailure() {
      this.$emit("max_failure", this.ref_results);
    },

    clearTimeout() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
    },
  },

  watch: {
    start: {
      handler: function(val) {
        if (!val || this.timeout) this.clearTimeout();
        else {
          this.ref_results = [];
          this.loop();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
