const default_options_values = {
    concession_cSlides_list: "all",
    groupe_cSlides_list: "all",
    franchise_cSlides_list: "all",

    concession_frequency_cSlides: "5",
    groupe_frequency_cSlides: "5",
    franchise_frequency_cSlides: "5",

    max_photo_per_vehicule: "7",
    nbr_second_per_photo: "7",

    detailsCar_overImage: true,
    detailsCar_right: false,

    weather_duration: "15",
    weather_interval: "20",

    trafic_duration: "15",
    trafic_interval: "15",

    loyer_duration: 72,
    loyer_taeg: "3",

    logo_type: "concession",

    display_details_car: true,
    smallDetailsCar: false

    // TODO : news
}

export default default_options_values;